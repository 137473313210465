import { Injectable } from '@angular/core';
import { DataElementType } from '@constants/data-element-types';

@Injectable({
  providedIn: 'root',
})
/**
 * This class is used to store the create type of a data element in the session storage, so that it can be retrieved
 * if a user refreshes the browser and we can return them to the same page.
 */
export class CreateDataTypeService {
  key = 'mepce-create-data-type';

  /**
   * Save the create type in the session storage.
   * @param createData The data to be saved in the session storage.
   */
  saveCreateDataType<T>(createData: { dataType: DataElementType; createType: T }): void {
    sessionStorage.setItem(this.key, JSON.stringify(createData));
  }

  /**
   * Retrieves the create type from the session storage, if it exists. If not, the default value is returned.
   * @param {DataElementType} dataType The data element type
   * @param defaultValue The default value to reutrn if the data does not exist in the session storage.
   * @returns The type of the data element being created.
   */
  getCreateDataType<T>(dataType: DataElementType, defaultValue: T): T {
    const data = sessionStorage.getItem(this.key);
    if (!data) return defaultValue;

    const createData = JSON.parse(data);
    if (createData.dataType !== dataType) return defaultValue;

    return createData.createType;
  }
}

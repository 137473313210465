import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HttpBackend } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DataErrorHandler } from '@shared/errors/data-error-handler';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// not used in production
import { storeFreeze } from 'ngrx-store-freeze';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../build-environments/environment';
import { MatIconModule } from '@angular/material/icon';
import { CookieService } from 'ngx-cookie-service';
import { LogoutComponent } from '@shared/components/auth/logout.component';
import { ServiceWorkerModule } from '@angular/service-worker';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { HttpLoaderFactory, getPaginatorTranslations } from '@utils/translate-utils';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { UserSettingsService } from '@services/user-settings.service';
import { CustomRouteReuseStategy } from './custom-route-reuse-strategy';
import { RouteReuseStrategy } from '@angular/router';
import { reducerProvider, reducerToken } from '@store/reducers';
import { effects } from '@store/effects';
import * as fromDynamicDataSetup from '@data-management/dynamic-data-setup';
import { HigNavModule } from '@shared/hig/nav/nav.module';
import { HigGlobalNavComponent } from '@shared/hig/global-nav/global-nav.component';
import { LoadingComponent } from '@shared/components/loading/loading.component';
import { DataElementEffectService } from '@services/data-element-effect.service';
import { HigComponentModule } from '@shared/hig/component/component.module';
import { CommonModule } from '@angular/common';
import { ReactComponentModule } from '@shared/react/react.module';
import { NotificationComponent } from '@shared/components/notification/notification.component';
import { GenericErrorComponent } from '@shared/components/generic-error/generic-error.component';

// export const metaReducers: MetaReducer<any>[] = !environment.production
//   ? [storeFreeze]
//   : [];

export const metaReducers: MetaReducer<any>[] = [storeFreeze];
// **Important - do not import modules into routes using index.ts barrel method

@NgModule({
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    StoreModule.forRoot(reducerToken, {
      metaReducers,
      runtimeChecks: {
        strictActionImmutability: false,
        strictStateImmutability: false,
      },
    }),
    EffectsModule.forRoot(effects),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    MatIconModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.supportsServiceWorker,
      registrationStrategy: 'registerImmediately',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    MatIconModule,
    HigNavModule,
    HigComponentModule,
    CommonModule,
    ReactComponentModule,
  ],
  declarations: [
    AppComponent,
    HigGlobalNavComponent,
    LogoutComponent,
    GenericErrorComponent,
    LoadingComponent,
    NotificationComponent,
  ],
  exports: [NotificationComponent],
  providers: [
    reducerProvider,
    {
      provide: ErrorHandler,
      useClass: DataErrorHandler,
    },
    {
      provide: LOCALE_ID,
      deps: [TranslateService],
      useFactory: (translate: TranslateService) =>
        translate.currentLang || translate.getBrowserLang(),
    },
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService],
      useFactory: getPaginatorTranslations,
    },
    CookieService,
    UserSettingsService,
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStategy,
    },
    ...fromDynamicDataSetup.dynamicDataSetupMultiProvider,
    DataElementEffectService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

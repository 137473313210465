<input
  matInput
  [matAutocomplete]="auto"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [placeholder]="props.placeholder"
  [errorStateMatcher]="errorStateMatcher"
  (focusout)="onBlur()"
/>
<mat-autocomplete #auto="matAutocomplete">
  <mat-option *ngFor="let value of filter | async" [value]="value">
    {{ value }}
  </mat-option>
</mat-autocomplete>

import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import {
  ToolBarOptions,
  ToolBarButton,
  ToolBarButtons,
  ToolBarButtonType,
} from '@models/tool-bar/tool-bar-options';
import {
  PartCollection,
  ServiceTemplateSizeRestriction,
  ServiceTemplatePalette,
  ServiceTemplateInfo,
} from '@models/fabrication/service-template-info';
import { Subscription, Observable, combineLatest, of, Subject } from 'rxjs';
import { elementIdDictionary } from '@constants/element-id-dictionary';
import { DynamicFormBaseCustomComponent } from '@shared/components/dynamic-form/dynamic-form-base-custom-component';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers/index';
import { StorageFile, ContentFile } from '@models/fabrication/files';
import { tap, switchMap, catchError, filter, map } from 'rxjs/operators';
import { BinaryStorageService } from '@cache/binary-storage.service';
import { LoggingService } from '@services/logging.service';
import {
  DynamicFormCustomUpdateEvent,
  DynamicFormOnApplyResult,
  DynamicFormOptions,
  DynamicFormStyle,
} from '@models/dynamic-form/dynamic-form-properties';
import { PartTemplateInfoTempModelOptions } from '@models/temp-data/temp-model';
import { NavigationConstants as nc } from '@constants/navigation-constants';
import { DynamicFormOperationType } from '@models/dynamic-form/dynamic-form-types';
import { selectThumbnailFileById } from '@store/selectors/thumbnail-file.selectors';
import { selectContentFileById } from '@store/selectors/content-file.selectors';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { TranslateService } from '@ngx-translate/core';
import { DynamicModalComponent } from '@shared/components/dynamic-modal/dynamic-modal.component';
import { DynamicModalSetupData } from '@models/dynamic-content/dynamic-content';
import { DataElementType } from '@constants/data-element-types';
import { DynamicFormCustomComponentType } from '@constants/dynamic-form-custom-component-types';
import { ArrayUtils } from '@utils/array-utils';
import { ImageUrlUtils } from '@utils/image-url-utils';

export interface ImagePathMap {
  path: string;
  objectKey: string;
  storageFile: StorageFile;
  id: string;
  dataUrl: string;
}

interface FileMap {
  path: string;
  id: string;
}

@Component({
  selector: 'fab-service-template-parts',
  templateUrl: './parts.component.html',
  styleUrls: ['./parts.component.scss'],
})
export class ServiceTemplatePartsComponent
  extends DynamicFormBaseCustomComponent<PartCollection[]>
  implements OnInit, OnDestroy
{
  idLookup = elementIdDictionary.partTemplateInfo;

  lc = LC;

  // edit part modal setup
  modalTitle: string;
  loadModalContent = false;
  showModal = false;
  showEditButton = true;

  toolBarOptions: ToolBarOptions<any> = null;
  hasSingleSelection: boolean;
  modalMultipleSelect = false;

  // main models
  palettes: ServiceTemplatePalette[] = [];
  sizeRestrictions: ServiceTemplateSizeRestriction[] = [];

  selectedPalette: ServiceTemplatePalette = null;
  selectedSizeRestriction: ServiceTemplateSizeRestriction = null;
  selectedPartCollection: PartCollection = null;
  selectedPartCollectionSizeRestrictions: ServiceTemplateSizeRestriction[] = [];

  customModelChangesSubscription: Subscription;
  thumbnailsMap: ImagePathMap[] = null;
  filesMap: FileMap[] = null;
  externalModelUpdateFields = ['sizeRestrictions', 'palettes'];
  partTemplateId: string;
  @ViewChild(DynamicModalComponent) modal: DynamicModalComponent;
  modalOptions: DynamicModalSetupData = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fileStorageService: BinaryStorageService,
    private loggingService: LoggingService,
    private changeRef: ChangeDetectorRef,
    private translate: TranslateService,
    private imageUrlUtils: ImageUrlUtils,
    // ideally store would not be referenced in child component, re-work with new service template design
    store$: Store<FDMState>
  ) {
    super(store$);
  }

  ngOnInit() {
    this.modalTitle = this.translate.instant(
      LC.FEATURE_MODULES.SERVICE_TEMPLATES.PARTS.MODAL_TITLE
    );
    this.getModelUpdates();
  }

  getModelUpdates() {
    this.customModelChangesSubscription = this.formModelUpdater
      .pipe(
        filter(
          (changeEvent: DynamicFormCustomUpdateEvent) =>
            changeEvent.isFirstUpdate ||
            (this.externalModelUpdateFields.includes(changeEvent.modelKey) &&
              changeEvent.subscriptionToken !== this.subscriptionToken)
        ),
        map((changeEvent: DynamicFormCustomUpdateEvent) => changeEvent.data),
        tap((model: ServiceTemplateInfo) => {
          this.partTemplateId = model.id;
          this.palettes = model.palettes;
          this.sizeRestrictions = model.sizeRestrictions;

          const requiresUpdate =
            (this.selectedPalette &&
              this.palettes.findIndex((x) => x.id === this.selectedPalette.id) < 0) ||
            (this.selectedSizeRestriction &&
              this.sizeRestrictions.findIndex((x) => x.id === this.selectedSizeRestriction.id) < 0);

          this.assignSelectedArtifacts();
          this.setup();

          if (requiresUpdate) {
            this.clearSelectedPartCollection();
            this.refreshData();
            this.changeRef.detectChanges();
          }
        }),
        switchMap((model: ServiceTemplateInfo) =>
          // only fired if we do not have initial thumbnails + files for partCollections
          this.getInitialThumbnails(model.thumbnails).pipe(
            switchMap(() => this.getInitialFiles(model.files))
          )
        )
      )
      .subscribe();
  }

  assignSelectedArtifacts() {
    // todo: improve ui control state, rather than looking at query params
    const paletteId = this.route.snapshot.queryParams[nc.QUERY_STRING_PALETTE_ID];
    const palette = paletteId && this.palettes.find((x) => x.id === paletteId);
    this.selectedPalette = palette || (this.palettes.length && this.palettes[0]) || null;

    if (this.route.snapshot.queryParams[nc.QUERY_STRING_SIZE_RESTRICTION_ID]) {
      this.selectedSizeRestriction = this.sizeRestrictions.find(
        (x) => x.id === this.route.snapshot.queryParams[nc.QUERY_STRING_SIZE_RESTRICTION_ID]
      );
    } else {
      this.selectedSizeRestriction =
        (this.sizeRestrictions.length && this.sizeRestrictions[0]) || null;
    }

    if (this.route.snapshot.queryParams[nc.QUERY_STRING_PART_COLLECTION_ID]) {
      this.selectedPartCollection = this.selectedPalette.partCollections.find(
        (x) => x.id === this.route.snapshot.queryParams[nc.QUERY_STRING_PART_COLLECTION_ID]
      );
      this.hasSingleSelection = true;
    } else {
      this.selectedPartCollection = null;
      this.hasSingleSelection = false;
    }
  }

  refreshData() {
    if (this.selectedPalette) {
      this.updateSource(this.selectedPalette.partCollections);
    }
  }

  getThumbnailContentFiles(thumbnailIds: string[]): Observable<ContentFile>[] {
    return thumbnailIds.map((x) => this.store$.select(selectThumbnailFileById(x)));
  }

  getInitialThumbnails(thumbnailContentFileIds: string[]): Observable<void> {
    if (!thumbnailContentFileIds) {
      this.thumbnailsMap = [];
      return of(null);
    }

    if (!this.thumbnailsMap) {
      let partThumbnails: ImagePathMap[] = null;
      return combineLatest(this.getThumbnailContentFiles(thumbnailContentFileIds)).pipe(
        tap((contentFiles: ContentFile[]) => {
          partThumbnails = contentFiles
            .filter((x) => !!x)
            .map((x) => ({
              path: x.path,
              objectKey: x.objectKey,
              storageFile: null,
              id: x.contentExternalId,
              dataUrl: null,
            }));
        }),
        switchMap(() => {
          return this.fileStorageService.getImageFiles(partThumbnails.map((x) => x.objectKey));
        }),
        tap((storageFiles: StorageFile[]) => {
          partThumbnails.forEach((x) => {
            x.storageFile = storageFiles.find((y) => y.id === x.objectKey);
            x.dataUrl = this.imageUrlUtils.getImageUrlFromThumbnailStorageFile(x.storageFile);
          });

          this.thumbnailsMap = partThumbnails;
          this.changeRef.detectChanges();
        }),
        map(() => {
          return;
        }),
        catchError((err) => {
          this.loggingService.logError(err);
          return of(null);
        })
      );
    } else {
      return of(null);
    }
  }

  getFileContentFiles(fileIds: string[]): Observable<ContentFile>[] {
    return fileIds.map((x) => this.store$.select(selectContentFileById(x)));
  }

  getInitialFiles(fileContentFileIds: string[]): Observable<void> {
    if (!fileContentFileIds) {
      this.filesMap = [];
      return of(null);
    }

    if (!this.filesMap) {
      return combineLatest(this.getFileContentFiles(fileContentFileIds)).pipe(
        tap((contentFiles: ContentFile[]) => {
          this.filesMap = contentFiles
            .filter((x) => !!x)
            .map((x) => ({
              path: x.path,
              id: x.contentExternalId,
            }));
        }),
        map(() => {
          return;
        }),
        catchError((err) => {
          this.loggingService.logError(err);
          return of(null);
        })
      );
    } else {
      return of(null);
    }
  }

  ngOnDestroy() {
    this.customModelChangesSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  private setup() {
    // create toolbar
    this.createToolBarOptions();
  }

  toggleExclusion(partCollection: PartCollection) {
    partCollection.isExcluded = !partCollection.isExcluded;
    this.refreshData();
  }

  selectPartCollection(partCollection: PartCollection) {
    this.selectedPartCollection =
      this.selectedPartCollection === partCollection ? null : partCollection;

    this.hasSingleSelection = this.selectedPartCollection === null ? false : true;
    this.setContextToolbarVisibility();
  }

  private setContextToolbarVisibility() {
    this.toolBarOptions.contextToolBarButtons.visible = !this.isReadOnly && this.hasSingleSelection;
  }

  clearSelectedPartCollection() {
    if (!this.isReadOnly) {
      this.selectedPartCollection = null;
      this.hasSingleSelection = false;
      this.setContextToolbarVisibility();
    }
  }

  private createToolBarOptions(): void {
    const collapsibleThreshold = 900;
    this.toolBarOptions = {};
    this.toolBarOptions.getRecord = () => this.selectedPartCollection;

    this.toolBarOptions.permanentToolBarButtons = {
      visible: !!(this.palettes.length && this.sizeRestrictions.length),
      buttons: [] as ToolBarButton<any>[],
    } as ToolBarButtons<any>;

    this.toolBarOptions.contextToolBarButtons = {
      visible: !this.isReadOnly && this.hasSingleSelection,
      buttons: [],
    } as ToolBarButtons<any>;

    this.toolBarOptions.permanentToolBarButtons.buttons.push({
      type: ToolBarButtonType.ADD,
      action: () => this.navigateToPartSearch(true),
      disableCondition: 'noRecords',
      label: this.translate.instant(LC.TOOLBAR.LABEL.ADD),
      tooltipTitle: this.translate.instant(LC.TOOLTIP.ADD_PART_TEMPLATE_PARTS),
      collapsibleThreshold,
    });

    // context single selection toolbar
    this.toolBarOptions.contextToolBarButtons.buttons.push({
      type: ToolBarButtonType.COLLAPSE_PANEL,
      action: () => this.navigateToPartSearch(false),
      label: this.translate.instant(LC.TOOLBAR.LABEL.ADD_TO_SIZE_RESTRICTION),
      tooltipTitle: this.translate.instant(LC.TOOLTIP.ADD_PART_TO_SIZE_RESTRICTION),
      collapsibleThreshold,
    });

    this.toolBarOptions.contextToolBarButtons.buttons.push({
      type: ToolBarButtonType.EDIT,
      action: (partCollection: PartCollection) => this.editPartCollection(partCollection),
      isHidden: !this.showEditButton,
      label: this.translate.instant(LC.TOOLBAR.LABEL.EDIT),
      tooltipTitle: this.translate.instant(LC.TOOLTIP.EDIT_PART_COLLECTION),
      collapsibleThreshold,
    });

    this.toolBarOptions.contextToolBarButtons.buttons.push({
      type: ToolBarButtonType.BACK,
      action: (partCollection) => this.movePartCollectionUp(partCollection),
      label: this.translate.instant(LC.TOOLBAR.LABEL.MOVE_LEFT),
      tooltipTitle: this.translate.instant(LC.TOOLTIP.MOVE_PART_LEFT),
      collapsibleThreshold,
    });

    this.toolBarOptions.contextToolBarButtons.buttons.push({
      type: ToolBarButtonType.FORWARD,
      action: (partCollection) => this.movePartCollectionDown(partCollection),
      label: this.translate.instant(LC.TOOLBAR.LABEL.MOVE_RIGHT),
      tooltipTitle: this.translate.instant(LC.TOOLTIP.MOVE_PART_RIGHT),
      collapsibleThreshold,
    });

    this.toolBarOptions.contextToolBarButtons.buttons.push({
      type: ToolBarButtonType.HIDE,
      action: (partCollection) => this.toggleExclusion(partCollection),
      label: this.translate.instant(LC.TOOLBAR.LABEL.EXCLUDE),
      tooltipTitle: this.translate.instant(LC.TOOLTIP.TOGGLE_PART_EXCLUSION),
      collapsibleThreshold,
    });

    this.toolBarOptions.contextToolBarButtons.buttons.push({
      type: ToolBarButtonType.TRASH,
      action: (button) => this.removePartCollection(button),
      label: this.translate.instant(LC.TOOLBAR.LABEL.DELETE),
      tooltipTitle: this.translate.instant(LC.TOOLTIP.DELETE_PART),
      collapsibleThreshold,
    });
  }

  private navigateToPartSearch(newParts: boolean) {
    // send temp model data
    const tempModelOptions: PartTemplateInfoTempModelOptions = {
      selectedPaletteId: this.selectedPalette.id,
      selectedPartCollectionId: (!newParts && this.selectedPartCollection.id) || null, // todo: determine when adding to existing part collection
      selectedSizeRestrictionId: this.selectedSizeRestriction.id,
    };
    this.enableTempNavigateFromForm(tempModelOptions);
    this.router.navigate([`add-parts`], {
      relativeTo: this.route,
      queryParams: { [nc.QUERY_STRING_TEMP_MODEL_ID]: this.partTemplateId },
    });
  }

  movePartCollectionUp(partCollection: PartCollection) {
    const partCollectionIdIndex = this.selectedPalette.partCollections.findIndex(
      (x) => x.id === partCollection.id
    );

    if (partCollectionIdIndex !== 0) {
      ArrayUtils.moveElement(
        this.selectedPalette.partCollections,
        partCollectionIdIndex,
        partCollectionIdIndex - 1
      );
      this.refreshData();
    }
  }

  movePartCollectionDown(partCollection: PartCollection) {
    const partCollectionIdIndex = this.selectedPalette.partCollections.findIndex(
      (x) => x.id === partCollection.id
    );

    if (partCollectionIdIndex !== this.selectedPalette.partCollections.length - 1) {
      ArrayUtils.moveElement(
        this.selectedPalette.partCollections,
        partCollectionIdIndex,
        partCollectionIdIndex + 1
      );
      this.refreshData();
    }
  }

  removePartCollection(partCollection: PartCollection) {
    const partCollectionIndex = this.selectedPalette.partCollections.findIndex(
      (x) => x.id === partCollection.id
    );

    // remove part collection id from palette and part collection object from part collections array
    this.selectedPalette.partCollections = this.selectedPalette.partCollections.filter(
      (x) => x.id !== partCollection.id
    );

    // move selected part collection to the next available if exists, if not clear selection
    if (this.selectedPalette.partCollections.length) {
      this.selectPartCollection(
        this.selectedPalette.partCollections[
          partCollectionIndex === 0 ? partCollectionIndex : partCollectionIndex - 1
        ]
      );
    } else {
      this.clearSelectedPartCollection();
    }

    this.refreshData();
  }

  hasSizeRestriction(partCollection: PartCollection): boolean {
    return !!partCollection.parts.filter(
      (x) => x.sizeRestrictionId === this.selectedSizeRestriction.id
    ).length;
  }

  partCollectionHasPartWithSizeRestriction(
    partCollection: PartCollection,
    sizeRestrictionId: string
  ): boolean {
    return !!partCollection.parts.filter((x) => x.sizeRestrictionId === sizeRestrictionId).length;
  }

  getPartCollectionThumbnail(partCollection: PartCollection, disableDefault?: boolean): string {
    let thumbnail: ImagePathMap = null;
    if (partCollection.updatedThumbnailStorageFile) {
      return this.imageUrlUtils.getImageUrlFromThumbnailStorageFile(
        partCollection.updatedThumbnailStorageFile
      );
    }
    if (partCollection.imageExternalIdOverride?.length) {
      thumbnail = this.thumbnailsMap.find((x) => x.id === partCollection.imageExternalIdOverride);
    }
    if (disableDefault) {
      return thumbnail?.dataUrl;
    }

    if (!thumbnail && partCollection.parts.length) {
      thumbnail = this.thumbnailsMap.find(
        (x) => x.id === partCollection.parts[0].contentExternalId
      );
    }

    return thumbnail?.dataUrl || this.imageUrlUtils.IMAGE_PLACEHOLDER;
  }

  private createModalFormOptions(
    partCollection: PartCollection,
    formOperation: DynamicFormOperationType
  ): DynamicModalSetupData {
    const hiddenFields = [
      'isExcluded',
      'imagePath',
      'sizeRestrictions',
      'imageExternalIdOverride',
      'updatedThumbnailStorageFile',
      'name',
    ];

    const partCollections = this.selectedPalette.partCollections.filter(
      (pc) => pc !== partCollection
    );

    const modelChangeSubject: Subject<PartCollection> = new Subject<PartCollection>();
    const formOptions: DynamicFormOptions<any> = {
      model: {
        ...partCollection,
        sizeRestrictions: this.model.sizeRestrictions,
      },
      formOperation,
      applyModelAction: null,
      isReadOnly: false,
      hiddenFields,
      uniqueFields: {
        fields: [],
        allElements: () => of(partCollections),
      },
      customComponents: [
        {
          type: DynamicFormCustomComponentType.PartCollectionTable,
          field: 'parts',
        },
      ],
      modelChangeHandler: (model) => {
        modelChangeSubject.next(model);
      },
      formStyle: DynamicFormStyle.NONE,
      additionalData: {
        partThumbnailMap: partCollection.parts
          .map((part) => this.thumbnailsMap.find((x) => x.id === part.contentExternalId))
          .filter((x) => !!x),
        collectionThumbnailUrl: this.getPartCollectionThumbnail(partCollection, true),
        modelChangeSubject,
      },
    };

    return {
      title: this.translate.instant(
        LC.FEATURE_MODULES.SERVICE_TEMPLATES.PARTS.PART_COLLECTIONS.MODAL_TITLE
      ),
      showModalButtons: false,
      modalWidth: 1000,
      modalHeight: 600,
      className: 'parts-modal',
      contentSetup: {
        contentType: 'form',
        formSetup: {
          fromModal: true,
          options: formOptions,
          dataType: DataElementType.ServiceTemplate,
          onFormApply: (onApply: DynamicFormOnApplyResult) => {
            this.updatePartCollection(onApply.result);
            this.refreshData();
            this.changeRef.markForCheck(); // TODO: try to avoid Sledge Hammer approach and re-render Part Collections in another way if possible
          },
          onFormCancel: () => {
            console.log('Modal from form cancelled');
          },
        },
      },
    };
  }

  updatePartCollection(partCollectionUpdated: PartCollection): void {
    const partCollection = this.selectedPalette.partCollections.find(
      (pc) => pc.id === partCollectionUpdated.id
    );
    partCollection.name = partCollectionUpdated.name;
    partCollection.parts = partCollectionUpdated.parts;
    partCollection.updatedThumbnailStorageFile = partCollectionUpdated.updatedThumbnailStorageFile;
  }

  editPartCollection(partCollection: PartCollection) {
    this.modalOptions = this.createModalFormOptions(partCollection, 'edit');
    this.modal.openModal(this.modalOptions);
  }
}

export enum TreePosition {
  UPSTREAM = 'upstream',
  DOWNSTREAM = 'downstream',
}

export enum PageControlPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export enum PaginationAction {
  NEXT = 'next',
  PREV = 'prev',
}

export enum SortOptions {
  ASC = 'asc',
  DESC = 'desc',
}

export const RectSizes = {
  WIDTH: 240,
  HEIGHT: 80,
};

export const ClusterFlange = {
  WIDTH: 10,
  HEIGHT: 20,
  FIRST_NODE_HEIGHT: 25,
  PAGINATED_HEIGHT: 55,
};

export const ClusterSizes = {
  WIDTH: RectSizes.WIDTH + ClusterFlange.WIDTH,
  HEIGHT: RectSizes.HEIGHT + ClusterFlange.HEIGHT,
};

export const BoxInfoMargin = {
  X: 5,
  Y: 3,
};

export const BottomControlSizes = {
  WIDTH: RectSizes.WIDTH,
  HEIGHT: 30,
  X: 5,
  Y: 5,
};

export const TopControlSizes = {
  WIDTH: RectSizes.WIDTH,
  HEIGHT: 30,
  X: 0,
  Y: -32,
};

export const NO_RESULTS_ID = 'no-results';

export const NODE_CONTENT_ROW_HEIGHT = 17; // css line-height + margin-bottom
export const NODE_SPACE_X = 50;
export const NODE_SPACE_Y = 8;
export const NODE_SIZE: [number, number] = [
  RectSizes.HEIGHT + NODE_SPACE_Y,
  RectSizes.WIDTH + NODE_SPACE_X,
];

export const CIRCLE_RADIUS = 9;
export const INFO_BOX_BORDER_RADIUS = 10;
export const SVG_CONTAINER_MARGIN = { top: 10, right: 120, bottom: 10, left: 40 };

export const DEFAULT_PAGE_SIZE = 5;

export const UPDATE_TRANSITION_DURATION = 250;
export const DEBOUNCE_TIME = UPDATE_TRANSITION_DURATION + 500;
export const THROTTLE_TIME = UPDATE_TRANSITION_DURATION + 350;

export const FIXED_NORESULT_NODE_DATA = {
  depth: 0,
  height: 0,
  data: {
    noResult: true,
  },
  x: 0,
  x0: 0,
  y: 0,
  y0: 0,
  parent: null,
  children: null,
};

export const NodeTooltipOptions = {
  EDIT: 'EDIT',
  FOCUS: 'FOCUS',
  VIEW: 'VIEW',
  UPGRADE: 'UPGRADE',
};

import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(private environmentService: EnvironmentService, private authService: AuthService) {}

  getFeedbackLink(): string {
    return `${this.environmentService.environment.navSubModuleHelp.feedbackUrl}?Q_SurveyVersionID=current&oxygenid=${this.authService.currentUserData.userId}`;
  }

  openFeedbackInNewWindow(): void {
    window.open(this.getFeedbackLink(), '_blank');
  }
}

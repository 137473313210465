import { ForgeContentDataElement } from '@models/forge-content/forge-content-data-element';
import {
  DynamicFormField,
  DynamicFormSelectType,
  DynamicFormBaseOptions,
  DynamicFormAsyncValidator,
  DynamicFormCustomUpdateEvent,
  DynamicFormToggleValueComponentOptions,
  DynamicFormMultiSelectComponentOptions,
  DynamicFormSelectField,
  DynamicFormForgeUnitsComponentOptions,
  DynamicFormGroupOptions,
  DynamicFormMessageComponentOptions,
} from '@models/dynamic-form/dynamic-form-properties';
import {
  DynamicFormInputTypes,
  DynamicFormOperationType,
} from '@models/dynamic-form/dynamic-form-types';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { startCase, sortBy, merge, concat, groupBy, camelCase } from 'lodash';
import { DataElementType } from '@constants/data-element-types';
import { Subject, BehaviorSubject, Observable, of } from 'rxjs';
import {
  customComponentDefinitions,
  CustomComponentDisplayType,
} from '@shared/components/dynamic-form/dynamic-form-custom-component-definitions';
import { map } from 'rxjs/operators';
import { JSONSchema7 } from 'json-schema';
import { FabricationReferenceType, FabricationReference } from '@models/forge-content/references';
import { Dictionary } from '@ngrx/entity';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { getI18nConstantRef } from '../translate-utils';
import { DynamicFormCustomComponentType } from '@constants/dynamic-form-custom-component-types';
import { FormlyHookConfig } from '@ngx-formly/core/lib/models';
import { DataElementTypeUtils } from '@utils/data-element-type-utils';
import { REACT_FIELD_TYPES } from '@data-management/dynamic-data-setup/dynamic-form-field-wrapper';

export class FormUtils {
  private static fabReferences = 'fabricationReferences';

  static mapFormlyFields(
    model: any,
    formOptions: DynamicFormBaseOptions,
    isReadOnly: boolean,
    formOperation: DynamicFormOperationType,
    schema: JSONSchema7,
    validators: any,
    asyncValidators: any,
    customComponentUpdateSubject: Subject<DynamicFormCustomUpdateEvent> = null,
    customComponentModelChangeListener: BehaviorSubject<DynamicFormCustomUpdateEvent> = null,
    customComponentAllowNavigateOverride: BehaviorSubject<[boolean, any]> = null,
    dataType: DataElementType,
    translate: TranslateService,
    customLifecycles: Dictionary<FormlyHookConfig> = {}
  ): FormlyFieldConfig[] {
    const formlyFields: FormlyFieldConfig[] = [
      {
        fieldGroupClassName: 'formly-field-group',
        fieldGroup: [],
      },
    ];

    const excludeInheritProps = Object.keys(new ForgeContentDataElement());
    excludeInheritProps.push('constructor');
    excludeInheritProps.push('schemaType');
    excludeInheritProps.push('reason');
    excludeInheritProps.push('extraData');

    const createField = (
      key: string,
      dataType: DataElementType,
      referenceDataIndex: number,
      groupOptions?: DynamicFormBaseOptions
    ): DynamicFormField =>
      this.createFieldSettings(
        key,
        Object.assign({}, formOptions, groupOptions),
        isReadOnly,
        formOperation,
        schema,
        validators,
        asyncValidators,
        dataType,
        referenceDataIndex,
        customComponentUpdateSubject,
        customComponentModelChangeListener,
        customComponentAllowNavigateOverride,
        translate,
        false,
        customLifecycles[key]
      );

    // collect all the model keys
    let modelKeys: string[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const item in model) {
      modelKeys.push(item);
    }

    // make sure any read-only fields are sorted first
    if (formOptions.readOnlyFields) {
      formOptions.readOnlyFields.forEach((x) => {
        modelKeys = modelKeys.filter((item) => item !== x);
        modelKeys.unshift(x);
      });
    }

    if (modelKeys.includes('category')) {
      // make sure the category (if there is one) is the second item
      modelKeys = modelKeys.filter((item) => item !== 'category');
      modelKeys.unshift('category');
    }

    if (modelKeys.includes('name')) {
      // make sure the name is the first item
      modelKeys = modelKeys.filter((item) => item !== 'name');
      modelKeys.unshift('name');
    }

    modelKeys.forEach((item: string) => {
      if (!excludeInheritProps.includes(item)) {
        if (
          item === this.fabReferences &&
          !formOptions.hiddenFields?.includes(this.fabReferences)
        ) {
          for (const refField of Object.keys(model[this.fabReferences])) {
            // only show relationships at this point
            const reference = model[this.fabReferences][refField] as FabricationReference;
            const referenceType = reference.referenceType as FabricationReferenceType;
            if (referenceType === FabricationReferenceType.Relationship) {
              const key = `${this.fabReferences}.${refField}.externalId`;
              const dataType = reference.dataType;
              const groupOptions = this.getGroupOptions(this.fabReferences, formOptions, key);
              if (groupOptions?.length) {
                groupOptions.forEach((groupOption) => {
                  const field = createField(key, dataType, Number(refField), groupOption.options);
                  this.addFormlyGroup(formlyFields, groupOption, field);
                });
              } else {
                const field = createField(key, dataType, Number(refField), null);
                this.addFormlyGroup(formlyFields, null, field);
              }
            }
          }

          if (formOptions.customComponents) {
            formOptions.customComponents
              .filter((x) => !!x.multiSelectOptions)
              .map((x) => x.multiSelectOptions)
              .forEach((x) => {
                const groupOptions = this.getGroupOptions(this.fabReferences, formOptions);
                if (groupOptions?.length) {
                  groupOptions.forEach((groupOption) => {
                    const field = createField(
                      this.fabReferences,
                      x.dataType,
                      null,
                      groupOption.options
                    );
                    this.addFormlyGroup(formlyFields, groupOption, field);
                  });
                } else {
                  const field = createField(this.fabReferences, x.dataType, null, null);
                  this.addFormlyGroup(formlyFields, null, field);
                }
              });
          }
        } else {
          const groupOptions = this.getGroupOptions(item, formOptions);
          if (groupOptions?.length) {
            groupOptions.forEach((groupOption) => {
              const field = createField(item, dataType, null, groupOption.options);
              this.addFormlyGroup(formlyFields, groupOption, field);
            });
          } else {
            const field = createField(item, dataType, null, null);
            this.addFormlyGroup(formlyFields, null, field);
          }
        }
      }
    });

    return formlyFields;
  }

  private static getGroupOptions(
    item: string,
    options: DynamicFormBaseOptions,
    key?: string
  ): DynamicFormGroupOptions[] {
    // todo - make this return a single group option after weave mui table is completed
    if (!options.groups) {
      return null;
    }
    if (!key) {
      return options.groups.filter((group) => group.includeFields.includes(item));
    } else {
      return options.groups.filter(
        (group) => group.includeFields.includes(item) && !group.options.hiddenFields.includes(key)
      );
    }
  }

  private static addFormlyGroup(
    formlyFields: FormlyFieldConfig[],
    groupOptions: DynamicFormGroupOptions,
    field: DynamicFormField
  ): void {
    const group = groupOptions?.label;
    const formlyField = formlyFields.find((field) => field?.id === group);
    if (formlyField) {
      if (groupOptions?.orderByIncludeFields && groupOptions?.includeFields) {
        this.insertFieldByOrder(field, groupOptions?.includeFields, formlyField.fieldGroup);
      } else {
        formlyField.fieldGroup.push(field);
      }
    } else {
      formlyFields.push({
        wrappers: ['accordion'],
        fieldGroup: [field],
        id: group,
        props: {
          label: group,
          expanded: groupOptions?.expanded,
        },
      });
    }
  }

  private static insertFieldByOrder(
    field: DynamicFormField,
    orderedKeys: string[],
    insertInto: any[]
  ) {
    const compareKeys = (includeKey: string, fieldKey: any) => {
      if (fieldKey && includeKey === this.fabReferences) {
        return fieldKey.startsWith(this.fabReferences + '.');
      }
      return includeKey === fieldKey;
    };
    let fieldArrayPointer = 0;

    for (const includeKey of orderedKeys) {
      if (compareKeys(includeKey, insertInto[fieldArrayPointer]?.key)) {
        fieldArrayPointer++;
        if (includeKey === this.fabReferences) {
          //Skip all fabReferences fields
          while (compareKeys(includeKey, insertInto[fieldArrayPointer]?.key)) {
            fieldArrayPointer++;
          }
        }
        if (fieldArrayPointer === insertInto.length) {
          break;
        }
      }
      if (compareKeys(includeKey, field.key)) {
        break;
      }
    }
    insertInto.splice(fieldArrayPointer, 0, field);
  }

  private static createFieldSettings(
    key: string,
    options: DynamicFormBaseOptions,
    isReadOnly: boolean,
    formOperation: DynamicFormOperationType | DynamicFormBaseOptions,
    schema: JSONSchema7,
    validators: any,
    asyncValidators: any,
    referenceDataType: DataElementType,
    referenceDataIndex: number,
    customComponentUpdateSubject: Subject<DynamicFormCustomUpdateEvent> = null,
    customComponentModelChangeListener: BehaviorSubject<DynamicFormCustomUpdateEvent> = null,
    customComponentAllowNavigateOverride: BehaviorSubject<[boolean, any]> = null,
    translate: TranslateService,
    usePlaceHolder = true,
    customHooks?: FormlyHookConfig
  ): DynamicFormField {
    const isCustomFormComponent = this.isCustomFormComponent(key, options);
    const isLockableFormComponent = this.isLockableFormComponent(key, options);
    const supportsCustomUpdates = isCustomFormComponent || isLockableFormComponent;

    if (!isReadOnly) {
      isReadOnly =
        formOperation === 'view' ||
        (options.readOnlyFields && options.readOnlyFields.includes(key));
    }

    const fieldType = this.getFieldType(key, options);
    const units = this.getUnits(key, options);
    const { label, placeholder } = this.setLabelAndPlaceHolder(
      key,
      options,
      usePlaceHolder,
      referenceDataType,
      translate
    );

    const result = {
      className: this.setLayoutClassName(isCustomFormComponent, key, options),
      key,
      wrappers: this.setFieldWrapper(key, options),
      type: fieldType,
      expressions: options?.expressions ?? {},
      props: {
        attributes: {
          'aria-label': label,
        },
        required: this.isFieldValueRequired(schema, key),
        // custom component update subjects
        customComponentUpdateSubject: supportsCustomUpdates ? customComponentUpdateSubject : null,
        customComponentModelChangeListener: supportsCustomUpdates
          ? customComponentModelChangeListener
          : null,
        customComponentAllowNavigateOverride: supportsCustomUpdates
          ? customComponentAllowNavigateOverride
          : null,
        label,
        placeholder,
        readonly: isReadOnly,
        ...this.getAdditionalOpts(key, options, isReadOnly),
        disabled: this.getDisabledState(options, key, isReadOnly),
        toggleValueOptions: this.getToggleValueOptions(key, options),
        dropdownTypeaheadValueOptions: this.getDropdownTypeaheadValueOptions(key, options),
        multiSelectOptions: this.getMultiSelectOptions(options),
        dataType: referenceDataType,
        dataIndex: referenceDataIndex,
        units,
        messageOptions: this.getMessageOptions(key, options),
        // click:
        //   fieldType === 'input'
        //     ? (config: FormlyFieldConfig, event: MouseEvent) => {
        //         // select all text when clicking into an input field
        //         const input = event.target as HTMLInputElement;
        //         if (input.selectionStart === input.value.length) {
        //           input.select();
        //         }
        //       }
        //     : null
      },
      validators,
      asyncValidators,
      hooks: customHooks,
      ...{ hideExpression: this.getHiddenFieldExpression(key, options) },
      modelOptions: {
        updateOn: units ? 'blur' : 'change',
      },
    } as DynamicFormField;

    return result;
  }

  static getDisabledState = (
    options: DynamicFormBaseOptions,
    key: string,
    isReadOnly: boolean
  ): boolean => {
    if (isReadOnly) {
      return true;
    } else if (options.disabledFields) {
      return !!options.disabledFields.find((x) => x === key);
    } else {
      return false;
    }
  };

  static setLabelAndPlaceHolder(
    key,
    options,
    usePlaceHolder,
    referenceDataType: string,
    translate: TranslateService
  ) {
    let label = this.applyLabelMapping(key, options);
    if (!label) {
      const dataElementType = DataElementTypeUtils.getLocalisationConstantRef(
        referenceDataType as DataElementType,
        true
      );
      const stringReference = getI18nConstantRef(key);
      const definitionKey =
        LC.DATATYPES.DEFINITIONS.COMMON[stringReference] ||
        LC.DATATYPES.DEFINITIONS[dataElementType][stringReference] ||
        LC.DATATYPES.TYPES[stringReference] ||
        null;

      label = definitionKey
        ? translate.instant(definitionKey)
        : `${referenceDataType}-${key}-needMapTranslation`;
    }

    return {
      label,
      placeholder: usePlaceHolder ? `${startCase(key)}` : '',
    };
  }

  /**
   * Apply field wrapper
   * Currently only supports lock-field and units-field wrappers
   * @static
   * @param {string} item
   * @param {DynamicFormBaseOptions} options
   * @returns {string[]}
   * @memberof FormUtils
   */
  static setFieldWrapper(item: string, options: DynamicFormBaseOptions): string[] {
    const fieldType = this.getFieldType(item, options);
    const wrappers = new Set<string>();

    if (options.tooltipFields?.includes(item)) {
      wrappers.add('tooltip-wrapper-field');

      // form-field wrapper is required only for angular fields, not React ones
      if (!REACT_FIELD_TYPES.has(fieldType)) {
        wrappers.add('form-field');
      }
    }

    if (options.messageFields?.some((o) => o.key === item)) {
      wrappers.add('message-field');
      // add form-field wrapper for select fields
      if (fieldType === 'select') {
        wrappers.add('form-field');
      }
    }

    if (options.lockFields?.includes(item)) {
      wrappers.add('lock-field');
      wrappers.add('form-field');
    }

    if (options.unitsFields?.findIndex((x) => x.key === item) >= 0) {
      wrappers.add('units-field');
      wrappers.add('form-field');
    }

    if (!wrappers.size && options.dropdownTypeaheadFields?.findIndex((x) => x.key === item) >= 0) {
      wrappers.add('form-field');
    }

    if (wrappers.size) return [...wrappers];

    return null;
  }

  static setLayoutClassName(
    isCustomFormComponent: boolean,
    item: string,
    options: DynamicFormBaseOptions
  ) {
    let className = 'formly-flex-field';

    if (options.overrideFieldWidth) {
      const overrideValue = options.overrideFieldWidth;
      const valueInBounds = overrideValue >= 1 && overrideValue <= 100;
      if (valueInBounds) {
        className = `${className} ${className}-${overrideValue}`;
      }
    }

    if (isCustomFormComponent) {
      const customComponent = options.customComponents.filter((x) => x.field === item)[0];
      const customComponentDisplayType = customComponentDefinitions.find(
        (x) => x.name === customComponent.type
      ).displayType;

      if (customComponentDisplayType === CustomComponentDisplayType.InlineTable) {
        className = 'formly-custom-field';
      }
    }

    //Adding unique class reference for each formField for unit test cases and layout class
    return `dynamic-form-${item}-field  ${className}`;
  }

  static applyLabelMapping(key: string, options: DynamicFormBaseOptions): string {
    let label;
    if (options.labelMapping) {
      const labelMapping = options.labelMapping.find((x) => x.key === key);
      label = labelMapping ? labelMapping.label : null;
    }

    return label;
  }

  static getFieldType(
    item: string,
    options: DynamicFormBaseOptions
  ): DynamicFormInputTypes | string {
    let fieldType: DynamicFormInputTypes | string = 'formly-text-field';

    // Set selectable fields
    const filterItem = this.filterItem(item, options.selectFields) as DynamicFormSelectField;
    if (options.selectFields && filterItem) {
      fieldType = 'select';
    }

    // Set checkbox fields
    if (options.checkboxFields?.includes(item)) {
      fieldType = 'checkbox';
    }

    // Set toggle fields
    if (options.toggleFields?.includes(item)) {
      fieldType = 'toggle';
    }

    // Set dropdownTypeahead fields
    if (options.dropdownTypeaheadFields?.findIndex((x) => x.key === item) >= 0) {
      fieldType = DynamicFormCustomComponentType.DropdownTypeaheadVariant;
    }

    if (
      options.unitsFields?.findIndex((x) => x.key === item) >= 0 ||
      options.numberFields?.includes(item)
    ) {
      fieldType = 'input';
    }

    // custom component fields
    if (this.isCustomFormComponent(item, options)) {
      fieldType = options.customComponents.find((x) => x.field === item).type;
    }

    return fieldType;
  }

  static getToggleValueOptions(
    item: string,
    options: DynamicFormBaseOptions
  ): DynamicFormToggleValueComponentOptions {
    if (this.isCustomFormComponent(item, options)) {
      return options.customComponents.find((x) => x.field === item).toggleValueOptions;
    }

    return null;
  }

  static getDropdownTypeaheadValueOptions(
    item: string,
    options: DynamicFormBaseOptions
  ): (term: string) => Observable<string[]> | undefined {
    const dropdowmItem = options?.dropdownTypeaheadFields?.findIndex((x) => x.key === item);

    if (dropdowmItem >= 0) {
      const itemListByProperty = options?.dropdownTypeaheadFields[dropdowmItem].options;

      const filterItem = (searchTerm: string) => {
        if (searchTerm) {
          searchTerm = searchTerm.replace(/\\/g, '\\\\').replace(/\*/g, '\\*');
          const nameRegex = new RegExp(searchTerm.trim(), 'i');
          const filteredList = itemListByProperty.filter((term) => {
            return nameRegex.test(term);
          });

          return filteredList;
        } else {
          return itemListByProperty;
        }
      };

      return (term) => of(filterItem(term));
    }

    return undefined;
  }

  static getMultiSelectOptions(
    options: DynamicFormBaseOptions
  ): DynamicFormMultiSelectComponentOptions {
    if (this.isCustomFormComponent(this.fabReferences, options)) {
      return options.customComponents.find((x) => x.field === this.fabReferences)
        .multiSelectOptions;
    }

    return null;
  }

  static getMessageOptions(
    item: string,
    options: DynamicFormBaseOptions
  ): DynamicFormMessageComponentOptions {
    return options.messageFields?.find((x) => x.key === item) || null;
  }

  static isCustomFormComponent(item: string, options: DynamicFormBaseOptions): boolean {
    return (
      (options.customComponents &&
        !!options.customComponents.filter((x) => x.field === item).length) ||
      false
    );
  }

  static getUnits(
    item: string,
    options: DynamicFormBaseOptions
  ): DynamicFormForgeUnitsComponentOptions {
    return options.unitsFields?.find((x) => x.key === item);
  }

  /**
   * Check if form field support locking e.g. part connectors
   * @static
   * @param {string} item
   * @param {DynamicFormBaseOptions} options
   * @returns {boolean}
   * @memberof FormUtils
   */
  static isLockableFormComponent(item: string, options: DynamicFormBaseOptions): boolean {
    return (options.lockFields && !!options.lockFields.includes(item)) || false;
  }

  static getAdditionalOpts(item: string, options: DynamicFormBaseOptions, isReadOnly: boolean) {
    let additionalOpts = {};

    if (options.selectFields) {
      const selectSettings = this.filterItem(item, options.selectFields);
      if (selectSettings) {
        additionalOpts = {
          ...additionalOpts,
          disableOptionCentering: true,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          change: (field, $event) => {
            // console.log('Select change event');
            // console.log(field);
            // console.log($event);
          },
          ...{ options: this.sortSelectFieldData(selectSettings.options) },
        };
      }
    }

    if (options.numberFields && options.numberFields.includes(item)) {
      additionalOpts = {
        ...additionalOpts,
        ...{ type: 'number' },
      };
    }

    if (options.additionalOpts) {
      const fieldAdditionalOpts = this.filterItem(item, options.additionalOpts);

      if (fieldAdditionalOpts) {
        additionalOpts = {
          ...additionalOpts,
          ...fieldAdditionalOpts.options,
        };
      }
    }

    if (options.isDisabled) {
      additionalOpts = {
        ...additionalOpts,
        ...{ disabled: options.isDisabled },
      };
    }

    if (options.disabledFields && options.disabledFields.includes(item)) {
      additionalOpts = {
        ...additionalOpts,
        ...{ disabled: true },
      };
    }

    additionalOpts = {
      ...additionalOpts,
      ...{ readonly: isReadOnly },
    };

    if (options.readOnlyFields && options.readOnlyFields.includes(item)) {
      additionalOpts = {
        ...additionalOpts,
        ...{ readonly: true },
      };
    }

    return additionalOpts;
  }

  static getCustomFieldValidators(item: string, options: any) {
    let validators = {};

    if (options.validators) {
      const customValidators = this.filterItem(item, options.validators);

      if (customValidators) {
        validators = {
          ...customValidators.validators,
        };
      }
    }

    return validators;
  }

  static getCustomFieldAsyncValidators(item: string, options: any) {
    let asyncValidators = {};

    if (options.asyncValidators) {
      const customValidators = this.filterItem(item, options.asyncValidators);

      if (customValidators) {
        asyncValidators = {
          ...customValidators.asyncValidators,
        };
      }
    }

    return asyncValidators;
  }

  static getHiddenFieldExpression(item: string, options: DynamicFormBaseOptions) {
    let fieldIsRequired = false;

    if (options.hiddenFields) {
      fieldIsRequired = options.hiddenFields.includes(item);
    }

    return fieldIsRequired;
  }

  static filterItem(item: string, opts: any[]) {
    const items = opts?.filter((fieldOpt) => {
      return fieldOpt.key === item;
    });

    if (items?.length) {
      return items[0];
    }

    return null;
  }

  /**
   * Covert string enums to format that can be bound to select field
   * @static
   * @param {*} enumType
   * @returns {DynamicFormSelectType[]}
   * @memberof FormUtils
   */
  static mapSelectOptionsFromEnum(
    translateService: TranslateService,
    enumType: any,
    enumName: string
  ): DynamicFormSelectType[] {
    const map: DynamicFormSelectType[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const item in enumType) {
      if (typeof enumType[item] !== 'number') {
        const enumRef = getI18nConstantRef(enumName);
        const stringReference = getI18nConstantRef(camelCase(item));

        const label =
          LC.ENUMS[enumRef] && LC.ENUMS[enumRef][stringReference]
            ? translateService.instant(LC.ENUMS[enumRef][stringReference])
            : `${enumName}-${item}-needMapTranslation`;

        map.push({
          value: item,
          label,
        });
      }
    }

    return map;
  }

  static stringToSpaceCased = (value: string) => value.replace(/([a-z])([A-Z])/g, '$1 $2');

  static mergeAsyncValidators(
    ...validators: DynamicFormAsyncValidator[][]
  ): DynamicFormAsyncValidator[] {
    const mergedValidatorLists = concat([], ...validators);
    const groupedValidators = groupBy(mergedValidatorLists, 'key');
    const mergedValidators = Object.values(groupedValidators).map((validatorGroup) =>
      merge({}, ...validatorGroup)
    );
    return mergedValidators;
  }

  /**
   * Apply sorting to all select fields
   * @static
   * @param {(DynamicFormSelectType[] | Observable<DynamicFormSelectType[]>)} selectOptions
   * @returns {(DynamicFormSelectType[] | Observable<DynamicFormSelectType[]>)}
   * @memberof FormUtils
   */
  static sortSelectFieldData(
    selectOptions: DynamicFormSelectType[] | Observable<DynamicFormSelectType[]>
  ): DynamicFormSelectType[] | Observable<DynamicFormSelectType[]> {
    const sortSelectOptions = (options: DynamicFormSelectType[]) => {
      return sortBy(
        options,
        ...['group', 'label'].map((sortField) => {
          return (element) =>
            (element[sortField] && element[sortField].toString().toLowerCase()) || '';
        })
      );
    };

    // basic array of select options
    if (Array.isArray(selectOptions)) {
      return sortSelectOptions(selectOptions);
    } else {
      // pipe observable output to include the sort method
      return selectOptions.pipe(
        map((options: DynamicFormSelectType[]) => sortSelectOptions(options))
      );
    }
  }

  /**
   * Determine if the form field is required
   * Look at the JSON Schema minimum etc constraints
   * Note - JSON Schema required is different to the form field required setting
   * @static
   * @param {JSONSchema7} schema
   * @param {string} key
   * @returns {boolean}
   * @memberof FormUtils
   */
  static isFieldValueRequired(schema: JSONSchema7, key: string): boolean {
    if (!schema) {
      return undefined;
    }

    let isRequired = false;
    const propertyDefinition: JSONSchema7 = schema.properties[key] as JSONSchema7;

    if (propertyDefinition) {
      switch (propertyDefinition.type) {
        default:
        case 'number':
        case 'integer':
        case 'object':
        case 'array':
          isRequired = propertyDefinition.minItems > 0;
          break;

        case 'string':
          isRequired = !!propertyDefinition.minLength || !!propertyDefinition.pattern;
          break;
      }
    }

    return isRequired;
  }

  static isFieldReadOnly(schema: JSONSchema7, key: string): boolean {
    let isReadOnly = false;
    if (!key.startsWith('fabricationReferences')) {
      isReadOnly = !!(schema.properties[key] as JSONSchema7).readOnly;
    }
    return isReadOnly;
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { elementIdDictionary } from '@constants/element-id-dictionary';
import {
  DynamicTableOptions,
  ColumnDefinition,
  InlineDataTableOptions,
} from '@models/dynamic-table/dynamic-table-options';
import { BehaviorSubject, Subscription, Subject } from 'rxjs';
import { DynamicFormBaseCustomComponent } from '@shared/components/dynamic-form/dynamic-form-base-custom-component';
import { filter, take } from 'rxjs/operators';
import { DynamicFormCustomUpdateEvent } from '@models/dynamic-form/dynamic-form-properties';
import {
  ConnectorInfo,
  ConnectorShapeType,
  RoundConnectorInfo,
  RoundGeometryType,
  RectangularConnectorInfo,
  RectangularGeometryType,
  OvalGeometryType,
  RoundConnectorMeasurementMode,
} from '@models/fabrication/connector-info';
import { FormUtils } from '@utils/formly/formly-utils';
import {
  RoundHexEndConnectorBreakPointTableRow,
  RoundConnectorBreakPointTableRow,
  ConnectorBreakPointTableRow,
  RectangularStepDownConnectorBreakPointTableRow,
  RectangularCouplingPlateConnectorBreakPointTableRow,
  RectangularConnectorBreakPointTableRow,
  OvalConnectorBreakPointTableRow,
  ConnectorBreakPointTableSidesType,
} from '@models/fabrication/connector-break-point-table';
import { InlineEditError, InlineEditType } from '@models/inline-edit/inline-edit.options';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { EnvironmentConstants } from '@constants/environment-constants';
import { SchemaService } from '@services/schema.service';
import { ValidatorService, InlineValidatorFunction } from '@services/validator.service';
import { TranslateService } from '@ngx-translate/core';
import { getI18nConstantRef } from '@utils/translate-utils';
import { JSONSchema7 } from 'json-schema';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers';
import { v4 as uuidv4 } from 'uuid';
import { ForgeUnitsService } from '@services/data-services/forge-units.service';
import { ConfirmationService } from '@services/confirmation/confirmation.service';
import { ConfirmationResult } from '@models/confirmation/confirmation';
import { DataElementType } from '@constants/data-element-types';
import { DynamicTableChangeService } from '@services/data-services';

@Component({
  selector: 'fab-connector-break-point-table',
  template: ` <fab-table-data *ngIf="tableOptions" [options]="tableOptions"></fab-table-data> `,
})
export class ConnectorBreakPointTableComponent
  extends DynamicFormBaseCustomComponent<ConnectorBreakPointTableRow[]>
  implements OnInit, OnDestroy
{
  tableOptions: DynamicTableOptions<ConnectorBreakPointTableRow> = null;
  tableDataSource: BehaviorSubject<ConnectorBreakPointTableRow[]>;
  tableChangeSubject: Subject<ConnectorBreakPointTableRow[]> = new Subject<
    ConnectorBreakPointTableRow[]
  >();
  tableChangeSubscription: Subscription;
  tableErrorReporterSubject: Subject<InlineEditError> = new Subject<InlineEditError>();
  tableErrorReporterSubscription: Subscription = null;
  idLookup = elementIdDictionary.connectorEdit;
  rows: ConnectorBreakPointTableRow[];
  customModelChangesSubscription: Subscription;
  currentMeasurementMode: RoundConnectorMeasurementMode = null;
  currentConnectorGeometryType: RoundGeometryType | RectangularGeometryType | OvalGeometryType =
    null;
  currentBreakPointTableRow: ConnectorBreakPointTableRow = null;

  constructor(
    private confirmationService: ConfirmationService,
    private schemaService: SchemaService,
    private validatorService: ValidatorService,
    private translate: TranslateService,
    private tableChangeService: DynamicTableChangeService<ConnectorBreakPointTableRow>,
    store$: Store<FDMState>
  ) {
    super(store$);
  }

  ngOnInit() {
    this.reportErrors();
    this.getModelUpdates();
    this.getTableChanges();
  }

  ngOnDestroy() {
    this.tableChangeSubscription?.unsubscribe();
    this.tableErrorReporterSubscription?.unsubscribe();
    this.customModelChangesSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  getModelUpdates() {
    // only need initial model update
    this.customModelChangesSubscription = this.formModelUpdater
      .pipe(
        filter(
          (changeEvent: DynamicFormCustomUpdateEvent) =>
            changeEvent.isFirstUpdate || changeEvent.subscriptionToken !== this.subscriptionToken
        )
      )
      .subscribe((changeEvent: DynamicFormCustomUpdateEvent) => {
        const model = changeEvent.data as ConnectorInfo;
        // set initial data
        if (this.currentMeasurementMode === null && model.shape === ConnectorShapeType.Round) {
          this.currentMeasurementMode = (model as RoundConnectorInfo).measurementMode;
        }

        if (this.currentConnectorGeometryType === null) {
          this.currentConnectorGeometryType = model.geometryType;
          this.currentBreakPointTableRow = this.getNewRowType(model);
          this.rows = model.breakPointTable;
          this.createTableDataOptions(model);
        }

        const oldMeasurementMode = this.currentMeasurementMode;
        if (model.shape === ConnectorShapeType.Round) {
          this.currentMeasurementMode = (model as RoundConnectorInfo).measurementMode;
        }

        const oldGeometryType = this.currentConnectorGeometryType;
        this.currentConnectorGeometryType = model.geometryType;
        const breakPointTableRow = this.getNewRowType(model);
        let requiresRefresh = false;

        // check to see if the shape or geometry type have changed
        if (model.geometryType !== oldGeometryType) {
          if (
            this.currentBreakPointTableRow.constructor !== breakPointTableRow.constructor &&
            !changeEvent.isFirstUpdate
          ) {
            if (!this.isDefaultRow()) {
              this.confirmationService
                .requestDisplayConfirmationDialog({
                  title: this.translate.instant(
                    LC.NOTIFICATIONS.TITLE_CHANGE_BREAKPOINT_TABLE_TYPE
                  ),
                  message: this.translate.instant(
                    LC.NOTIFICATIONS.MSG_CHANGE_BREAKPOINT_TABLE_TYPE
                  ),
                })
                .pipe(take(1))
                .subscribe((result: ConfirmationResult) => {
                  if (result === ConfirmationResult.OK) {
                    this.convertTableType(breakPointTableRow, model, changeEvent);
                  } else {
                    // revert the geometry type
                    this.currentConnectorGeometryType = oldGeometryType;

                    // set the value back in the form
                    this.form.controls['geometryType'].setValue(oldGeometryType);
                  }
                });
            } else {
              this.convertTableType(breakPointTableRow, model, changeEvent);
            }
          } else {
            requiresRefresh = true;
          }
        } else if (changeEvent.isFirstUpdate && this.currentConnectorGeometryType) {
          // assume data reverted
          requiresRefresh = true;
        }

        if (this.currentMeasurementMode !== oldMeasurementMode) {
          const roundConnector = model as RoundConnectorInfo;
          const field =
            roundConnector.geometryType === RoundGeometryType.HexEnd
              ? 'hexHeight'
              : 'bodyThickness';
          this.tableChangeService.submitUpdateColumnHeader({
            field,
            header:
              this.currentMeasurementMode === RoundConnectorMeasurementMode.OutsideDiameter
                ? this.translate.instant(LC.ENUMS.ROUND_CONNECTOR_MEASUREMENT_MODE.OUTSIDE_DIAMETER)
                : this.translate.instant(LC.ENUMS.ROUND_CONNECTOR_MEASUREMENT_MODE.WALL_THICKNESS),
          });
        }

        if (requiresRefresh) {
          this.currentBreakPointTableRow = breakPointTableRow;
          this.rows = model.breakPointTable;
          this.createTableDataOptions(model);
          this.tableDataSource.next(this.rows);
        }
      });
  }

  private convertTableType(
    breakPointTableRow: ConnectorBreakPointTableRow,
    model: ConnectorInfo,
    changeEvent: DynamicFormCustomUpdateEvent
  ) {
    this.currentBreakPointTableRow = breakPointTableRow;

    // don't over-write the table after a revert
    if (!changeEvent.isFirstUpdate) {
      model.breakPointTable = this.mapOldTableToNewTable(model);
    }

    this.rows = model.breakPointTable;
    this.createTableDataOptions(model);
    this.tableDataSource.next(this.rows);
  }

  mapOldTableToNewTable(model: ConnectorInfo): ConnectorBreakPointTableRow[] {
    const rows: ConnectorBreakPointTableRow[] = [];
    model.breakPointTable.forEach((oldRow) => {
      const newRow = this.getNewRowType(model);
      Object.keys(newRow).forEach((key) => {
        if (oldRow[key]) {
          newRow[key] = oldRow[key];
        }
      });
      rows.push(newRow);
    });

    return rows;
  }

  createTableDataOptions(model: ConnectorInfo) {
    // allow the tables to be re-created since we can change the table type
    this.tableDataSource =
      this.tableDataSource || new BehaviorSubject<ConnectorBreakPointTableRow[]>(this.rows);

    this.tableOptions = {
      id: uuidv4(),
      data: this.tableDataSource,
      controlIdPrefix: 'connector-break-point-table',
      rowSelection: { visible: true },
      isReadOnly: this.isReadOnly,
      maintainSelectionOnUpdate: true,
      columns: this.getColumnDefinitions(model),
      subscribeToTableChanges: this.tableChangeSubject,
      forceFullValidationOnEachChange: true,
      tableTypeOptions: {
        newRowData: () => this.getNewRowType(this.model),
      } as InlineDataTableOptions<ConnectorBreakPointTableRow>,
      errorReporter: this.tableErrorReporterSubject,
      toolbarOptions: {},
      enableMultiSelect: true,
    };
  }

  getTableChanges() {
    this.tableChangeSubscription = this.tableChangeSubject.subscribe(
      (rows: ConnectorBreakPointTableRow[]) => {
        this.rows = rows;
        this.updateSource(this.rows);
      }
    );
  }

  getValidator(model: ConnectorInfo, field?: string): InlineValidatorFunction {
    const validators: InlineValidatorFunction[] = [];
    const schemaType = this.getSchemaType(model);
    const schemaValidator = this.validatorService.getInlineEditSchemaValidator(schemaType.$id);
    validators.push(schemaValidator);
    const diameterFieldName = 'diameter';
    const longSideFieldName = 'longSide';
    const shortSideFieldName = 'shortSide';

    // only attach validators to required fields
    // validate diameter field
    if (field === diameterFieldName) {
      const uniqueValidator = this.validatorService.getInlineEditUniqueValidator(
        this.rows.map((x) => x[diameterFieldName]),
        diameterFieldName
      );
      validators.push(uniqueValidator);
    }

    // validate long side/short side combination fields
    if (field === longSideFieldName || field === shortSideFieldName) {
      const message = this.translate.instant(LC.VALIDATIONS.MSG_UNIQUE_COMBINATION_REQUIRED, {
        values: `${this.translate.instant(
          LC.DATATYPES.DEFINITIONS.CONNECTORS.LONG_SIDE
        )} / ${this.translate.instant(LC.DATATYPES.DEFINITIONS.CONNECTORS.SHORT_SIDE)}`,
      });

      const uniqueItemsValidator = this.validatorService.getInlineEditUniqueObjectsValidator(
        this.rows.map((x) => ({
          [longSideFieldName]: x[longSideFieldName],
          [shortSideFieldName]: x[shortSideFieldName],
        })),
        [longSideFieldName, shortSideFieldName],
        message
      );
      validators.push(uniqueItemsValidator);
    }

    return this.validatorService.mergeInlineEditValidators(...validators);
  }

  private getSchemaType(model: ConnectorInfo): JSONSchema7 {
    let parentSchema: JSONSchema7 = null;
    let definition: string = null;

    switch (model.shape) {
      default:
      case ConnectorShapeType.Round:
        parentSchema = this.schemaService.getSchemaByDataElementType(DataElementType.Connector);
        if (model.geometryType === RoundGeometryType.HexEnd) {
          definition =
            EnvironmentConstants.FSS_SUB_SCHEMA_ROUND_HEX_END_CONNECTOR_GEOMETRY_TABLE_ROW;
        } else if (model.geometryType === RoundGeometryType.Standard) {
          definition =
            EnvironmentConstants.FSS_SUB_SCHEMA_ROUND_STANDARD_CONNECTOR_GEOMETRY_TABLE_ROW;
        } else {
          definition = EnvironmentConstants.FSS_SUB_SCHEMA_ROUND_CONNECTOR_GEOMETRY_TABLE_ROW;
        }
        break;

      case ConnectorShapeType.Rectangular:
        parentSchema = this.schemaService.getSchemaByDataElementType(
          EnvironmentConstants.FSS_SCHEMA_RECTANGULAR_CONNECTOR
        );
        if (model.geometryType === RectangularGeometryType.CouplingPlate) {
          definition =
            EnvironmentConstants.FSS_SUB_SCHEMA_RECTANGULAR_COUPLING_PLATE_CONNECTOR_GEOMETRY_TABLE_ROW;
        } else if (model.geometryType === RectangularGeometryType.StepDown) {
          definition =
            EnvironmentConstants.FSS_SUB_SCHEMA_RECTANGULAR_STEP_DOWN_CONNECTOR_GEOMETRY_TABLE_ROW;
        } else {
          definition = EnvironmentConstants.FSS_SUB_SCHEMA_RECTANGULAR_CONNECTOR_GEOMETRY_TABLE_ROW;
        }
        break;

      case ConnectorShapeType.Oval:
        parentSchema = this.schemaService.getSchemaByDataElementType(
          EnvironmentConstants.FSS_SCHEMA_OVAL_CONNECTOR
        );
        definition = EnvironmentConstants.FSS_SUB_SCHEMA_OVAL_CONNECTOR_GEOMETRY_TABLE_ROW;
        break;
    }

    const schema = parentSchema.definitions[definition] as JSONSchema7;
    return schema;
  }

  private getNewRowType(model: ConnectorInfo): ConnectorBreakPointTableRow {
    let tableType: any;

    switch (model.shape) {
      case ConnectorShapeType.Round:
        const roundConnector = model as RoundConnectorInfo;
        if (roundConnector.geometryType === RoundGeometryType.HexEnd) {
          tableType = RoundHexEndConnectorBreakPointTableRow;
        } else {
          tableType = RoundConnectorBreakPointTableRow;
        }
        break;

      case ConnectorShapeType.Rectangular:
        const rectConnector = model as RectangularConnectorInfo;
        if (rectConnector.geometryType === RectangularGeometryType.StepDown) {
          tableType = RectangularStepDownConnectorBreakPointTableRow;
        } else if (rectConnector.geometryType === RectangularGeometryType.CouplingPlate) {
          tableType = RectangularCouplingPlateConnectorBreakPointTableRow;
        } else {
          tableType = RectangularConnectorBreakPointTableRow;
        }
        break;

      case ConnectorShapeType.Oval:
      default:
        tableType = OvalConnectorBreakPointTableRow;
        break;
    }

    return new tableType(); // eslint-disable-line new-cap
  }

  private getColumnDefinitions(model: ConnectorInfo): ColumnDefinition[] {
    const instance = this.getNewRowType(model);
    const units = ForgeUnitsService.getStandardLengthUnits(this.configUnitSystem);

    return Object.keys(instance).map<ColumnDefinition>((key) => {
      let header = '';
      if (
        model.shape === ConnectorShapeType.Round &&
        (key === 'bodyThickness' || key === 'hexHeight')
      ) {
        header =
          this.currentMeasurementMode === RoundConnectorMeasurementMode.OutsideDiameter
            ? this.translate.instant(LC.ENUMS.ROUND_CONNECTOR_MEASUREMENT_MODE.OUTSIDE_DIAMETER)
            : this.translate.instant(LC.ENUMS.ROUND_CONNECTOR_MEASUREMENT_MODE.WALL_THICKNESS);
      } else {
        header = `${this.translate.instant(
          LC.DATATYPES.DEFINITIONS.CONNECTORS[getI18nConstantRef(key)]
        )}`;
      }

      return {
        field: key,
        header,
        units: key === 'sides' ? null : units,
        inlineEdit: {
          disabled: this.isReadOnly,
          fieldType: key === 'sides' ? InlineEditType.select : InlineEditType.units,
          selectFieldOptions: () =>
            key === 'sides'
              ? FormUtils.mapSelectOptionsFromEnum(
                  this.translate,
                  ConnectorBreakPointTableSidesType,
                  'ConnectorBreakPointTableSidesType'
                )
              : null,
          validator: () => this.getValidator(model, key),
        },
      };
    });
  }

  /**
   * Checks to see if the tables contains only a single row and the row contains only the default values.
   */
  private isDefaultRow(): boolean {
    if (this.rows.length !== 1) {
      return false;
    }

    const row = this.rows[0];
    if (row instanceof RectangularCouplingPlateConnectorBreakPointTableRow) {
      const couplingPlateRow = row as RectangularCouplingPlateConnectorBreakPointTableRow;
      return (
        couplingPlateRow.longSide === 0 &&
        couplingPlateRow.shortSide === 0 &&
        couplingPlateRow.gasket === 0 &&
        couplingPlateRow.sides === ConnectorBreakPointTableSidesType.None &&
        couplingPlateRow.horizontalInset === 0 &&
        couplingPlateRow.length === 0 &&
        couplingPlateRow.thickness === 0 &&
        couplingPlateRow.verticalWidth === 0
      );
    }

    let nonZero = false;
    Object.keys(row).forEach((key: string) => {
      if (row[key] !== 0) {
        nonZero = true;
      }
    });

    return !nonZero;
  }
}

import { Injectable } from '@angular/core';
import AJV, { ErrorObject, ValidateFunction } from 'ajv';
import { EMPTY, Observable, Subject, of } from 'rxjs';
import { JSONSchema7 } from 'json-schema';
import { AbstractControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  map,
  filter,
  take,
  takeUntil,
  catchError,
  debounceTime,
  tap,
  switchMap,
} from 'rxjs/operators';
import { DynamicFormOperationType } from '@models/dynamic-form/dynamic-form-types';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { InlineEditData } from '@models/inline-edit/inline-edit.options';
import * as FormValidators from '@utils/formly/validators-utils';
import { TranslateService } from '@ngx-translate/core';
import { diff } from 'deep-object-diff';
import { isEmpty } from 'lodash';
import {
  DynamicFormOptions,
  DynamicFormValidator,
} from '@models/dynamic-form/dynamic-form-properties';
import * as EmailValidator from 'node-email-validation';
import { FormlyHookConfig } from '@ngx-formly/core/lib/models';
import { ForgeContentDataElement } from '@models/forge-content/forge-content-data-element';
import { ForgeContentService } from './forge-content.service';
import { EnvironmentConstants } from '@constants/environment-constants';
import { DynamicFormChangeService } from './data-services';
import { FormUtils } from '@utils/formly/formly-utils';

interface ValidationResult {
  valid: boolean;
  errors: ErrorObject[];
}

export interface InlineValidatorFunction {
  (inlineEditData: InlineEditData, value?: any): { error: boolean; message: string };
}

export interface UniqueFieldsConfig {
  fields: string[];
  allElements: () => Observable<any>;
  serverSideSearchOverride?: ServerSideSearchConfig;
}

export interface ServerSideSearchConfig {
  schemaType: string;
  customQuery?: (currentModel: ForgeContentDataElement) => string;
  filter?: string;
  customFilter?: (item: ForgeContentDataElement) => boolean;
}

export interface CustomErrorMessage {
  message: string;
  field: string;
  value: string;
}

// use singleton to register schemas once
@Injectable({
  providedIn: 'root',
})
export class ValidatorService {
  ajv = new AJV({ allErrors: true, strict: false }); // main ajv instance;
  private addedSchemas: Set<string> = new Set(); // reference to already added schemas

  private forgeContentService: ForgeContentService;

  constructor(
    private translate: TranslateService,
    private dynamicFormService: DynamicFormChangeService
  ) {}

  /**
   * Avoids a circular dependency
   * @param forgeContentService
   */
  setForgeContentService(forgeContentService: ForgeContentService): void {
    this.forgeContentService = forgeContentService;
  }

  /**
   * register json schema (v7) to use in validators
   *
   * @param {JSONSchema7} validationSchema
   * @memberof ValidatorService
   */
  registerValidationSchema(validationSchema: JSONSchema7): void {
    if (!this.addedSchemas.has(validationSchema.$id)) {
      this.addPropertiesSubSchemas(validationSchema);
      this.ajv.addSchema(validationSchema);
      this.addedSchemas.add(validationSchema.$id);
    }
  }

  private addPropertiesSubSchemas(schema: JSONSchema7): any {
    const parentId = schema.$id;
    const propKeys = Object.keys(schema.properties);
    const subSchemas = propKeys.map((k) => {
      const propertySchema: JSONSchema7 = {
        $id: `${parentId}#${k}`,
        properties: { [k]: schema.properties[k] },
      };

      if (schema.required.includes(k)) {
        propertySchema.required = [k];
      }

      return propertySchema;
    });

    subSchemas.forEach((subSchema) => {
      if (!this.addedSchemas.has(subSchema.$id)) {
        this.ajv.addSchema(subSchema);
        this.addedSchemas.add(subSchema.$id);
      }
    });
  }

  /**
   * proxy method for sync validation of a field value using AJV
   *
   * @param {string} parentSchemaId - model/data-element schema id
   * @param {string} fieldName - individual field to validate
   * @param {*} value - field value
   * @returns {ValidationResult}
   * @memberof ValidatorService
   */
  validateFieldSync(
    parentSchemaId: string,
    fieldName: string,
    value: any,
    customErrorMessage?: CustomErrorMessage
  ): ValidationResult {
    const validateBySchema = this.ajv.getSchema(`${parentSchemaId}#${fieldName}`);
    if (validateBySchema) {
      const validation = validateBySchema({ [fieldName]: value });

      const result = { valid: validation as boolean, errors: validateBySchema.errors };
      if (
        !validation &&
        customErrorMessage?.field?.length &&
        fieldName.includes(customErrorMessage.field)
      ) {
        validateBySchema.errors?.forEach((x) => {
          if (x.instancePath.includes(customErrorMessage.field))
            x.message = customErrorMessage.message;
        });
      }

      return result;
    } else {
      console.log(`No validation schema for ${fieldName}`);
      return { valid: true, errors: null };
    }
  }

  /**
   * get string message for unique validation
   *
   * @param {*} uniqueFieldRestrictions
   * @param {*} field
   * @returns
   * @memberof ValidatorService
   */
  getUniqueModelValidationMessage(uniqueFieldRestrictions, field?) {
    return FormValidators.uniqueModelValidationMessage.bind({
      translate: this.translate,
    })(uniqueFieldRestrictions, field);
  }

  /**
   * get unique server side search unique method
   *
   * @param {*} formOperation
   * @param {*} uniqueFields
   * @param {*} model
   * @param {*} config
   * @returns
   * @memberof ValidatorService
   */
  getUniqueServerSideSearch<T extends ForgeContentDataElement>(
    formOperation: DynamicFormOperationType,
    uniqueFields: string[],
    model: T,
    config: ServerSideSearchConfig
  ): Observable<boolean> {
    return this.uniqueServerSideSearch(formOperation, uniqueFields, model, config);
  }

  private uniqueServerSideSearch<T extends ForgeContentDataElement>(
    formOperation: DynamicFormOperationType,
    uniqueFields: string[],
    currentModel: T,
    config: ServerSideSearchConfig
  ): Observable<boolean> {
    const filter =
      `type.name:"${EnvironmentConstants.FSS_SCHEMA_NAMESPACE}:${config.schemaType}"` +
      (config.filter ? ` AND ${config.filter}` : '');
    const query = config.customQuery?.(currentModel);
    if (query && !query.length) return of(false);

    return this.forgeContentService.search<T>(query, filter).pipe(
      map((contentItems) => {
        let results = [...contentItems] || [];
        if (formOperation === 'edit' || formOperation === 'fix') {
          results = results.filter((x) => x.id !== currentModel.id);
        }
        if (config.customFilter) results = results.filter((x) => config.customFilter(x));

        let foundMatching = false;
        results.forEach((x) => {
          let allMatches = true;
          uniqueFields.forEach((field) => {
            if (x[field]?.trim().toLowerCase() !== currentModel[field]?.trim().toLowerCase()) {
              allMatches = false;
            }
          });

          if (allMatches) {
            foundMatching = true;
          }
        });

        return !foundMatching;
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  private validateModelDoesNotExist(
    formOperation: DynamicFormOperationType,
    uniqueFields: string[],
    currentModel: any,
    originalModelId: string,
    allElements: () => Observable<any>
  ) {
    return allElements().pipe(
      filter((all) => !!all),
      map((all) => {
        // filter removed items, seems after elements are deleted a null/undefined element remains in this list
        all = all.filter((x) => !!x);
        // remove the original model from the list so that it doesn't get tested against
        if (formOperation === 'edit' || formOperation === 'fix') {
          all = all.filter((x) => x.id !== originalModelId);
        }

        let foundMatching = false;
        all.forEach((x) => {
          let allMatches = true;
          uniqueFields.forEach((field) => {
            if (x[field]?.trim().toLowerCase() !== currentModel[field]?.trim().toLowerCase()) {
              allMatches = false;
            }
          });

          if (allMatches) {
            foundMatching = true;
          }
        });

        return !foundMatching;
      }),
      take(1)
    );
  }

  /**
   * Validate that an item or value doesn't exist
   * in certain property of object collection
   *
   * @param {*} item - item or value
   * @param {*} itemId - current editing item id
   * @param {any[]} list - list with all items
   * @param {string} property - property of object to compare with item
   * @returns
   * @memberof ValidatorService
   */
  validateUniqueItemInList(item: any, itemId: string, list: any[], property: string) {
    let validation = { valid: true, errors: null };
    let isValid = true;

    if (list?.length && item !== null && item !== undefined) {
      let duplicates = [];

      if (itemId) {
        duplicates = list.filter(
          (o) =>
            o.id !== itemId &&
            o[property].toString().toLowerCase().trim() === item.toString().toLowerCase().trim()
        );
        isValid = duplicates.length === 0;
      } else {
        duplicates = list.filter((x) => x === item);
        isValid = duplicates.length === 1;
      }

      validation = {
        valid: isValid,
        errors: isValid
          ? null
          : [{ message: this.translate.instant(LC.VALIDATIONS.MSG_ALREADY_EXIST) }],
      };
    }

    return validation;
  }

  /**
   * Validate an object in an exisitng list
   * e.g. use to validate value combinations in dynamic table rows
   * @param {*} item
   * @param {any[]} list
   * @param {string} message
   * @returns
   * @memberof ValidatorService
   */
  validateUniqueObjectInList(item: any, list: any[], message: string) {
    let validation = { valid: true, errors: null };
    let isValid = true;

    const itemsAreTheSame = (sourceItem: any, compareItem: any) =>
      Object.keys(diff(sourceItem, compareItem)).length === 0;

    if (list?.length && item) {
      let duplicates = [];

      duplicates = list.filter((x) => itemsAreTheSame(item, x));
      // only matched self in list
      isValid = duplicates.length <= 1;

      validation = {
        valid: isValid,
        errors: isValid ? null : [{ message }],
      };
    }

    return validation;
  }

  /**
   * Get adapter to add unique validator in dynamic forms
   *
   * @param {{ fields: string[]; allElements: () => Observable<any> }} uniqueFieldsConfig
   * @param {DynamicFormOperationType} formOperation
   * @returns
   * @memberof ValidatorService
   */
  getUniqueFieldsValidator = (
    uniqueFieldsConfig: UniqueFieldsConfig,
    formOperation: DynamicFormOperationType
  ) => {
    let errorMessage: string;
    return {
      uniqueFieldsValidator: {
        expression: (_: any, field: FormlyFieldConfig): Promise<boolean> => {
          const editableFormOperations = ['create', 'copy', 'edit', 'fix'];
          return new Promise((resolve, reject) => {
            if (
              editableFormOperations.includes(formOperation) &&
              uniqueFieldsConfig.fields.includes(field.key as string)
            ) {
              const currentModel = {
                ...field.model,
                [field.key as string]: field.formControl.value,
              };
              const modelId = field.model.id;

              const validation$ = uniqueFieldsConfig.serverSideSearchOverride
                ? this.uniqueServerSideSearch(
                    formOperation,
                    uniqueFieldsConfig.fields,
                    currentModel,
                    uniqueFieldsConfig.serverSideSearchOverride
                  )
                : this.validateModelDoesNotExist(
                    formOperation,
                    uniqueFieldsConfig.fields,
                    currentModel,
                    modelId,
                    uniqueFieldsConfig.allElements
                  );

              validation$
                .pipe(
                  catchError((err) => {
                    reject(err);
                    return EMPTY;
                  })
                )
                .subscribe((isValidModel: boolean) => {
                  if (!isValidModel) {
                    const message = this.getUniqueModelValidationMessage(
                      uniqueFieldsConfig.fields,
                      field
                    );

                    errorMessage = message;
                  }
                  resolve(isValidModel);
                });
            } else {
              resolve(true);
            }
          });
        },
        message: () => errorMessage,
      },
    };
  };

  /**
   * Get adapter to use a better unique validator using custom hooks in formly
   *
   * @param {*} uniqueFieldValidator - uniqueValidator object that contains expression and message
   * @param {*} onDestroy on Destroy subject in parent component
   * @returns {FormlyLifeCycleOptions<FormlyHookFn>}
   * @memberof ValidatorService
   */
  getUniqueFieldsHooks(
    uniqueFieldValidator,
    uniqueFields: string[],
    onDestroy: Subject<void>,
    validateImediately: boolean,
    requiresServerSideValidation: boolean
  ): FormlyHookConfig {
    const runValidator = (field: FormlyFieldConfig) =>
      uniqueFieldValidator.uniqueFieldsValidator.expression(null, field).then((result) => {
        if (!result) {
          field.parent.fieldGroup
            .filter((f) => uniqueFields.includes(f.key as string))
            .forEach((f) => {
              f.formControl.setErrors({
                ...f.formControl.errors,
                uniqueValidator: {
                  message: uniqueFieldValidator.uniqueFieldsValidator.message(),
                },
              });
              f.formControl.markAsDirty();
              f.formControl.markAsTouched();
            });
        } else {
          field.parent.fieldGroup
            .filter((f) => uniqueFields.includes(f.key as string))
            .forEach((f) => {
              const errors = f.formControl.errors;
              if (errors && errors.uniqueValidator) {
                f.formControl.setErrors({ ...errors, uniqueValidator: undefined });
                f.formControl.updateValueAndValidity({ emitEvent: false });
              }
            });
        }

        if (requiresServerSideValidation)
          this.dynamicFormService.updateInvalidComponent(`${field.key}-unique-validator`, false);
      });

    return {
      onInit: (field: FormlyFieldConfig) => {
        if (validateImediately) {
          runValidator(field);
        }

        field.formControl.valueChanges
          .pipe(
            takeUntil(onDestroy),
            tap(() => {
              if (requiresServerSideValidation)
                this.dynamicFormService.updateInvalidComponent(
                  `${field.key}-unique-validator`,
                  true
                );
            }),
            debounceTime(500),
            switchMap(() => {
              if (requiresServerSideValidation)
                this.dynamicFormService.updateInvalidComponent(
                  `${field.key}-unique-validator`,
                  true
                );
              runValidator(field);
              return of(true);
            })
          )
          .subscribe();
      },
    };
  }

  /**
   * Merge multiple inline edit validator adapters
   *
   * @param {...InlineValidatorFunction[]} validators
   * @returns
   * @memberof ValidatorService
   */
  mergeInlineEditValidators(...validators: InlineValidatorFunction[]): InlineValidatorFunction {
    return (inlineEditData: InlineEditData, value: any) => {
      const results = validators.map((validatorFn) => validatorFn(inlineEditData, value));
      const error = results.some((r) => r.error);
      const message = results
        .map((r) => r.message)
        .filter(Boolean)
        .join('. ');
      return { error, message };
    };
  }

  /**
   * Get adapter to add unique validator for inline edit options
   *
   * @param {any[]} list
   * @param {string} property
   * @returns
   * @memberof ValidatorService
   */
  getInlineEditUniqueValidator = (list: any[], property: string): InlineValidatorFunction => {
    return (inlineEditData: InlineEditData, value: any) => {
      if (inlineEditData.field !== property) {
        return { error: false, message: '' };
      }

      const { valid, errors } = this.validateUniqueItemInList(
        value,
        inlineEditData.row.id,
        list,
        property
      );
      return { error: !valid, message: errors && errors[0].message };
    };
  };

  /**
   * Get adapter to add unique objects validator for inline edit options
   * @memberof ValidatorService
   */
  getInlineEditUniqueObjectsValidator = (
    list: any[],
    properties: string[],
    message: string
  ): InlineValidatorFunction => {
    return (inlineEditData: InlineEditData) => {
      const validMessage = { error: false, message: '' };
      if (properties?.length && !properties.find((x) => x === inlineEditData.field)) {
        return validMessage;
      }

      // no need to validate against lists of one entry
      if (list?.length && list.length === 1) {
        return validMessage;
      }

      const item = {};
      properties.forEach((prop: string) => (item[prop] = inlineEditData.row[prop]));
      const { valid, errors } = this.validateUniqueObjectInList(item, list, message);
      return { error: !valid, message: errors && errors[0].message };
    };
  };

  /**
   * Get adapter to add schema validator for inline edit options
   *
   * @param {string} schemaId
   * @returns
   * @memberof ValidatorService
   */
  getInlineEditSchemaValidator = (
    schemaId: string,
    customErrorMessage?: CustomErrorMessage
  ): InlineValidatorFunction => {
    return (inlineEditData: InlineEditData, value: any) => {
      const { valid, errors } = this.validateFieldSync(
        schemaId,
        inlineEditData.field,
        value,
        customErrorMessage
      );
      return { error: !valid, message: errors && errors[0].message };
    };
  };

  /**
   * Get adapter to add schema validator for dynamic forms
   *
   * @param {string} schemaId
   * @returns
   * @memberof ValidatorService
   */
  getFormSchemaValidator = (schemaId: string) => {
    const currentErrors: Map<string, string> = new Map();
    return {
      schemaValidator: {
        expression: (control: AbstractControl, field: FormlyFieldConfig): boolean => {
          const validateBySchema: ValidateFunction = this.ajv.getSchema(schemaId);

          let currentModel = {};
          const key = field.key as string;
          if (key.startsWith('fabricationReferences')) {
            const index = Number(key.split('.')[1]);
            if (!isNaN(index)) {
              const validateReferences = [...(field?.model?.fabricationReferences || [])];
              if (!isEmpty(validateReferences) && validateReferences[index]) {
                validateReferences[index].externalId = control.value;
                currentModel = { ...field.model, fabricationReferences: validateReferences };
              }
            } else {
              return true;
            }
          } else {
            let value = control.value;
            if (typeof value === 'string') {
              value = value.length < 1 ? undefined : value.trim();
            }
            currentModel = { ...field.model, [key]: value };
          }

          let isValid = validateBySchema(currentModel) as boolean;

          if (!isValid) {
            const errorsForField = validateBySchema.errors.filter(
              (x) => x.instancePath === `/${field.key}`
            );

            if (errorsForField.length) {
              currentErrors.set(key, errorsForField[0].message);
            } else {
              isValid = true;
            }
          }

          return isValid;
        },
        message: (error, field: FormlyFieldConfig) =>
          error && currentErrors.get(field.key as string),
      },
    };
  };

  getEmailValidator = (
    fieldKey: string,
    restrictedAddresses?: string[],
    restrictedAddressErrorMessage?: string
  ): DynamicFormValidator => {
    let currentError = '';
    return {
      emailValidator: {
        expression: (control: AbstractControl, field: FormlyFieldConfig): boolean => {
          const key = field.key as string;
          if (key === fieldKey) {
            const value = control.value as string;
            // add email validation logic here i.e. not empty and matches email regex
            if (!value) {
              currentError = this.translate.instant(LC.VALIDATIONS.FORM.REQUIRED, {
                label: field.props.label,
              });
              return false;
            }
            if (!EmailValidator.is_email_valid(value)) {
              currentError = this.translate.instant(LC.VALIDATIONS.FORM.VALID, {
                label: field.props.label,
              });
              return false;
            } else if (restrictedAddresses?.length) {
              if (restrictedAddresses.find((x) => x === value)) {
                currentError = restrictedAddressErrorMessage;

                return false;
              }
            }

            return true;
          } else {
            return true;
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        message: (error: any, field: FormlyFieldConfig) => error && currentError,
      },
    };
  };

  getSpecialCharactersValidator = (
    fieldKey: string,
    regexp: RegExp,
    prohibitedCharacters?: string
  ): DynamicFormValidator => {
    let currentError = '';
    return {
      specialCharactersValidator: {
        expression: (control: AbstractControl, field: FormlyFieldConfig): boolean => {
          const key = field.key as string;
          if (key === fieldKey) {
            const value = control.value as string;
            if (!value) {
              currentError = this.translate.instant(LC.VALIDATIONS.FORM.REQUIRED, {
                label: field.props.label.toLocaleLowerCase(),
              });
              return false;
            }

            if (value && regexp.test(value)) {
              currentError = this.translate.instant(LC.VALIDATIONS.FORM.PROHIBITED_CHARACTERS, {
                field: field.props.label.toLocaleLowerCase(),
                prohibitedCharacters,
              });
              return false;
            }
          }

          return true;
        },
        message: (error: any) => error && currentError,
      },
    };
  };

  validateForgeContentData = (
    dataElement: ForgeContentDataElement,
    schema: JSONSchema7
  ): boolean => {
    const model = ForgeContentService.removeTopLevelProperties(dataElement, ['name']);

    if (model.hasOwnProperty('schemaType')) delete model['schemaType'];

    //remove all the records with undefined as value
    Object.keys(model).forEach((key) => typeof model[key] === 'undefined' && delete model[key]);

    const validateBySchema: ValidateFunction = this.ajv.getSchema(schema.$id);

    const isValid = validateBySchema(model);

    if (!isValid) {
      console.log('Validation errors:', validateBySchema.errors);
    }

    return isValid;
  };

  validateSpecialCharacters = (
    text: string,
    regexp: RegExp,
    prohibitedCharacters?: string
  ): string => {
    let errorName = '';
    const field = this.translate.instant(LC.DATATYPES.DEFINITIONS.PARTS.NAME).toLowerCase();
    if (text && regexp.test(text)) {
      errorName = this.translate.instant(LC.VALIDATIONS.FORM.PROHIBITED_CHARACTERS, {
        field,
        prohibitedCharacters,
      });
    }

    return errorName;
  };

  formTitleValidation(
    title: string,
    options: DynamicFormOptions<any>,
    model: any,
    onDestroy: Subject<void>
  ): string {
    const MAX_LENGTH = 256;

    const editableFormOperations: DynamicFormOperationType[] = ['create', 'copy', 'edit', 'fix'];
    let message = '';
    const formOperation = options.formOperation;
    const uniqueFieldsConfig = options.uniqueFields;
    if (
      editableFormOperations.includes(formOperation) &&
      uniqueFieldsConfig.fields.includes('name')
    ) {
      if (!title) {
        message = this.translate.instant(LC.VALIDATIONS.FORM.REQUIRED, {
          label: FormUtils.stringToSpaceCased(
            options.titleField.charAt(0).toUpperCase() + options.titleField.substring(1)
          ),
        });
        return message;
      }

      if (title.length > MAX_LENGTH) {
        message = this.translate.instant(LC.VALIDATIONS.FORM.MAX_LENGTH, {
          maxLength: MAX_LENGTH,
        });
        return message;
      }

      const currentModel = {
        ...model,
        name: title,
      };
      const modelId = model.id;

      const validation$ = uniqueFieldsConfig.serverSideSearchOverride
        ? this.uniqueServerSideSearch(
            formOperation,
            uniqueFieldsConfig.fields,
            currentModel,
            uniqueFieldsConfig.serverSideSearchOverride
          )
        : this.validateModelDoesNotExist(
            formOperation,
            uniqueFieldsConfig.fields,
            currentModel,
            modelId,
            uniqueFieldsConfig.allElements
          );

      validation$
        .pipe(
          takeUntil(onDestroy),
          catchError(() => {
            return EMPTY;
          })
        )
        .subscribe((isValidModel: boolean) => {
          if (!isValidModel) {
            message = this.getUniqueModelValidationMessage(uniqueFieldsConfig.fields);
          }
        });
    }
    return message;
  }
}

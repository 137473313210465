import { Injectable } from '@angular/core';
import { SwUpdate, UnrecoverableStateEvent, VersionEvent } from '@angular/service-worker';
import { BinaryStorageService } from '@cache/binary-storage.service';
import { Observable, of, combineLatest, Subject, timer } from 'rxjs';
import { delay, map, take, takeUntil, tap } from 'rxjs/operators';
import { NotificationType } from '@models/notification/notification';
import { AuthService } from '../auth.service';
import { NotificationService } from '../notification.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { EnvironmentService } from '@services/environment.service';
import helpLinks from '@assets/help/help-links.json';
import { CacheService } from '../cache.service';
import { AppConstants } from '@constants/app-constants';

/**
 * Checks for Service Worker updates
 * @export
 * @class AppUpdateService
 */
@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {
  private destroyed$ = new Subject<void>();
  private logPrefix = 'App Update Service Log:';
  private appUpdateStorageKey = 'fdm-version-update';

  public updateReady = false;
  public get appIsUpdated(): boolean {
    return !!localStorage.getItem(this.appUpdateStorageKey);
  }

  constructor(
    private swUpdate: SwUpdate,
    private binaryStorageService: BinaryStorageService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private envService: EnvironmentService,
    private cacheService: CacheService
  ) {
    // Check for app updates on load and every minute afterwards.
    // The swUpdate.checkForUpdate() method downloads the update under the hood.
    // Downloading may take somewhere in between 5 to 20 seconds,
    // This way we optimize the user experience by downloading in the background
    if (this.swUpdate.isEnabled) {
      timer(0, 60 * 1000)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => this.checkForAppUpdates());
    }
  }

  ngOnDestroy(): void {
    this.destroyed$?.next();
    this.destroyed$?.complete();
  }

  public checkForAppUpdates = async (): Promise<boolean> => {
    console.log(`${this.logPrefix} checking for app updates`);

    const hasUpdate = await this.swUpdate.checkForUpdate();

    if (hasUpdate) {
      this.updateReady = true;
    }

    console.log(`${this.logPrefix} has app update:`, this.updateReady);

    return this.updateReady;
  };

  public listenForAppUpdates = (): Observable<VersionEvent> => {
    if (!this.swUpdate.isEnabled) {
      return of(null);
    }

    return this.swUpdate.versionUpdates.pipe(
      tap((event: VersionEvent) => {
        switch (event.type) {
          case 'NO_NEW_VERSION_DETECTED':
            console.log(`${this.logPrefix} no new app version detected`);
            break;
          case 'VERSION_DETECTED':
            console.log(`${this.logPrefix} downloading new app version: ${event.version.hash}`);
            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.log(
              `${this.logPrefix} failed to install app version ${event.version.hash}: ${event.error}`
            );
            break;
          case 'VERSION_READY':
            console.log(`${this.logPrefix} new app version ${event.latestVersion.hash} is ready`);
            this.activateVersionUpdate().pipe(take(1)).subscribe();
            break;
        }
      })
    );
  };

  public listenForUnrecoverableState = (): Observable<UnrecoverableStateEvent> => {
    if (!this.swUpdate.isEnabled) {
      return of(null);
    }

    return this.swUpdate.unrecoverable.pipe(
      tap((event: UnrecoverableStateEvent) => {
        console.log(`${this.logPrefix} app is in an unrecoverable state: ${event.reason}`);
        this.activateVersionUpdate().pipe(take(1)).subscribe();
      })
    );
  };

  public activateVersionUpdate(targetUrl?: string): Observable<boolean> {
    return combineLatest([
      this.binaryStorageService.cleanBinaryStorage(),
      this.cacheService.cleanDataCacheStorageForVersionUpdate(),
    ]).pipe(
      map((results: [boolean, boolean]) => results.every((x) => x === true)),
      tap((ready: boolean) => {
        if (!ready) {
          console.error(`${this.logPrefix} Error on attempt to clean binary storage or cache`);
        }

        this.setVersionUpdatedStorageItem();
        this.authService.reloadApp(targetUrl);
      })
    );
  }

  public notifyVersionUpdateIfAvailable = (): void => {
    // display version update notification if required
    // delay to avoid showing too early prior to the browser refresh
    // triggered by the new angular version
    of(this.appIsUpdated)
      .pipe(delay(5000), take(1))
      .subscribe((isUpdated: boolean) => {
        if (!isUpdated) return;

        this.clearVersionUpdateStorageKey();

        const linkText = this.translate.instant(LC.NOTIFICATIONS.VERSION_UPDATE.WHATS_NEW);
        const message = this.translate.instant(LC.NOTIFICATIONS.VERSION_UPDATE.MESSAGE);
        const description = this.translate.instant(LC.NOTIFICATIONS.VERSION_UPDATE.DESCRIPTION, {
          appName: AppConstants.APP_NAME,
        });

        this.notificationService.showFlyoutNotification({
          message,
          description,
          type: NotificationType.Success,
          showToast: true,
          withLink: {
            text: linkText,
            action: () =>
              window.open(
                `${this.envService.environment.helpLinks.baseUrl}${helpLinks.general.releaseNotes}`,
                '_blank'
              ),
          },
        });
      });
  };

  private clearVersionUpdateStorageKey = (): void => {
    localStorage.removeItem(this.appUpdateStorageKey);
  };

  private setVersionUpdatedStorageItem = (): void => {
    localStorage.setItem(this.appUpdateStorageKey, '1');
  };
}

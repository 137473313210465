import { Component, OnInit } from '@angular/core';
import {
  PartReference,
  ServiceTemplatePalette,
  ServiceTemplateSizeRestriction,
  ServiceTemplateSizeRestrictionOption,
  ServiceTemplateInfo,
} from '@models/fabrication/service-template-info';
import { elementIdDictionary } from '@constants/element-id-dictionary';
import { BehaviorSubject, Subscription, Subject, of } from 'rxjs';
import {
  DynamicTableOptions,
  InlineDataTableOptions,
  InlineDataMultiDeleteResult,
} from '@models/dynamic-table/dynamic-table-options';
import { DynamicFormBaseCustomComponent } from '@shared/components/dynamic-form/dynamic-form-base-custom-component';
import { filter } from 'rxjs/operators';
import {
  DynamicFormCustomUpdateEvent,
  DynamicFormEditableSelectType,
} from '@models/dynamic-form/dynamic-form-properties';
import { InlineEditType, InlineEditData } from '@models/inline-edit/inline-edit.options';
import { NotificationService } from '@services/notification.service';
import {
  Notification,
  NotificationType,
  NotificationPlace,
} from '@models/notification/notification';
import { InlineValidatorFunction, ValidatorService } from '@services/validator.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers';
import { v4 as uuidv4 } from 'uuid';
import { ForgeUnitsService } from '@services/data-services/forge-units.service';
import { UnitSchemaType } from '@models/forge-units/forge-unit-schema-types';
import { ConfigUnitSystem } from '@models/fabrication/config';
import { MAX_NAME_LENGTH } from '@constants/generic-constants';

interface ServiceTemplateSizeRestrictionRow {
  id: string;
  description: string;
  greaterThan: number;
  lessThanOrEqual: number;
}

@Component({
  selector: 'fab-service-template-size-restrictions',
  template: ` <fab-table-data [options]="tableOptions"></fab-table-data> `,
})
export class ServiceTemplateSizeRestrictionsComponent
  extends DynamicFormBaseCustomComponent<ServiceTemplateSizeRestriction[]>
  implements OnInit
{
  tableOptions: DynamicTableOptions<ServiceTemplateSizeRestrictionRow> = null;
  tableChangeSubject: Subject<ServiceTemplateSizeRestrictionRow[]> = new Subject<
    ServiceTemplateSizeRestrictionRow[]
  >();
  tableChangeSubscription: Subscription;
  tableDataSource: BehaviorSubject<ServiceTemplateSizeRestrictionRow[]>;
  sizeRestrictions: ServiceTemplateSizeRestrictionRow[];
  parts: PartReference[];
  customModelChangesSubscription: Subscription;

  selectedSizeRestriction: ServiceTemplateSizeRestrictionRow = null;

  idLookup = elementIdDictionary.partTemplateEdit;

  private readonly nameMaxLength = MAX_NAME_LENGTH;
  private readonly nameMinLength = 1;

  private readonly nameFieldName = 'description';
  private readonly lessThanEqualFieldName = 'lessThanOrEqual';
  private readonly greaterThanFieldName = 'greaterThan';

  constructor(
    private notificationService: NotificationService,
    private validatorService: ValidatorService,
    private translate: TranslateService,
    store$: Store<FDMState>
  ) {
    super(store$);
  }

  ngOnInit() {
    this.getModelUpdates();
  }

  ngOnDestroy(): void {
    this.tableChangeSubscription?.unsubscribe();
    this.customModelChangesSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  getModelUpdates() {
    // only need initial model update
    // palettes component is not affected by changes to parts or palettes
    this.customModelChangesSubscription = this.formModelUpdater
      .pipe(
        filter(
          (changeEvent: DynamicFormCustomUpdateEvent) =>
            changeEvent.isFirstUpdate || changeEvent.subscriptionToken !== this.subscriptionToken
        )
      )
      .subscribe((changeEvent: DynamicFormCustomUpdateEvent) => {
        const model = changeEvent.data as ServiceTemplateInfo;
        this.sizeRestrictions = model.sizeRestrictions.map((x) => {
          return {
            id: x.id,
            description: x.description,
            greaterThan:
              x.greaterThan.option === ServiceTemplateSizeRestrictionOption.Unrestricted
                ? -1
                : x.greaterThan.value,
            lessThanOrEqual:
              x.lessThanOrEqual.option === ServiceTemplateSizeRestrictionOption.Unrestricted
                ? -1
                : x.lessThanOrEqual.value,
          };
        });
        this.parts = this.getParts(model.palettes);

        // assume model has been reverted
        if (this.tableOptions && changeEvent.isFirstUpdate) {
          this.tableDataSource.next(this.sizeRestrictions);
        }
        if (!this.tableOptions) {
          this.reportErrors();
          this.createTableDataOptions();
        }
      });

    this.tableChangeSubscription = this.tableChangeSubject.subscribe(
      (rows: ServiceTemplateSizeRestrictionRow[]) => {
        this.sizeRestrictions = rows;
        this.refreshData();
      }
    );
  }

  getParts(palettes: ServiceTemplatePalette[]) {
    const parts: PartReference[] = [];
    if (palettes && palettes.length) {
      palettes.forEach((palette) => {
        palette.partCollections.forEach((partCollection) => {
          parts.push(...partCollection.parts);
        });
      });
    }

    return parts;
  }

  refreshData() {
    // convert the size restrictions back
    const sizeRestrictions = this.sizeRestrictions.map((x) => {
      const sizeRestriction: ServiceTemplateSizeRestriction = {
        description: x.description,
        id: x.id,
        greaterThan: {
          option:
            x.greaterThan >= 0
              ? ServiceTemplateSizeRestrictionOption.Value
              : ServiceTemplateSizeRestrictionOption.Unrestricted,
          value: x.greaterThan >= 0 ? x.greaterThan : 0,
        },
        lessThanOrEqual: {
          option:
            x.lessThanOrEqual >= 0
              ? ServiceTemplateSizeRestrictionOption.Value
              : ServiceTemplateSizeRestrictionOption.Unrestricted,
          value: x.lessThanOrEqual >= 0 ? x.lessThanOrEqual : 0,
        },
      };

      return sizeRestriction;
    });

    this.model.sizeRestrictions = sizeRestrictions;

    this.updateSource(sizeRestrictions);
  }

  public createTableDataOptions() {
    if (!this.tableOptions) {
      this.tableDataSource = new BehaviorSubject<ServiceTemplateSizeRestrictionRow[]>(
        this.sizeRestrictions
      );

      const sizeRestrictionValueFormatter = (value: number) => {
        return value === -1
          ? this.translate.instant(
              LC.FEATURE_MODULES.SERVICE_TEMPLATES.SIZE_RESTRICTIONS.UNRESTRICTED
            )
          : value;
      };

      const lengthUnits =
        this.configUnitSystem === ConfigUnitSystem.Imperial
          ? UnitSchemaType.Inches
          : UnitSchemaType.Millimeters;

      this.tableOptions = {
        id: uuidv4(),
        data: this.tableDataSource,
        controlIdPrefix: 'part-template-size-restriction-list',
        rowSelection: { visible: true },
        isReadOnly: this.isReadOnly,
        subscribeToTableChanges: this.tableChangeSubject,
        errorReporter: this.tableErrorReporterSubject,
        forceFullValidationOnEachChange: true,
        columns: [
          {
            field: 'description',
            header: this.translate.instant(
              LC.FEATURE_MODULES.SERVICE_TEMPLATES.SIZE_RESTRICTIONS.DESCRIPTION
            ),
            inlineEdit: {
              disabled: this.isReadOnly,
              fieldType: InlineEditType.text,
              validator: () => this.getValidator(),
            },
          },
          {
            field: this.greaterThanFieldName,
            header: this.translate.instant(
              LC.FEATURE_MODULES.SERVICE_TEMPLATES.SIZE_RESTRICTIONS.MORE_THAN
            ),
            formatter: sizeRestrictionValueFormatter,
            units: ForgeUnitsService.getStandardLengthUnits(this.configUnitSystem),
            inlineEdit: {
              disabled: this.isReadOnly,
              fieldType: InlineEditType.selectEditable,
              selectFieldOptions: (row: ServiceTemplateSizeRestrictionRow, field) => {
                return this.getSelectFields(row, field, lengthUnits);
              },
              validator: () => this.getValidator(),
            },
          },
          {
            field: this.lessThanEqualFieldName,
            header: this.translate.instant(
              LC.FEATURE_MODULES.SERVICE_TEMPLATES.SIZE_RESTRICTIONS.UP_TO
            ),
            formatter: sizeRestrictionValueFormatter,
            units: ForgeUnitsService.getStandardLengthUnits(this.configUnitSystem),
            inlineEdit: {
              disabled: this.isReadOnly,
              fieldType: InlineEditType.selectEditable,
              selectFieldOptions: (row: ServiceTemplateSizeRestrictionRow, field) => {
                return this.getSelectFields(row, field, lengthUnits);
              },
              validator: () => this.getValidator(),
            },
          },
        ],
        tableTypeOptions: {
          newRowData: () => {
            const sizeRestriction: ServiceTemplateSizeRestrictionRow = {
              description: this.translate.instant(
                LC.FEATURE_MODULES.SERVICE_TEMPLATES.SIZE_RESTRICTIONS.UNRESTRICTED
              ),
              greaterThan: -1,
              lessThanOrEqual: -1,
              id: uuidv4(),
            };

            return sizeRestriction;
          },
          customDeleteRow: {
            deleteRow: (sizeRestriction: ServiceTemplateSizeRestrictionRow) =>
              of(this.removeSizeRestriction(sizeRestriction)),
            modalOptions: () => {
              return { open: false };
            },
          },
          customDeleteMultipleRows: {
            deleteRows: (sizeRestrictions: ServiceTemplateSizeRestrictionRow[]) =>
              of(this.removeSizeRestrictions(sizeRestrictions)),
            modalOptions: () => {
              return { open: false };
            },
          },
          disableDeleteCondition: 'selectedAll',
        } as InlineDataTableOptions<ServiceTemplateSizeRestrictionRow>,
        toolbarOptions: {},
        enableMultiSelect: true,
      };
    }
  }

  getSelectFields = (
    row: ServiceTemplateSizeRestrictionRow,
    columnField: string,
    units: UnitSchemaType
  ): DynamicFormEditableSelectType[] => {
    const value: number = row[columnField];

    const options: DynamicFormEditableSelectType[] = [
      {
        label: this.translate.instant(
          LC.FEATURE_MODULES.SERVICE_TEMPLATES.SIZE_RESTRICTIONS.UNRESTRICTED
        ),
        value: -1,
        isCustomValue: (value) => value >= 0,
        units,
      },
      {
        label: value >= 0 ? value.toString() : '0',
        isCustomValue: (value) => value >= 0,
        value: value >= 0 ? value : 0,
        units,
      },
    ];

    return options;
  };

  getValidator(): InlineValidatorFunction {
    const uniqueValidator = this.validatorService.getInlineEditUniqueValidator(
      this.sizeRestrictions,
      'description'
    );

    const sizeRestrictionValidator: InlineValidatorFunction = (
      inlineEditData: InlineEditData,
      value: number
    ) => {
      let error = false;
      let message = '';
      const isLessThanEqualField = inlineEditData.field === this.lessThanEqualFieldName;
      const isGreaterThanField = inlineEditData.field === this.greaterThanFieldName;

      if (isLessThanEqualField || isGreaterThanField) {
        const isUnrestricted = inlineEditData.value === -1;

        if (!isUnrestricted) {
          let errorMessage = LC.FEATURE_MODULES.SERVICE_TEMPLATES.SIZE_RESTRICTIONS.RANGE_OUT;

          const greaterThanValue: number = inlineEditData.row[this.greaterThanFieldName];

          const lessThanEqualValue: number = inlineEditData.row[this.lessThanEqualFieldName];

          // validate lessThanEqual
          if (isLessThanEqualField) {
            error = greaterThanValue >= 0 && value <= greaterThanValue;

            if (greaterThanValue >= 0) {
              error = value <= greaterThanValue;
            }

            // don't allow a 0 value in the less than column
            if (value === 0) {
              error = true;
              errorMessage = LC.FEATURE_MODULES.SERVICE_TEMPLATES.SIZE_RESTRICTIONS.POSITIVE_RANGE;
            }
          }

          // validate greaterThan
          if (isGreaterThanField) {
            error = lessThanEqualValue >= 0 && value >= lessThanEqualValue;
          }

          message = error ? this.translate.instant(errorMessage) : '';
        }
      }

      return { error, message };
    };

    const nameLengthValidator: InlineValidatorFunction = (
      inlineEditData: InlineEditData,
      value: string
    ) => {
      let error = false;
      let message = '';
      if (inlineEditData.field === this.nameFieldName) {
        if (value.length > this.nameMaxLength) {
          error = true;
          message = this.translate.instant(LC.VALIDATIONS.FORM.MAX_LENGTH, {
            maxLength: this.nameMaxLength,
          });
        } else if (value.length < this.nameMinLength) {
          error = true;
          message = this.translate.instant(LC.VALIDATIONS.FORM.MIN_LENGTH, {
            minLength: this.nameMinLength,
          });
        }
      }

      return { error, message };
    };

    return this.validatorService.mergeInlineEditValidators(
      uniqueValidator,
      sizeRestrictionValidator,
      nameLengthValidator
    );
  }

  private removeSizeRestrictions(
    sizeRestrictions: ServiceTemplateSizeRestrictionRow[]
  ): InlineDataMultiDeleteResult[] {
    const inUse: ServiceTemplateSizeRestrictionRow[] = [];
    const toDelete: ServiceTemplateSizeRestrictionRow[] = [];
    const results: InlineDataMultiDeleteResult[] = [];
    sizeRestrictions.forEach((sizeRestriction) => {
      const partsWithSizeRestriction = this.getPartsUsedBySizeRestriction(sizeRestriction);
      if (partsWithSizeRestriction.length) {
        inUse.push(sizeRestriction);
        results.push({ model: sizeRestriction, result: false });
      } else {
        toDelete.push(sizeRestriction);
        results.push({ model: sizeRestriction, result: true });
      }
    });

    if (toDelete.length) {
      toDelete.forEach((deleteSizeRestriction) => {
        this.sizeRestrictions = this.sizeRestrictions.filter(
          (x) => x.id !== deleteSizeRestriction.id
        );
      });
      this.refreshData();
    }

    if (inUse.length) {
      this.postUnableToDeleteNotification(
        this.translate.instant(LC.NOTIFICATIONS.MSG_SIZE_RESTRICTIONS_UNABLE_DELETE_IN_USE, {
          inUse: inUse
            .map((x) => x.description)
            .sort((a, b) => a.localeCompare(b))
            .join(', '),
        })
      );
    }

    return results;
  }

  private getPartsUsedBySizeRestriction(
    sizeRestriction: ServiceTemplateSizeRestrictionRow
  ): PartReference[] {
    return this.parts.filter((x) => x.sizeRestrictionId === sizeRestriction.id);
  }

  private removeSizeRestriction(sizeRestriction: ServiceTemplateSizeRestrictionRow): boolean {
    // are there dependant part collections
    const removesizeRestriction = this.sizeRestrictions.find((x) => x.id === sizeRestriction.id);

    const partsWithSizeRestriction = this.parts.filter(
      (x) => x.sizeRestrictionId === removesizeRestriction.id
    );

    if (partsWithSizeRestriction.length) {
      this.postUnableToDeleteNotification(
        this.translate.instant(LC.NOTIFICATIONS.MSG_SIZE_RESTRICTIONS_UNABLE_DELETE, {
          description: removesizeRestriction.description,
          length: partsWithSizeRestriction.length,
          specification:
            partsWithSizeRestriction.length > 1
              ? this.translate.instant(LC.NOTIFICATIONS.MSG_SIZE_RESTRICTIONS_UNABLE_DELETE_PLURAL)
              : this.translate.instant(
                  LC.NOTIFICATIONS.MSG_SIZE_RESTRICTIONS_UNABLE_DELETE_SINGULAR
                ),
        })
      );
      return false;
    }

    return true;
  }

  public postUnableToDeleteNotification(message: string): void {
    const notification = new Notification();
    notification.type = NotificationType.Info;
    notification.place = NotificationPlace.Banner;
    const messages = [message];
    notification.messages = messages;
    this.notificationService.postNotification(notification);
  }
}

import { Injectable, Injector } from '@angular/core';
import { DynamicDataElementTypeSetup } from '@data-management/dynamic-data-setup/base/dynamic-data';
import { DynamicTableOptions, ColumnDefinition } from '@models/dynamic-table/dynamic-table-options';
import { DynamicFormOperationType } from '@models/dynamic-form/dynamic-form-types';
import { DynamicFormOptions, DynamicFormStyle } from '@models/dynamic-form/dynamic-form-properties';
import { DataElementType } from '@constants/data-element-types';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers/index';
import { Config } from '@models/fabrication/config';
import { EnvironmentConstants } from '@constants/environment-constants';
import { catchError, map, switchMap, take, tap, filter } from 'rxjs/operators';
import { ServiceTemplateInfo } from '@models/fabrication/service-template-info';
import { DynamicFormCustomComponentType } from '@constants/dynamic-form-custom-component-types';
import { DynamicGraphOptions } from '@models/dynamic-graph/dynamic-graph-options';
import { FabricationReference, FabricationReferenceType } from '@models/forge-content/references';
import {
  selectCurrentConfigServiceTemplateInfos,
  selectCurrentConfig,
  selectCurrentConfigSystemInfos,
} from '@store/selectors/configs.selectors';
import { selectServiceTemplateInfoById } from '@store/selectors/service-template-info.selectors';
import {
  LoadServiceTemplateInfos,
  LoadServiceTemplateInfosSuccess,
  AddServiceTemplateInfo,
  UpdateServiceTemplateInfo,
  CopyServiceTemplateInfo,
  DeleteServiceTemplateInfos,
  AddServiceTemplateInfoSuccess,
  UpdateServiceTemplateInfoSuccess,
  DeleteServiceTemplateInfosSuccess,
} from '@store/actions/service-template-info.action';
import { UpdateConfigServiceTemplateInfoIds } from '@store/actions/configs.action';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { DataElementInternalType } from '@constants/data-element-internal-types';
import { Observable, of, lastValueFrom } from 'rxjs';
import { selectInternalInvalidData } from '@store/selectors/invalid-data.selectors';
import { ReferenceMap } from '@services/data-services';
import { FixInvalidData } from '@store/actions/invalid-data.action';
import { InvalidDataErrorService } from '@services/invalid-data-error.service';
import { ForgeSchemaInfo } from '@models/forge-content/forge-content-schema';
import helpLinks from '@assets/help/help-links.json';
import { EnvironmentService } from '@services/environment.service';
import { LoadPartsSuccess } from '@store/actions/part.action';
import { SchemaService } from '@services/schema.service';
import { InvalidData } from '@models/fabrication/invalid-data';
import { validate } from 'uuid';
import { UploadThumbnailService } from '@services/upload-thumbnail.service';
import { ForgeContentService } from '@services/forge-content.service';
import { ImageDataElement } from '@models/fabrication/image';
import { v4 as uuidv4 } from 'uuid';
import { NotificationService } from '@services/notification.service';
import { NotificationType } from '@models/notification/notification';
import { ApiError } from '@models/errors/api-error';
import { ContentItem } from '@adsk/content-sdk';
import { cloneDeep } from 'lodash';
import { SystemInfo } from '@models/fabrication/system-info';

@Injectable()
export class DynamicServiceTemplateInfoSetup extends DynamicDataElementTypeSetup<ServiceTemplateInfo> {
  constructor(
    store$: Store<FDMState>,
    translate: TranslateService,
    invalidDataService: InvalidDataErrorService<ServiceTemplateInfo>,
    schemaService: SchemaService,
    environmentService: EnvironmentService,
    private notificationService: NotificationService,
    private uploadThumbnailService: UploadThumbnailService,
    private injector: Injector
  ) {
    super(store$, translate, invalidDataService, schemaService, environmentService);
  }

  get helpLinkId(): string {
    return helpLinks.dataTypes.partTemplates;
  }

  setupOptions() {
    const schema: ForgeSchemaInfo = {
      namespace: EnvironmentConstants.FSS_SCHEMA_NAMESPACE,
      version: EnvironmentConstants.FSS_SCHEMA_SERVICE_TEMPLATE_VERSION,
      type: EnvironmentConstants.FSS_SCHEMA_SERVICE_TEMPLATE,
    };

    this.options = {
      dataType: DataElementType.ServiceTemplate,
      dependentDataTypes: [
        DataElementType.Material, // required for part search
        DataElementType.Service,
      ],
      createNewInstance: () => {
        return new ServiceTemplateInfo();
      },
      sortFields: ['category', 'name'],
      supportsDynamicUpdates: true,
      filesAreReferenced: true,
      selectors: {
        selectAll: (includeInvalidData: boolean) =>
          this.store$.select(selectCurrentConfigServiceTemplateInfos(includeInvalidData)),
        selectById: (id: string, getInternalInvalidData?: boolean) =>
          getInternalInvalidData
            ? this.store$.select(selectInternalInvalidData(id, this.fixMissingReferences))
            : this.store$.select(selectServiceTemplateInfoById(id)),
      },
      actions: {
        loadAllAction: (config: Config) =>
          this.store$.dispatch(new LoadServiceTemplateInfos({ config })),
        loadSuccessAction: () => new LoadServiceTemplateInfosSuccess(),
        loadReferencedFilesSuccessAction: () => new LoadPartsSuccess(),
        deleteDataSuccessAction: () => new DeleteServiceTemplateInfosSuccess(),
        addDataSuccessAction: () => new AddServiceTemplateInfoSuccess(),
        updateDataSuccessAction: () => new UpdateServiceTemplateInfoSuccess(),
        updateDataReferencesAction: (
          config: Config,
          dataIds: string[],
          deleteReferences: boolean
        ) =>
          new UpdateConfigServiceTemplateInfoIds(
            {
              id: config.externalId,
              changes: dataIds,
            },
            deleteReferences
          ),
        createModelAction: (model: ServiceTemplateInfo) => {
          let config: Config;
          this.store$
            .select(selectCurrentConfig)
            .pipe(
              take(1),
              tap((result) => (config = result)),
              switchMap((config) => this.syncReferences(model, config)),
              filter((x) => x.success)
            )
            .subscribe((result) =>
              this.store$.dispatch(
                new AddServiceTemplateInfo({
                  config,
                  dataElement: model,
                  updatedReferenceContentIds: result.updatedReferenceContentIds,
                })
              )
            );
        },
        editModelAction: (model: ServiceTemplateInfo) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(
              take(1),
              switchMap((config) => this.syncReferences(model, config)),
              filter((x) => x.success)
            )
            .subscribe((result) =>
              this.store$.dispatch(
                new UpdateServiceTemplateInfo({
                  dataElement: model,
                  updatedReferenceContentIds: result.updatedReferenceContentIds,
                })
              )
            );
        },
        copyModelAction: (model: ServiceTemplateInfo) => {
          let config: Config;
          this.store$
            .select(selectCurrentConfig)
            .pipe(
              take(1),
              tap((result) => (config = result)),
              switchMap((config) => this.syncReferences(model, config)),
              filter((x) => x.success)
            )
            .subscribe((result) =>
              this.store$.dispatch(
                new CopyServiceTemplateInfo({
                  config,
                  dataElement: model,
                  updatedReferenceContentIds: result.updatedReferenceContentIds,
                })
              )
            );
        },
        deleteModelsAction: (models: ServiceTemplateInfo[]) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(new DeleteServiceTemplateInfos({ config, dataElements: models }))
            );
        },
        fixModelAction: (model: ServiceTemplateInfo) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new FixInvalidData({
                  config,
                  dataElement: model,
                  dataElementType: DataElementType.ServiceTemplate,
                  fixSchemaType: EnvironmentConstants.FSS_SCHEMA_SERVICE_TEMPLATE,
                  fixSchemaVersion: EnvironmentConstants.FSS_SCHEMA_SERVICE_TEMPLATE_VERSION,
                  nodeId: EnvironmentConstants.FCS_NODE_ID_SERVICE_TEMPLATE,
                  addSuccessAction: new AddServiceTemplateInfoSuccess(),
                })
              )
            );
        },
      },
      fcs: {
        dataTypeExternalNodeId: EnvironmentConstants.FCS_NODE_ID_SERVICE_TEMPLATE,
        schemas: [
          {
            dataType: DataElementType.ServiceTemplate,
            schema,
          },
        ],
        subSchemas: [
          {
            parentSchema: schema,
            id: `#${EnvironmentConstants.FSS_SUB_SCHEMA_PALETTE}`,
          },
          {
            parentSchema: schema,
            id: `#${EnvironmentConstants.FSS_SUB_SCHEMA_SIZE_RESTRICTION}`,
          },
          {
            parentSchema: schema,
            id: `#${EnvironmentConstants.FSS_SUB_SCHEMA_SIZE_RESTRICTION_VALUE}`,
          },
        ],
      },
    };
  }

  getDynamicTableOptions(): Observable<DynamicTableOptions<ServiceTemplateInfo>> {
    return this.store$.select(selectCurrentConfigSystemInfos(true)).pipe(
      map((systemInfos: SystemInfo[]) => {
        const serviceTemplateInfoTableColumns: ColumnDefinition[] = [
          {
            field: 'name',
            header: this.translate.instant(LC.DATATYPES.DEFINITIONS.SERVICE_TEMPLATES.NAME),
            link: { field: 'id' },
            visible: true,
          },
          {
            field: 'category',
            header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.CATEGORY),
            formatter: (value: string) =>
              value || this.translate.instant(LC.DATATYPES.DEFINITIONS.GENERIC.NOT_ASSIGNED),
            visible: true,
          },
          {
            field: 'services', // use undefined field so we can add a new column that doesn't exist on the data
            header: this.translate.instant(LC.DATATYPES.TITLES.SERVICES),
            disableSort: true,
            formatter: (value: string, template: ServiceTemplateInfo) => {
              const services: SystemInfo[] = [];
              systemInfos.forEach((system) => {
                if (
                  system.fabricationReferences.findIndex(
                    (x) => x.externalId === template.externalId
                  ) >= 0
                ) {
                  services.push(system);
                }
              });

              return services
                .map((x) => x.name)
                .sort((a, b) => a.localeCompare(b))
                .join(', ');
            },
            visible: true,
          },
        ];
        return this.createDynamicTableOptions(serviceTemplateInfoTableColumns);
      })
    );
  }

  getDynamicFormOptions(
    formOperation: DynamicFormOperationType,
    modelId: string
  ): Observable<DynamicFormOptions<ServiceTemplateInfo>> {
    const uniqueFieldRestrictions = ['name', 'category'];
    const categories = this.getItemListForTypeaheadControl('category');
    const titleField = 'name';

    return this.getFormModel(formOperation, modelId).pipe(
      map((model: ServiceTemplateInfo) => {
        return {
          model,
          formOperation,
          applyModelAction: this.getFormApplyAction(formOperation),
          isReadOnly: formOperation === 'view',
          uniqueFields: {
            fields: uniqueFieldRestrictions,
            allElements: () => this.options.selectors.selectAll(true),
          },
          hiddenFields: ['fabricationReferences'],
          formStyle: DynamicFormStyle.NONE,
          groups: [
            {
              label: this.translate.instant(LC.DYNAMIC_FORM.TABS.DETAILS),
              includeFields: ['category'],
              expanded: true,
              orderByIncludeFields: true,
              options: {
                dropdownTypeaheadFields: [
                  {
                    key: 'category',
                    options: categories,
                  },
                ],
                formStyle: DynamicFormStyle.SIMPLE,
              },
            },
            {
              label: this.translate.instant(LC.DYNAMIC_FORM.TABS.PALETTES),
              includeFields: ['palettes'],
              options: {
                formStyle: DynamicFormStyle.SIMPLE,
                customComponents: [
                  {
                    type: DynamicFormCustomComponentType.ServiceTemplateInfoPalettes,
                    field: 'palettes',
                  },
                ],
              },
            },
            {
              label: this.translate.instant(LC.DYNAMIC_FORM.TABS.PARTS),
              includeFields: ['palettes'],
              expanded: true,
              options: {
                formStyle: DynamicFormStyle.SIMPLE,
                customComponents: [
                  {
                    type: DynamicFormCustomComponentType.ServiceTemplateInfoParts,
                    field: 'palettes',
                  },
                ],
              },
            },
            {
              label: this.translate.instant(LC.DYNAMIC_FORM.TABS.SIZE_RESTRICTIONS),
              includeFields: ['sizeRestrictions'],
              options: {
                formStyle: DynamicFormStyle.SIMPLE,
                customComponents: [
                  {
                    type: DynamicFormCustomComponentType.ServiceTemplateInfoSizeRestrictions,
                    field: 'sizeRestrictions',
                  },
                ],
              },
            },
          ],
          titleField,
        };
      })
    );
  }

  getDynamicGraphOptions(): DynamicGraphOptions {
    return {
      nodeInfoFields: ['name', 'category'],
      isReplaceable: true,
      isRemovable: false,
      isEditable: true,
      upstreamReferenceDataTypes: () => [DataElementType.Service],
      clusterIcon: 'service16',
      createInternalDownstreamGraphNodes: (
        dataElement: ServiceTemplateInfo,
        references: ReferenceMap[],
        isInvalidData: boolean
      ) => {
        const graphNodes = dataElement.palettes.map((palette) => {
          let numParts = 0;
          palette.partCollections.forEach((x) => (numParts += x.parts.length));
          return {
            id: palette.id,
            internalRelationshipId: palette.id,
            internalRelationshipDataType: DataElementInternalType.Palette,
            dbid: dataElement.id,
            dataType: DataElementType.ServiceTemplate,
            isFocusable: false,
            isExpandable: true,
            isReplaceable: false,
            isRemovable: false,
            isEditable: false,
            info: { name: palette.name, parts: numParts },
            referenceMetaData: null,
            isInvalidData,
            isInvalidInternalData: isInvalidData,
          };
        });

        return of(graphNodes);
      },
      getInternalDownstreamReferenceFilteredElements: (
        dataElement: ServiceTemplateInfo,
        internalRelationshipId: string
      ) => {
        const palette = (dataElement as ServiceTemplateInfo).palettes.find(
          (palette) => palette.id === internalRelationshipId
        );
        const partCollections = palette.partCollections;
        const dataElementFabricationReferencesFiltered = dataElement.fabricationReferences.filter(
          (ref) => {
            return partCollections.some(
              (x) => x.parts.findIndex((y) => y.contentExternalId === ref.externalId) >= 0
            );
          }
        );

        return dataElementFabricationReferencesFiltered;
      },
    };
  }

  protected getModelCopy = (modelId: string): Observable<ServiceTemplateInfo> => {
    // Generate new IDs for internals when Service Template gets copied.
    // Otherwise, the source and copy palettes, sizeRestrictions, etc., will have same ids but at
    // the same time they will be completely independent objects (may be edited independently).
    // This causes issues in RM when it becomes impossible to get source Service Template
    // from internal id, since it may exist in both source and copy Service Templates.
    // Potentially this may cause issues in other parts of the application as well.
    return super.getModelCopy(modelId).pipe(
      switchMap((model) => {
        const newModel = cloneDeep(model);
        const sizeRestrictionIdMap = new Map<string, string>();
        const { palettes, sizeRestrictions } = newModel;
        const partCollections = palettes.map((palette) => palette.partCollections).flat();
        const parts = partCollections.map((palette) => palette.parts).flat();

        palettes.forEach((palette) => (palette.id = uuidv4()));
        partCollections.forEach((partCollection) => (partCollection.id = uuidv4()));
        sizeRestrictions.forEach((sizeRestriction) => {
          sizeRestrictionIdMap.set(sizeRestriction.id, uuidv4());
          sizeRestriction.id = sizeRestrictionIdMap.get(sizeRestriction.id);
        });
        parts.forEach((part) => {
          part.sizeRestrictionId = sizeRestrictionIdMap.has(part.sizeRestrictionId)
            ? sizeRestrictionIdMap.get(part.sizeRestrictionId)
            : part.sizeRestrictionId;
        });

        return of(newModel);
      })
    );
  };

  private async syncReferences(
    serviceTemplate: ServiceTemplateInfo,
    config: Config
  ): Promise<{ success: boolean; updatedReferenceContentIds: string[] }> {
    const refs: string[] = [];
    const imageRefs: string[] = [];
    const uploadResult = await this.finalizeUpdatedThumbnails(serviceTemplate, config);
    if (!uploadResult.success) {
      const message = this.translate.instant(LC.NOTIFICATIONS.COMMON.SOMETHING_WENT_WRONG);
      this.notificationService.showToast({ message, type: NotificationType.Error });
      return { success: false, updatedReferenceContentIds: [] };
    }
    serviceTemplate.palettes.forEach((palette) => {
      palette.partCollections.forEach((partCollection) => {
        if (
          partCollection.imageExternalIdOverride?.length &&
          validate(partCollection.imageExternalIdOverride)
        )
          imageRefs.push(partCollection.imageExternalIdOverride);

        delete partCollection.updatedThumbnailStorageFile;

        partCollection.parts.forEach((part) => {
          if (part.contentExternalId && part.contentExternalId !== '-1') {
            refs.push(part.contentExternalId);
          }
        });
      });
    });

    const partReferences = [...new Set(refs)].map((ref) => {
      const reference: FabricationReference = {
        externalId: ref,
        dataType: DataElementType.Part,
        referenceType: FabricationReferenceType.Relationship,
      };
      return reference;
    });

    const imageReferences = [...new Set(imageRefs)].map((ref) => {
      const reference: FabricationReference = {
        externalId: ref,
        dataType: DataElementType.Image,
        referenceType: FabricationReferenceType.Relationship,
      };
      return reference;
    });

    serviceTemplate.fabricationReferences = [...partReferences, ...imageReferences];
    return { success: true, updatedReferenceContentIds: uploadResult.updatedReferenceContentIds };
  }

  private async finalizeUpdatedThumbnails(
    serviceTemplate: ServiceTemplateInfo,
    config: Config
  ): Promise<{ success: boolean; updatedReferenceContentIds: string[] }> {
    const forgeContentService = this.injector.get(ForgeContentService);
    const promises: Promise<string>[] = [];
    const updatedObjectKeys: string[] = [];
    serviceTemplate.palettes.forEach((palette) => {
      palette.partCollections.forEach((partCollection) => {
        if (partCollection.updatedThumbnailStorageFile) {
          //Thumbnail has changed
          updatedObjectKeys.push(partCollection.updatedThumbnailStorageFile.id);
        }
      });
    });
    if (!updatedObjectKeys.length) return { success: true, updatedReferenceContentIds: [] };
    if (!(await this.uploadThumbnailService.finalizePending(config, updatedObjectKeys))) {
      return { success: false, updatedReferenceContentIds: [] };
    }
    serviceTemplate.palettes.forEach((palette) => {
      palette.partCollections.forEach((partCollection) => {
        if (partCollection.updatedThumbnailStorageFile) {
          if (partCollection.imageExternalIdOverride) {
            //Image content item already exists, update thumbnails
            promises.push(
              lastValueFrom(
                forgeContentService
                  .getContentItem(config, partCollection.imageExternalIdOverride, false)
                  .pipe(
                    switchMap((contentItem) => {
                      const imageName = `${contentItem.externalId}.png`;
                      const thumbnailFile = this.uploadThumbnailService.getThumbnailFile(
                        partCollection.updatedThumbnailStorageFile,
                        imageName,
                        contentItem.externalId
                      );
                      return forgeContentService.updateContent(
                        config,
                        contentItem,
                        DataElementType.Image,
                        [],
                        [thumbnailFile],
                        false
                      );
                    }),
                    map((data) => {
                      if ((data as ApiError).errors) {
                        return null;
                      }
                      return (data[1] as ContentItem).id;
                    }),
                    catchError(() => of(null))
                  )
              )
            );
          } else {
            //Create a new content item for image
            const imageContentItem = new ImageDataElement();
            imageContentItem.externalId = uuidv4();

            const imageName = `${imageContentItem.externalId}.png`;
            const thumbnailFile = this.uploadThumbnailService.getThumbnailFile(
              partCollection.updatedThumbnailStorageFile,
              imageName,
              imageContentItem.externalId
            );

            imageContentItem.imagePath = `${config.imagesFolderPath || 'images'}\\${imageName}`;
            imageContentItem.name = imageContentItem.imagePath;

            promises.push(
              lastValueFrom(
                forgeContentService
                  .addContent(
                    imageContentItem,
                    config,
                    DataElementType.Image,
                    EnvironmentConstants.FCS_NODE_ID_IMAGE_FILES,
                    null,
                    [],
                    [thumbnailFile],
                    false,
                    imageContentItem.externalId
                  )
                  .pipe(
                    map((data) => {
                      if ((data as ApiError).errors) {
                        return null;
                      }
                      partCollection.imageExternalIdOverride = imageContentItem.externalId;
                      return (data[1] as ContentItem).id;
                    }),
                    catchError(() => of(null))
                  )
              )
            );
          }
        }
      });
    });
    const promisesResult = await Promise.all(promises);
    if (promisesResult.every((x) => x)) {
      return { success: true, updatedReferenceContentIds: promisesResult };
    }
    return { success: false, updatedReferenceContentIds: [] };
  }

  dataFixes(): void {
    //
  }

  fixMissingReferences(fabricationReferences: FabricationReference[]): FabricationReference[] {
    return fabricationReferences || [];
  }

  getIconName(): string {
    return 'service';
  }

  isFixable(): boolean {
    return false;
  }

  getInvalidDataErrors(model: ServiceTemplateInfo & InvalidData): string {
    const schema = this.schemaService.getSchemaByDataElementType(DataElementType.ServiceTemplate);
    const errors = this.invalidDataErrorService.parseErrors(model, schema);
    if (errors.length > 1) {
      return this.translate.instant(LC.ERROR_HANDLING.GENERIC.MULTIPLE_ERRORS);
    }

    const error = errors[0];
    if (error.attribute === 'sizeRestrictionId') {
      return this.translate.instant(LC.ERROR_HANDLING.SERVICE_TEMPLATES.INVALID_SIZE_RESTRICTION);
    }

    return this.getStandardInvalidDataError(DataElementType.ServiceTemplate, model, schema);
  }

  requiresBinaryUpgrade(): boolean {
    return false;
  }
}

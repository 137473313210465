import { ForgeContentDataElement } from '@models/forge-content/forge-content-data-element';
import { SchemaErrorType } from './schema-error-type';

export interface ParseInvalidDataErrorResult {
  reason: InvalidDataSchemaErrorReason;
  attribute: string;
  fullPath?: string;
  schemaErrorType: SchemaErrorType;
}

export enum InvalidDataSchemaErrorReason {
  Unknown = 'Unknown',
  NumberLessThanMinimum = 'Number Less Than Mimimum',
  NumberMoreThanMaximum = 'Number More Than Maximum',
  ArrayTooShort = 'Array is too short',
  InvalidPart = 'Invalid Part',
  InvalidReference = 'Invalid Reference',
  Required = 'Required',
  InvalidSizeRestriction = 'Invalid Size Restriction',
}

export class InvalidData extends ForgeContentDataElement {
  reason?: string;
  path?: string; // only exists for parts
  extraData?: { [key: string]: string };
}

export enum InvalidDataErrorReason {
  PayloadTooBig = 'PayloadTooBig',
  Schema = 'Schema',
  Corrupt = 'Corrupt',
  InvalidDims = 'InvalidDims',
  CalculationInvalidReference = 'CalculationInvalidReference',
  CalculationCircularReference = 'CalculationCircularReference',
  DependencyCircularReference = 'DependencyCircularReference',
  DifferentOptionPresetValues = 'DifferentOptionPresetValues',
  NameTooLong = 'NameTooLong',
}

import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { elementIdDictionary } from '@constants/element-id-dictionary';
import {
  DynamicTableOptions,
  ColumnDefinition,
  InlineDataMultiDeleteResult,
} from '@models/dynamic-table/dynamic-table-options';
import { BehaviorSubject, Subscription, Subject, Observable, of, combineLatest } from 'rxjs';
import { DynamicFormBaseCustomComponent } from '@shared/components/dynamic-form/dynamic-form-base-custom-component';
import { catchError, filter, map, tap } from 'rxjs/operators';
import {
  DynamicFormCustomUpdateEvent,
  DynamicFormOnApplyResult,
  DynamicFormOptions,
  DynamicFormSelectType,
  DynamicFormStyle,
} from '@models/dynamic-form/dynamic-form-properties';
import { InlineEditError } from '@models/inline-edit/inline-edit.options';
import {
  SpecificationApplicationType,
  SpecificationInfo,
  SpecificationTable,
  specificationTableFactory,
  SpecificationTableType,
} from '@models/fabrication/specification-info';
import { Material, MaterialType } from '@models/fabrication/material';
import { FDMState } from '@store/reducers';
import { Store } from '@ngrx/store';
import { selectCurrentConfigMaterials } from '@store/selectors/configs.selectors';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { getI18nConstantRef } from '@utils/translate-utils';
import { DomainType, ShapeType } from '@models/fabrication/common';
import { DynamicModalSetupData } from '@models/dynamic-content/dynamic-content';
import { DynamicModalComponent } from '@shared/components/dynamic-modal/dynamic-modal.component';
import { DynamicFormOperationType } from '@models/dynamic-form/dynamic-form-types';
import { DataElementType } from '@constants/data-element-types';
import { FormUtils } from '@utils/formly/formly-utils';
import { DynamicFormCustomComponentType } from '@constants/dynamic-form-custom-component-types';
import {
  FabricationReference,
  FabricationReferenceType,
  ReferencedData,
} from '@models/forge-content/references';
import { uniq } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { SchemaService } from '@services/schema.service';
import { JSONSchema7 } from 'json-schema';
import { EnvironmentConstants } from '@constants/environment-constants';
import { DataElementEffectService } from '@services/data-element-effect.service';
import { ForgeContentService } from '@services/forge-content.service';
import { Part } from '@models/fabrication/part';
import { RxjsUtils } from '@utils/rxjs/rxjs-utils';
import { NotificationService } from '@services/notification.service';
import { NotificationType } from '@models/notification/notification';

interface DeleteTableInfo {
  table: SpecificationTable;
  parts: Part[];
}

@Component({
  selector: 'fab-part-spec-tables',
  template: `
    <fab-dynamic-modal #modal> </fab-dynamic-modal>
    <fab-table-data *ngIf="tableOptions" [options]="tableOptions"></fab-table-data>
  `,
})
export class PartSpecificationTablesComponent
  extends DynamicFormBaseCustomComponent<SpecificationTable[]>
  implements OnInit, OnDestroy
{
  @ViewChild(DynamicModalComponent) modal: DynamicModalComponent;
  tableOptions: DynamicTableOptions<SpecificationTable> = null;
  tableDataSource: BehaviorSubject<SpecificationTable[]>;
  tableChangeSubject: Subject<SpecificationTable[]> = new Subject<SpecificationTable[]>();
  tableChangeSubscription: Subscription;
  tableErrorReporterSubject: Subject<InlineEditError> = new Subject<InlineEditError>();
  tableErrorReporterSubscription: Subscription = null;
  idLookup = elementIdDictionary.connectorEdit;
  rows: SpecificationTable[];
  customModelChangesSubscription: Subscription;
  currentRow: SpecificationTable = null;
  materials: Material[] = null;
  modalOptions: DynamicModalSetupData = null;
  selectedTableIndex = 0;

  constructor(
    store$: Store<FDMState>,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private schemaService: SchemaService,
    private dataElementEffectService: DataElementEffectService,
    private forgeContentService: ForgeContentService,
    private notificationService: NotificationService
  ) {
    super(store$);
  }

  ngOnInit() {
    this.getModelUpdates();
    this.getTableChanges();
  }

  ngOnDestroy() {
    this.tableChangeSubscription?.unsubscribe();
    this.tableErrorReporterSubscription?.unsubscribe();
    this.customModelChangesSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  getModelUpdates() {
    const getModelChanges$: Observable<SpecificationInfo> = this.formModelUpdater.pipe(
      filter(
        (changeEvent: DynamicFormCustomUpdateEvent) =>
          changeEvent.isFirstUpdate || changeEvent.subscriptionToken !== this.subscriptionToken
      ),
      map((changeEvent: DynamicFormCustomUpdateEvent) => changeEvent.data as SpecificationInfo)
    );

    this.customModelChangesSubscription = combineLatest([
      getModelChanges$,
      this.store$
        .select(selectCurrentConfigMaterials(true, false))
        .pipe(
          map((materials: Material[]) =>
            materials.filter(
              (x) =>
                x.materialType === MaterialType.Main || x.materialType === MaterialType.Ductboard
            )
          )
        ),
    ]).subscribe((setupData: [SpecificationInfo, Material[]]) => {
      const [model, materials] = setupData;
      this.rows = model.tables;
      this.materials = materials;
      this.createTableDataOptions(model);
      this.tableDataSource.next(this.rows);
    });
  }

  getTableChanges() {
    this.tableChangeSubscription = this.tableChangeSubject.subscribe(
      (rows: SpecificationTable[]) => {
        this.rows = rows;
        this.applyFabricationReferences();
      }
    );
  }

  getMaterialSelectFields = (): DynamicFormSelectType[] => {
    const settings: DynamicFormSelectType[] = [
      {
        value: EnvironmentConstants.FCS_ANY_MATERIAL,
        label: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.ANY),
      },
    ];

    return settings.concat(
      this.materials.map((x) => ({
        label: x.name,
        group: x.category,
        value: x.externalId,
      }))
    );
  };

  createTableDataOptions(model: SpecificationInfo) {
    if (!this.tableOptions) {
      this.tableDataSource =
        this.tableDataSource || new BehaviorSubject<SpecificationTable[]>(this.rows);

      this.tableOptions = {
        id: uuidv4(),
        data: this.tableDataSource,
        controlIdPrefix: 'part-spec-tables',
        rowSelection: { visible: true },
        isReadOnly: this.isReadOnly,
        subscribeToTableChanges: this.tableChangeSubject,
        maintainSelectionOnUpdate: true,
        columns: this.getColumnDefinitions(model),
        tableTypeOptions: {
          disableAddCondition: 'customFunction',
          customAddAction: this.displayTableModalForCreate,
          customEditAction: this.displayTableModalForEdit,
          customDisableAddButton: () => model.tableType !== SpecificationTableType.SingleDimension,
          customDisableEditButton: (tables: SpecificationTable[], index: number) =>
            model.tableType !== SpecificationTableType.SingleDimension ||
            tables[index]?.domain !== DomainType.Pipework,
          customDeleteRow: {
            deleteRow: (table: SpecificationTable): Observable<boolean> => {
              // ensure we don't delete a table that is being used by any parts in the config
              return this.checkDeleteRow(table).pipe(
                tap((deleteInfo: DeleteTableInfo) => {
                  if (!deleteInfo?.parts?.length) return;

                  const referencedData: ReferencedData[] = deleteInfo.parts.map((x) => ({
                    externalId: x.externalId,
                    dataType: DataElementType.Part,
                    name: x.name,
                  }));

                  this.dataElementEffectService.postUnableToDeleteNotification(
                    DataElementType.PartSpecificationTable,
                    referencedData,
                    1
                  );
                }),
                map((deleteInfo: DeleteTableInfo) => !deleteInfo?.parts?.length),
                catchError(() => {
                  this.displayBannerError();
                  return of(false);
                })
              );
            },
            modalOptions: () => {
              return { open: false };
            },
          },
          customDeleteMultipleRows: {
            deleteRows: (
              tables: SpecificationTable[]
            ): Observable<InlineDataMultiDeleteResult[]> => {
              // ensure we only delete tables that are not being used by any parts in the config
              const checkRows = tables.map((table) => {
                return this.checkDeleteRow(table);
              });

              return RxjsUtils.concurrentForkJoin(checkRows).pipe(
                tap((deleteInfo: DeleteTableInfo[]) => {
                  const parts: { [externalId: string]: string } = {};
                  deleteInfo?.forEach((x) => {
                    x.parts.forEach((part) => {
                      parts[part.externalId] = part.name;
                    });
                  });

                  if (!Object.keys(parts).length) return;

                  const referencedData: ReferencedData[] = Object.keys(parts).map((key) => ({
                    externalId: key,
                    dataType: DataElementType.Part,
                    name: parts[key],
                  }));
                  this.dataElementEffectService.postUnableToDeleteNotification(
                    DataElementType.PartSpecificationTable,
                    referencedData,
                    deleteInfo.filter((x) => !x.parts.length).length
                  );
                }),
                map((deleteInfo: DeleteTableInfo[]) => {
                  if (!deleteInfo?.length) return [];

                  return deleteInfo.map((x) => ({
                    model: x.table,
                    result: !x.parts.length,
                  }));
                }),
                catchError(() => {
                  this.displayBannerError();

                  const results: InlineDataMultiDeleteResult[] = tables.map((table) => ({
                    model: table,
                    result: false,
                  }));

                  return of(results);
                })
              );
            },
            modalOptions: () => {
              return { open: false };
            },
          },
        },
        toolbarOptions: {},
        footerText: this.translate.instant(LC.DYNAMIC_TABLE.PIPEWORK_TABLES_ONLY),
        enableMultiSelect: true,
      };
    }
  }

  displayBannerError = () => {
    this.notificationService.showBanner(
      this.translate.instant(LC.ERROR_HANDLING.GENERIC.UNEXPECTED_ERROR),
      NotificationType.Error
    );
  };

  checkDeleteRow = (table: SpecificationTable): Observable<DeleteTableInfo> => {
    const schemaType = `${EnvironmentConstants.FSS_SCHEMA_NAMESPACE}:${EnvironmentConstants.FSS_SCHEMA_PART}`;

    const isAny =
      table.material === EnvironmentConstants.FCS_ANY_MATERIAL || table.material === '-1';

    const specId = (this.model as SpecificationInfo).externalId;
    let query = `fabricationData.fabricationReferences.externalId:"${specId}"`;
    if (!isAny) {
      query = `${query} && fabricationData.fabricationReferences.externalId:"${table.material}"`;
    }

    const typeFilter = `type.name:"${schemaType}"`;

    return this.forgeContentService
      .search(query, typeFilter)
      .pipe(map((parts: Part[]) => ({ table, parts })));
  };

  displayTableModalForCreate = () => {
    // only create currently
    const spec = this.model as SpecificationInfo;
    const defaultTable = specificationTableFactory(spec, ShapeType.Round, DomainType.Pipework);
    this.modalOptions = this.createModalFormOptions(defaultTable, 'create');
    this.modal.openModal(this.modalOptions);
  };

  displayTableModalForEdit = (model: SpecificationTable, index: number) => {
    this.selectedTableIndex = index;
    this.modalOptions = this.createModalFormOptions(model, 'edit');
    this.modal.openModal(this.modalOptions);
  };

  private getColumnDefinitions(model: SpecificationInfo): ColumnDefinition[] {
    const hasLengthField =
      model.tableType === SpecificationTableType.LongSideShortSidePlusLength ||
      model.tableType === SpecificationTableType.SingleDimensionPlusLength;

    const columns: ColumnDefinition[] = [
      {
        field: 'material',
        header: this.translate.instant(LC.DATATYPES.TYPES.MATERIAL),
        formatter: (materialId: string) =>
          materialId === EnvironmentConstants.FCS_ANY_MATERIAL || materialId === '-1'
            ? this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.ANY)
            : this.materials.find((x) => x.externalId === materialId)?.name,
      },
      {
        field: 'domain',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.DOMAIN),
        formatter: (domain: DomainType) =>
          this.translate.instant(LC.ENUMS.DOMAIN_TYPE[getI18nConstantRef(domain)]),
      },
      {
        field: 'appliesTo',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.SPECIFICATIONS.APPLIES_TO),
        formatter: (applicationType: SpecificationApplicationType) =>
          this.translate.instant(
            LC.ENUMS.SPECIFICATION_APPLICATION_TYPE[getI18nConstantRef(applicationType)]
          ),
      },
      {
        field: 'shape',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.SHAPE),
        formatter: (shape: ShapeType) =>
          this.translate.instant(LC.ENUMS.SHAPE_TYPE[getI18nConstantRef(shape)]),
      },
    ];

    if (hasLengthField) {
      columns.push({
        field: 'tableLength',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.LENGTH),
      });
    }
    return columns;
  }

  applyFabricationReferences = () => {
    const connectorIds = [];
    const materialIds = [];
    const materialSpecIds = [];
    this.rows.forEach((table: SpecificationTable) => {
      connectorIds.push(
        ...table.entries.map((x) => x.primaryConnector),
        ...table.entries.map((x) => x.secondaryConnector)
      );
      materialIds.push(table.material);

      // collect ay material specs too, which will be on the non-pipework tables
      table.entries.forEach((x: any) => {
        if (x.materialSpecification) {
          materialSpecIds.push(x.materialSpecification);
        }
      });
    });

    let connectorRefs: FabricationReference[] = [];
    let materialRefs: FabricationReference[] = [];
    let materialSpecRefs: FabricationReference[] = [];
    if (connectorIds?.length) {
      // ensure unique
      // filter out references to none/not set connectors
      connectorRefs = uniq(
        connectorIds.filter(
          (x) =>
            x !== EnvironmentConstants.FCS_UNASSIGNED_CONNECTOR &&
            x !== EnvironmentConstants.FCS_NONE_CONNECTOR &&
            x !== '-1' &&
            x !== '-2'
        )
      ).map((x) => ({
        dataType: DataElementType.Connector,
        externalId: x,
        referenceType: FabricationReferenceType.Relationship,
      }));
    }

    if (materialIds?.length) {
      // ensure unique
      // filter out references to "any" material
      materialRefs = uniq(
        materialIds.filter(
          (x) =>
            x !== EnvironmentConstants.FCS_ANY_MATERIAL &&
            x !== EnvironmentConstants.FCS_UNASSIGNED_MATERIAL &&
            x !== '-1'
        )
      ).map((x) => ({
        dataType: DataElementType.Material,
        externalId: x,
        referenceType: FabricationReferenceType.Relationship,
      }));
    }

    if (materialSpecIds?.length) {
      // ensure unique
      materialSpecRefs = uniq(
        materialSpecIds.filter((x) => x !== EnvironmentConstants.FCS_UNASSIGNED_MATERIAL_SPEC)
      ).map((x) => ({
        dataType: DataElementType.MaterialSpecification,
        externalId: x,
        referenceType: FabricationReferenceType.Relationship,
      }));
    }

    const spec = this.model as SpecificationInfo;
    spec.fabricationReferences = [...connectorRefs, ...materialRefs, ...materialSpecRefs];
    this.updateSource(this.rows);
  };

  createModalFormOptions = (
    table: SpecificationTable,
    formOperation: DynamicFormOperationType
  ): DynamicModalSetupData => {
    const uniqueFieldRestrictions = ['domain', 'appliesTo', 'material', 'shape'];
    const tablesToCompareUniqueSetup =
      formOperation === 'create' ? this.rows : this.rows.filter((x) => x !== table);

    const formOptions: DynamicFormOptions<any> = {
      model: table,
      formOperation,
      applyModelAction: null,
      disableCreateId: true,
      isReadOnly: formOperation === 'view',
      uniqueFields: {
        fields: uniqueFieldRestrictions,
        allElements: () => of(tablesToCompareUniqueSetup),
        validateImediately: true,
      },
      getCustomSchema: () => {
        const parentSchema = this.schemaService.getSchemaByDataElementType(
          DataElementType.Specification
        );
        const schema = parentSchema.definitions['partSpecificationTable'] as JSONSchema7;
        return schema;
      },
      tabs: [
        {
          label: this.translate.instant(LC.DYNAMIC_FORM.TABS.DETAILS),
          includeFields: ['domain', 'shape', 'appliesTo', 'material'],
          options: {
            disabledFields: ['domain', 'shape'],
            selectFields: [
              {
                key: 'domain',
                options: FormUtils.mapSelectOptionsFromEnum(
                  this.translate,
                  DomainType,
                  'DomainType'
                ).filter((x) => x.value !== DomainType.NotSet),
              },
              {
                key: 'shape',
                options: FormUtils.mapSelectOptionsFromEnum(this.translate, ShapeType, 'ShapeType'),
              },
              {
                key: 'appliesTo',
                options: FormUtils.mapSelectOptionsFromEnum(
                  this.translate,
                  SpecificationApplicationType,
                  'SpecificationApplicationType'
                ),
              },
              {
                key: 'material',
                options: this.getMaterialSelectFields(),
              },
            ],
            formStyle: DynamicFormStyle.SIMPLE,
          },
        },
        {
          label: this.translate.instant(LC.DYNAMIC_FORM.TABS.SIZE_ENTRIES),
          includeFields: ['entries'],
          options: {
            customComponents: [
              {
                type: DynamicFormCustomComponentType.PartSpecTable,
                field: 'entries',
              },
            ],
            formStyle: DynamicFormStyle.SIMPLE,
          },
        },
      ],
      formStyle: DynamicFormStyle.NONE,
    };

    return {
      title: this.translate.instant(LC.FEATURE_MODULES.SPECIFICATIONS.MODAL_TITLE),
      showModalButtons: false,
      modalWidth: 1000,
      contentSetup: {
        contentType: 'form',
        formSetup: {
          fromModal: true,
          options: formOptions,
          dataType: DataElementType.Specification,
          onFormApply: (onApply: DynamicFormOnApplyResult) => {
            if (onApply.operation === 'create') {
              this.rows.push(onApply.result);
            } else {
              this.rows[this.selectedTableIndex] = onApply.result;
            }

            this.tableDataSource.next(this.rows);
            this.cdr.detectChanges();
            // update fabrication refs
            this.applyFabricationReferences();
          },
          onFormCancel: () => {
            console.log('Modal from form cancelled');
          },
        },
      },
    };
  };
}

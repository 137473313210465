<div class="custom-multi-select__container">
  <mat-form-field class="custom-multi-select__item">
    <mat-label>{{ props.label }}</mat-label>
    <mat-select
      [(ngModel)]="selectedItems"
      (openedChange)="openedChange($event)"
      [disabled]="isReadOnly"
      multiple
      disableOptionCentering
      [aria-label]="props.label"
    >
      <mat-select-trigger aria-label="select-trigger">
        <div>
          {{ selectedText }}
        </div>
      </mat-select-trigger>
      <mat-option>
        <ngx-mat-select-search
          aria-label="select-search"
          [formControl]="groupedItemsFilterCtrl"
          placeholderLabel="{{ searchText }}"
          noEntriesFoundLabel="{{ noMatchesText }}"
        ></ngx-mat-select-search>
      </mat-option>
      <mat-optgroup *ngFor="let group of groupedItems" [label]="group.group">
        <mat-option *ngFor="let item of group.items" [value]="item" [disabled]="item.disabled">
          <fab-tooltip [content]="getTooltip(item)" position="right left bottom top">
            {{ item.label }}
          </fab-tooltip>
        </mat-option>
      </mat-optgroup>
    </mat-select>
  </mat-form-field>
</div>

import { Injectable } from '@angular/core';
import { DynamicDataElementTypeSetup } from '@data-management/dynamic-data-setup/base/dynamic-data';
import {
  DynamicFormFieldLabelMapping,
  DynamicFormOptions,
  DynamicFormSelectField,
  DynamicFormSelectType,
  DynamicFormStyle,
} from '@models/dynamic-form/dynamic-form-properties';
import {
  ConnectorInfo,
  RoundConnectorInfo,
  ConnectorDomainType,
  ConnectorGenderType,
  ConnectorShapeType,
  RoundGeometryType,
  PipeworkEndType,
  RectangularGeometryType,
  OvalGeometryType,
  RectangularStepDownOption,
  RectangularConnectorInfo,
  OvalConnectorInfo,
  RoundConnectorMeasurementMode,
} from '@models/fabrication/connector-info';
import {
  DynamicTableOptions,
  ColumnDefinition,
  CreateOptions,
} from '@models/dynamic-table/dynamic-table-options';
import { FormUtils } from '@utils/formly/formly-utils';
import { DynamicFormOperationType } from '@models/dynamic-form/dynamic-form-types';
import { DataElementType } from '@constants/data-element-types';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers/index';
import {
  selectCurrentConfigConnectorInfos,
  selectCurrentConfig,
} from '@store/selectors/configs.selectors';
import { selectConnectorInfoById } from '@store/selectors/connector-info.selectors';
import {
  LoadConnectorInfos,
  LoadConnectorInfosSuccess,
  AddConnectorInfo,
  UpdateConnectorInfo,
  CopyConnectorInfo,
  DeleteConnectorInfos,
  DeleteConnectorInfosSuccess,
  AddConnectorInfoSuccess,
  UpdateConnectorInfoSuccess,
} from '@store/actions/connector-info.action';
import { UpdateConfigConnectorInfoIds } from '@store/actions/configs.action';

import { Config, ConfigUnitSystem } from '@models/fabrication/config';
import { map, take } from 'rxjs/operators';
import { EnvironmentConstants } from '@constants/environment-constants';
import { DynamicFormCustomComponentType } from '@constants/dynamic-form-custom-component-types';
import { DynamicGraphOptions } from '@models/dynamic-graph/dynamic-graph-options';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { UnitSchemaType } from '@models/forge-units/forge-unit-schema-types';
import { ReferenceOutput } from '@services/data-services/dynamic-graph-data.service';
import { DataElementInternalType } from '@constants/data-element-internal-types';
import { SpecificationInfo } from '@models/fabrication/specification-info';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { flatten } from 'lodash';
import { selectInternalInvalidData } from '@store/selectors/invalid-data.selectors';
import { FixInvalidData } from '@store/actions/invalid-data.action';
import { FabricationReference, FabricationReferenceType } from '@models/forge-content/references';
import { InvalidDataErrorService } from '@services/invalid-data-error.service';
import { ForgeUnitsService } from '@services/data-services/forge-units.service';
import { ForgeSchemaInfo } from '@models/forge-content/forge-content-schema';
import helpLinks from '@assets/help/help-links.json';
import { EnvironmentService } from '@services/environment.service';
import { SchemaService } from '@services/schema.service';
import { InvalidData } from '@models/fabrication/invalid-data';
import { JSONSchema7 } from 'json-schema';
import { CreateDataTypeService } from '@services/create-data-type.service';

const tooltipTranslationMap = {
  shape: LC.DATATYPES.DEFINITIONS.CONNECTORS.SHAPE,
  gender: LC.DATATYPES.DEFINITIONS.CONNECTORS.GENDER,
  domain: LC.DATATYPES.DEFINITIONS.CONNECTORS.DOMAIN,
  connectivityClassification: LC.DATATYPES.DEFINITIONS.CONNECTORS.CONNECTIVITY_CLASSIFICATION,
};

@Injectable()
export class DynamicConnectorInfoSetup extends DynamicDataElementTypeSetup<ConnectorInfo> {
  private modelChangeSubject = new BehaviorSubject<ConnectorInfo>(null);
  private defaultCreateType = ConnectorShapeType.Round;

  constructor(
    store$: Store<FDMState>,
    translate: TranslateService,
    invalidDataService: InvalidDataErrorService<ConnectorInfo>,
    schemaService: SchemaService,
    environmentService: EnvironmentService,
    private createDataTypeService: CreateDataTypeService
  ) {
    super(store$, translate, invalidDataService, schemaService, environmentService);
  }

  get helpLinkId(): string {
    return helpLinks.dataTypes.connectors;
  }

  setupOptions() {
    const roundConnectorSchema: ForgeSchemaInfo = {
      namespace: EnvironmentConstants.FSS_SCHEMA_NAMESPACE,
      type: EnvironmentConstants.FSS_SCHEMA_ROUND_CONNECTOR,
      version: EnvironmentConstants.FSS_SCHEMA_ROUND_CONNECTOR_VERSION,
    };

    const rectangularConnectorSchema: ForgeSchemaInfo = {
      namespace: EnvironmentConstants.FSS_SCHEMA_NAMESPACE,
      type: EnvironmentConstants.FSS_SCHEMA_RECTANGULAR_CONNECTOR,
      version: EnvironmentConstants.FSS_SCHEMA_RECTANGULAR_CONNECTOR_VERSION,
    };

    const ovalConnectorSchema: ForgeSchemaInfo = {
      namespace: EnvironmentConstants.FSS_SCHEMA_NAMESPACE,
      type: EnvironmentConstants.FSS_SCHEMA_OVAL_CONNECTOR,
      version: EnvironmentConstants.FSS_SCHEMA_OVAL_CONNECTOR_VERSION,
    };

    this.options = {
      dataType: DataElementType.Connector,
      dependentDataTypes: [],
      createNewInstance: () => {
        let connector: ConnectorInfo = null;
        const storageCreateType = this.createDataTypeService.getCreateDataType<ConnectorShapeType>(
          DataElementType.Connector,
          this.defaultCreateType
        );
        switch (storageCreateType) {
          default:
          case ConnectorShapeType.Round:
            connector = new RoundConnectorInfo();
            break;
          case ConnectorShapeType.Rectangular:
            connector = new RectangularConnectorInfo();
            break;
          case ConnectorShapeType.Oval:
            connector = new OvalConnectorInfo();
            break;
        }

        return connector;
      },
      sortFields: ['category', 'name'],
      supportsDynamicUpdates: true,
      selectors: {
        selectAll: (includeInvalidData: boolean) =>
          this.store$.select(selectCurrentConfigConnectorInfos(includeInvalidData)),
        selectById: (id: string, getInternalInvalidData?: boolean) =>
          getInternalInvalidData
            ? this.store$.select(selectInternalInvalidData(id, this.fixMissingReferences))
            : this.store$.select(selectConnectorInfoById(id, this.fixMissingReferences)),
      },
      actions: {
        loadAllAction: (config: Config) => this.store$.dispatch(new LoadConnectorInfos({ config })),
        loadSuccessAction: () => new LoadConnectorInfosSuccess(),
        deleteDataSuccessAction: () => new DeleteConnectorInfosSuccess(),
        addDataSuccessAction: () => new AddConnectorInfoSuccess(),
        updateDataSuccessAction: () => new UpdateConnectorInfoSuccess(),
        updateDataReferencesAction: (
          config: Config,
          dataIds: string[],
          deleteReferences: boolean
        ) =>
          new UpdateConfigConnectorInfoIds(
            {
              id: config.externalId,
              changes: dataIds,
            },
            deleteReferences
          ),
        createModelAction: (model: ConnectorInfo) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new AddConnectorInfo({
                  config,
                  dataElement: model,
                  schema: this.getCustomSchema(model),
                })
              )
            );
        },
        editModelAction: (model: ConnectorInfo) =>
          this.store$.dispatch(
            new UpdateConnectorInfo({ dataElement: model, schema: this.getCustomSchema(model) })
          ),
        copyModelAction: (model: ConnectorInfo) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new CopyConnectorInfo({
                  config,
                  dataElement: model,
                  schema: this.getCustomSchema(model),
                })
              )
            );
        },
        deleteModelsAction: (models: ConnectorInfo[]) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(new DeleteConnectorInfos({ config, dataElements: models }))
            );
        },
        fixModelAction: (model: ConnectorInfo) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new FixInvalidData({
                  config,
                  dataElement: model,
                  dataElementType: DataElementType.Connector,
                  fixSchemaType: this.getSchemaType(model),
                  fixSchemaVersion: this.getSchemaVersion(model),
                  nodeId: EnvironmentConstants.FCS_NODE_ID_CONNECTORINFOS,
                  addSuccessAction: new AddConnectorInfoSuccess(),
                  schema: this.getCustomSchema(model),
                })
              )
            );
        },
      },
      fcs: {
        createSchemaOverride: () => {
          const createType = this.createDataTypeService.getCreateDataType<ConnectorShapeType>(
            DataElementType.Connector,
            this.defaultCreateType
          );
          if (createType === ConnectorShapeType.Round) {
            return roundConnectorSchema;
          } else if (createType === ConnectorShapeType.Rectangular) {
            return rectangularConnectorSchema;
          }
          return ovalConnectorSchema;
        },
        dataTypeExternalNodeId: EnvironmentConstants.FCS_NODE_ID_CONNECTORINFOS,
        schemas: [
          {
            dataType: DataElementType.Connector,
            schema: roundConnectorSchema,
          },
          {
            dataType: rectangularConnectorSchema.type,
            schema: rectangularConnectorSchema,
          },
          {
            dataType: ovalConnectorSchema.type,
            schema: ovalConnectorSchema,
          },
        ],
        subSchemas: [
          {
            parentSchema: roundConnectorSchema,
            id: `#${EnvironmentConstants.FSS_SUB_SCHEMA_ROUND_CONNECTOR_GEOMETRY_TABLE_ROW}`,
          },
          {
            parentSchema: roundConnectorSchema,
            id: `#${EnvironmentConstants.FSS_SUB_SCHEMA_ROUND_STANDARD_CONNECTOR_GEOMETRY_TABLE_ROW}`,
          },
          {
            parentSchema: roundConnectorSchema,
            id: `#${EnvironmentConstants.FSS_SUB_SCHEMA_ROUND_HEX_END_CONNECTOR_GEOMETRY_TABLE_ROW}`,
          },
          {
            parentSchema: rectangularConnectorSchema,
            id: `#${EnvironmentConstants.FSS_SUB_SCHEMA_RECTANGULAR_CONNECTOR_GEOMETRY_TABLE_ROW}`,
          },
          {
            parentSchema: rectangularConnectorSchema,
            id: `#${EnvironmentConstants.FSS_SUB_SCHEMA_RECTANGULAR_STEP_DOWN_CONNECTOR_GEOMETRY_TABLE_ROW}`,
          },
          {
            parentSchema: rectangularConnectorSchema,
            id: `#${EnvironmentConstants.FSS_SUB_SCHEMA_RECTANGULAR_COUPLING_PLATE_CONNECTOR_GEOMETRY_TABLE_ROW}`,
          },
          {
            parentSchema: ovalConnectorSchema,
            id: `#${EnvironmentConstants.FSS_SUB_SCHEMA_OVAL_CONNECTOR_GEOMETRY_TABLE_ROW}`,
          },
        ],
      },
    };
  }

  getDynamicTableOptions(
    configUnitSystem: ConfigUnitSystem
  ): Observable<DynamicTableOptions<ConnectorInfo>> {
    return this.store$.select(selectCurrentConfigConnectorInfos(true)).pipe(
      take(1),
      map((connectors: ConnectorInfo[]) => {
        const lengthUnits = ForgeUnitsService.getStandardLengthUnits(configUnitSystem);

        const connectorInfoTableColumns: ColumnDefinition[] = [
          {
            field: 'name',
            header: this.translate.instant(LC.DATATYPES.DEFINITIONS.CONNECTORS.NAME),
            link: { field: 'id' },
            visible: true,
          },
          {
            field: 'category',
            header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.CATEGORY),
            formatter: (value: string) =>
              value || this.translate.instant(LC.DATATYPES.DEFINITIONS.GENERIC.NOT_ASSIGNED),
            visible: true,
          },
          {
            field: 'domain',
            header: this.translate.instant(LC.DATATYPES.DEFINITIONS.CONNECTORS.DOMAIN),
            formatter: (value: ConnectorDomainType) => FormUtils.stringToSpaceCased(value),
            visible: true,
          },
          {
            field: 'shape',
            header: this.translate.instant(LC.DATATYPES.DEFINITIONS.CONNECTORS.SHAPE),
            visible: true,
          },
          {
            field: 'connectivityClassification',
            header: this.translate.instant(
              LC.DATATYPES.DEFINITIONS.CONNECTORS.CONNECTIVITY_CLASSIFICATION
            ),
            visible: false,
          },
          {
            field: 'connectorLead',
            header: this.translate.instant(LC.DATATYPES.DEFINITIONS.CONNECTORS.CONNECTOR_LEAD),
            formatter: (value: number) =>
              value || value === 0 ? value : this.translate.instant(LC.DYNAMIC_TABLE.NA),
            visible: false,
            units: lengthUnits,
          },
          {
            field: 'developmentTurnover',
            header: this.translate.instant(
              LC.DATATYPES.DEFINITIONS.CONNECTORS.DEVELOPMENT_TURNOVER
            ),
            formatter: (value: number) =>
              value || value === 0 ? value : this.translate.instant(LC.DYNAMIC_TABLE.NA),
            visible: false,
            units: lengthUnits,
          },
          {
            field: 'gender',
            header: this.translate.instant(LC.DATATYPES.DEFINITIONS.CONNECTORS.GENDER),
            visible: false,
          },
          {
            field: 'geometryType',
            header: this.translate.instant(LC.DATATYPES.DEFINITIONS.CONNECTORS.GEOMETRY_TYPE),
            formatter: (value: RoundGeometryType | RectangularGeometryType | OvalGeometryType) =>
              FormUtils.stringToSpaceCased(value),
            visible: false,
          },
          {
            field: 'pipeworkEndType',
            header: this.translate.instant(LC.DATATYPES.DEFINITIONS.CONNECTORS.PIPEWORK_END_TYPE),
            formatter: (value: PipeworkEndType) =>
              value
                ? FormUtils.stringToSpaceCased(value)
                : this.translate.instant(LC.DYNAMIC_TABLE.NA),
            visible: false,
          },
          {
            field: 'pressureClass',
            header: this.translate.instant(LC.DATATYPES.DEFINITIONS.CONNECTORS.PRESSURE_CLASS),
            visible: false,
          },
          {
            field: 'measurementMode',
            header: this.translate.instant(LC.DATATYPES.DEFINITIONS.CONNECTORS.MEASUREMENT_MODE),
            formatter: (value: RoundConnectorMeasurementMode) => {
              if (!value?.length) return this.translate.instant(LC.DYNAMIC_TABLE.NA);

              if (value === RoundConnectorMeasurementMode.OutsideDiameter)
                return this.translate.instant(
                  LC.ENUMS.ROUND_CONNECTOR_MEASUREMENT_MODE.OUTSIDE_DIAMETER
                );

              return this.translate.instant(
                LC.ENUMS.ROUND_CONNECTOR_MEASUREMENT_MODE.WALL_THICKNESS
              );
            },
            visible: false,
          },
          {
            field: 'fabricationReferences',
            header: this.translate.instant(LC.DATATYPES.DEFINITIONS.CONNECTORS.MATCHING_CONNECTOR),
            formatter: (value: FabricationReference[]) => {
              const unassignedLabel = this.translate.instant(LC.GRAPH.UNASSIGNED);
              const connectorId = value?.find(
                (ref) => ref.dataType === DataElementType.Connector
              )?.externalId;

              if (!connectorId || connectorId === EnvironmentConstants.FCS_UNASSIGNED_CONNECTOR) {
                return unassignedLabel;
              }

              const connector = connectors.find((c) => c.externalId === connectorId);
              return connector?.name ?? unassignedLabel;
            },
            visible: false,
          },
        ];

        const createOptions: CreateOptions = {
          options: [
            {
              label: this.translate.instant(LC.ENUMS.CONNECTOR_SHAPE_TYPE.ROUND),
              value: () => ConnectorShapeType.Round,
            },
            {
              label: this.translate.instant(LC.ENUMS.CONNECTOR_SHAPE_TYPE.RECTANGULAR),
              value: () => ConnectorShapeType.Rectangular,
            },
            {
              label: this.translate.instant(LC.ENUMS.CONNECTOR_SHAPE_TYPE.OVAL),
              value: () => ConnectorShapeType.Oval,
            },
          ],
          setType: (value: ConnectorShapeType) => {
            this.createDataTypeService.saveCreateDataType({
              dataType: DataElementType.Connector,
              createType: value,
            });
          },
        };

        return this.createDynamicTableOptions(connectorInfoTableColumns, createOptions);
      })
    );
  }

  getDynamicFormOptions(
    formOperation: DynamicFormOperationType,
    modelId: string
  ): Observable<DynamicFormOptions<ConnectorInfo>> {
    const uniqueFieldRestrictions = ['name', 'category'];
    const categories = this.getItemListForTypeaheadControl('category');
    const connectivityClassifications = this.getItemListForTypeaheadControl(
      'connectivityClassification'
    );
    const titleField = 'name';
    const isReadOnly = formOperation === 'view';

    this.getFormModel(formOperation, modelId)
      .pipe(take(1))
      .subscribe((model) => {
        this.modelChangeSubject.next(model);
      });

    return combineLatest([
      this.getFormModel(formOperation, modelId),
      this.options.selectors.selectAll(true),
    ]).pipe(
      map(([model, allConnectors]) => {
        const referenceData = this.getFabricationReferenceFields(model);
        const shape = model.shape;
        return {
          model,
          formOperation,
          modelChangeHandler: (model) => this.modelChangeSubject.next(model),
          getCustomSchema: (model: ConnectorInfo) => this.getCustomSchema(model),
          applyModelAction: this.getFormApplyAction(formOperation),
          isReadOnly,
          uniqueFields: {
            fields: uniqueFieldRestrictions,
            allElements: () => this.options.selectors.selectAll(true),
          },
          formStyle: DynamicFormStyle.NONE,
          hiddenFields: referenceData
            .filter((ref) => ref.dataType !== DataElementType.Connector)
            .map((ref) => ref.key),
          groups: [
            {
              label: this.translate.instant(LC.DYNAMIC_FORM.TABS.BASIC),
              includeFields: this.getBasicIncludeFields(shape),
              expanded: true,
              orderByIncludeFields: true,
              options: {
                expressions: this.getBasicFieldExpressions(allConnectors, isReadOnly),
                hiddenFields: referenceData
                  .filter((ref) => ref.dataType !== DataElementType.Connector)
                  .map((ref) => ref.key),
                tooltipFields: ['shape', 'domain', 'gender', 'connectivityClassification'],
                readOnlyFields: this.getBasicReadonlyFields(model, allConnectors),
                selectFields: this.getBasicSelectFields(model, shape),
                labelMapping: this.getBasicLabelMapping(model),
                customComponents:
                  shape === ConnectorShapeType.Rectangular
                    ? [
                        {
                          type: DynamicFormCustomComponentType.ToggleValue,
                          field: 'stepDown',
                          toggleValueOptions: {
                            message: 'Use Liner Thickness',
                            trueValue: RectangularStepDownOption.LinerThickness,
                            units: {
                              imperial: UnitSchemaType.Inches,
                              metric: UnitSchemaType.Millimeters,
                            },
                          },
                        },
                      ]
                    : null,
                unitsFields:
                  shape === ConnectorShapeType.Rectangular
                    ? [
                        {
                          key: 'connectorLead',
                          units: {
                            imperial: UnitSchemaType.Inches,
                            metric: UnitSchemaType.Millimeters,
                          },
                        },
                      ]
                    : [],
                dropdownTypeaheadFields: [
                  {
                    key: 'category',
                    options: categories,
                  },
                  {
                    key: 'connectivityClassification',
                    options: connectivityClassifications,
                  },
                ],
                formStyle: DynamicFormStyle.SIMPLE,
              },
            },
            {
              label: this.translate.instant(LC.DYNAMIC_FORM.TABS.MANUFACTURING),
              includeFields: this.getManufacturingIncludeFields(shape),
              orderByIncludeFields: true,
              options: {
                unitsFields:
                  shape === ConnectorShapeType.Rectangular
                    ? [
                        {
                          key: 'developmentTurnover',
                          units: {
                            imperial: UnitSchemaType.Inches,
                            metric: UnitSchemaType.Millimeters,
                          },
                        },
                      ]
                    : [],
                formStyle: DynamicFormStyle.SIMPLE,
              },
            },
            {
              label: this.translate.instant(LC.DYNAMIC_FORM.TABS.GEOMETRY),
              includeFields: ['geometryType', 'measurementMode', 'breakPointTable'],
              orderByIncludeFields: true,
              options: {
                formStyle: DynamicFormStyle.SIMPLE,
                selectFields: this.getGeometrySelectFields(shape),
                customComponents: [
                  {
                    type: DynamicFormCustomComponentType.ConnectorBreakPointTable,
                    field: 'breakPointTable',
                  },
                ],
              },
            },
          ],
          titleField,
        };
      })
    );
  }

  getDynamicGraphOptions(): DynamicGraphOptions {
    return {
      nodeInfoFields: ['name', 'category', 'shape'],
      isReplaceable: true,
      isRemovable: true,
      isEditable: true,
      requireModelOnLoadList: true,
      ignoreDownstreamReferences: [
        DataElementType.FabricationRate,
        DataElementType.InstallationRate,
      ],
      upstreamReferenceDataTypes: () => [
        DataElementType.Part,
        DataElementType.Specification,
        DataElementType.Connector,
      ],
      clusterIcon: 'file-assembly16',
      clusterLabel: (sourceDataType, currentDataType) => {
        const isMatchingConnector = sourceDataType === currentDataType;

        return isMatchingConnector
          ? this.translate.instant(LC.DATATYPES.DEFINITIONS.CONNECTORS.MATCHING_CONNECTOR)
          : null;
      },
      internalUpstreamReferencesDataTypes: () => [DataElementType.Specification],
      createInternalUpstreamGraphNodes: (dataElement, references) => {
        const partSpecifications = references
          .filter((ref) => ref.dataElement && ref.dataElementType === DataElementType.Specification)
          .map((ref) => ref.dataElement as SpecificationInfo);

        if (partSpecifications?.length) {
          const graphNodes = flatten(
            partSpecifications.map((spec) => {
              const tables = spec.tables;

              return tables
                .filter((table) =>
                  table.entries.some(
                    (entry) =>
                      entry.primaryConnector === dataElement.externalId ||
                      entry.secondaryConnector === dataElement.externalId
                  )
                )
                .map((table) => {
                  const { shape, domain, appliesTo } = table;
                  return {
                    id: table.id,
                    internalRelationshipId: spec.externalId,
                    internalRelationshipDataType: DataElementInternalType.PartSpecficationTable,
                    dbid: dataElement.id,
                    dataType: DataElementType.Connector,
                    isFocusable: false,
                    isExpandable: true,
                    isReplaceable: false,
                    isRemovable: false,
                    isEditable: false,
                    info: {
                      shape,
                      domain,
                      appliesTo,
                    },
                    referenceMetaData: null,
                    isInvalidInternalData:
                      spec.extensionDataType === EnvironmentConstants.FSS_SCHEMA_INVALID_DATA,
                  };
                });
            })
          );

          return of(graphNodes);
        } else {
          return of([
            {
              id: '-2',
              dataType: DataElementType.Specification,
              isExpandable: false,
              hasNoReference: true,
            },
          ]);
        }
      },
      getInternalUpstreamReferenceFilteredElements: (
        references: ReferenceOutput[],
        internalRelationshipId: string
      ) => {
        const selectedSpecification = references
          .filter((ref) => ref.referenceMap.dataElementType === DataElementType.Specification)
          .find((ref) => ref.referenceMap.dataElement.externalId === internalRelationshipId);

        return [selectedSpecification];
      },
    };
  }

  getManufacturingIncludeFields(shape: ConnectorShapeType): string[] {
    let includeFields = [];

    switch (shape) {
      case ConnectorShapeType.Rectangular:
        includeFields = includeFields.concat(['developmentTurnover']);
        break;

      default:
        break;
    }

    return includeFields;
  }

  getReferencingConnectors(model: ConnectorInfo, allConnectors: ConnectorInfo[]) {
    return allConnectors.filter((connector) =>
      connector.fabricationReferences.some((ref) => ref.externalId === model.externalId)
    );
  }

  getBasicFieldExpressions(allConnectors: ConnectorInfo[], isReadOnly: boolean) {
    return {
      'props.disabled': (field) => {
        if (isReadOnly || field.form.disabled) return true;

        const readonlyField = this.getBasicReadonlyFields(field.model, allConnectors);

        return readonlyField.includes(field.key.toString());
      },
      'props.tooltip': (field) => {
        if (isReadOnly) return {};

        const readonlyFields = this.getBasicReadonlyFields(field.model, allConnectors);

        if (!readonlyFields.includes(field.key.toString())) return {};

        const fieldName = this.translate.instant(tooltipTranslationMap[field.key as string]);
        const title = this.translate.instant(LC.TOOLTIP.CONNECTOR.READONLY_FIELD, { fieldName });
        const referencingConnectors = this.getReferencingConnectors(field.model, allConnectors);
        const messageKey =
          referencingConnectors.length === 0
            ? LC.TOOLTIP.CONNECTOR.REMOVE_MATCHING_CONNECTOR
            : referencingConnectors.length === 1
            ? LC.TOOLTIP.CONNECTOR.REFERENCED_AS_MATCHING_CONNECTOR_SINGULAR
            : LC.TOOLTIP.CONNECTOR.REFERENCED_AS_MATCHING_CONNECTOR_PLURAL;

        switch (field.key) {
          case 'shape':
            return { title };
          case 'gender':
          case 'domain':
          case 'connectivityClassification':
            return {
              title,
              content: this.translate.instant(messageKey, {
                fieldName,
                number: referencingConnectors.length,
              }),
            };
          default:
            return {};
        }
      },
    };
  }

  getBasicReadonlyFields(model: ConnectorInfo, allConnectors: ConnectorInfo[]): string[] {
    const readonlyFields = ['shape'];
    const matchingConnector = model.fabricationReferences.find(
      (ref) => ref.dataType === DataElementType.Connector
    );
    const hasMatchingConnector =
      matchingConnector &&
      matchingConnector.externalId !== EnvironmentConstants.FCS_UNASSIGNED_CONNECTOR;
    const isUsedAsMatchingConnector = allConnectors.some((connector) =>
      connector.fabricationReferences.some((ref) => ref.externalId === model.externalId)
    );

    if (hasMatchingConnector || isUsedAsMatchingConnector) {
      readonlyFields.push('gender', 'domain', 'connectivityClassification');
    }

    return readonlyFields;
  }

  getBasicIncludeFields(shape: ConnectorShapeType): string[] {
    let includeFields = [
      'name',
      'category',
      'shape',
      'gender',
      'domain',
      'connectivityClassification',
      'fabricationReferences',
    ];

    switch (shape) {
      case ConnectorShapeType.Round:
        includeFields = includeFields.concat(['pipeworkEndType', 'pressureClass']);
        break;

      case ConnectorShapeType.Rectangular:
        includeFields = includeFields.concat([
          'connectorLead',
          'stepDown', // todo: this is an option type
        ]);
        break;

      default:
        break;
    }

    return includeFields;
  }

  getBasicLabelMapping(model: ConnectorInfo): DynamicFormFieldLabelMapping[] {
    const matchingConnectorIndex = model.fabricationReferences.findIndex(
      (ref) => ref.dataType === DataElementType.Connector
    );

    return [
      {
        key: `fabricationReferences.${matchingConnectorIndex}.externalId`,
        label: this.translate.instant(LC.DATATYPES.DEFINITIONS.CONNECTORS.MATCHING_CONNECTOR),
      },
    ];
  }

  getBasicSelectFields(model: ConnectorInfo, shape: ConnectorShapeType): DynamicFormSelectField[] {
    const matchingConnectorIndex = model.fabricationReferences.findIndex(
      (ref) => ref.dataType === DataElementType.Connector
    );

    const matchingConnectorOptions = combineLatest([
      this.getReferenceSelector(DataElementType.Connector),
      this.modelChangeSubject,
    ]).pipe(
      map(([referenceInfoList, updatedModel]) => {
        const currentConnector = updatedModel ?? model;
        const matchingConnectorInfoList = referenceInfoList.filter((connector: ConnectorInfo) => {
          // after connector is modified but not saved yet, it may appear in the list,
          // since the store has not been updated. So need to exclude the current connector
          if (connector.externalId === currentConnector.externalId) return false;

          // cannot pair if shape does not match
          if (currentConnector.shape !== connector.shape) return false;

          // cannot pair if the connectivity classification is not the same
          if (
            currentConnector.connectivityClassification !== connector.connectivityClassification
          ) {
            return false;
          }

          // if either connector domains are not set, or if they are set and are different, then they cannot pair
          if (
            currentConnector.domain !== ConnectorDomainType.NotSet &&
            connector.domain !== ConnectorDomainType.NotSet &&
            currentConnector.domain !== connector.domain
          ) {
            return false;
          }

          // none can match with none, whilst male matches to female and vice versa
          if (currentConnector.gender === ConnectorGenderType.None) {
            return connector.gender === ConnectorGenderType.None;
          } else if (currentConnector.gender === ConnectorGenderType.Male) {
            return connector.gender === ConnectorGenderType.Female;
          } else {
            return connector.gender === ConnectorGenderType.Male;
          }
        });

        return this.getDataElementTypeAsSummaryList(
          DataElementType.Connector,
          matchingConnectorInfoList,
          model,
          matchingConnectorIndex
        );
      })
    );

    const selectFields: DynamicFormSelectField[] = [
      {
        key: 'gender',
        options: FormUtils.mapSelectOptionsFromEnum(
          this.translate,
          ConnectorGenderType,
          'ConnectorGenderType'
        ),
      },
      {
        key: `fabricationReferences.${matchingConnectorIndex}.externalId`,
        options: matchingConnectorOptions,
      },
    ];

    const domainTypes: DynamicFormSelectType[] = FormUtils.mapSelectOptionsFromEnum(
      this.translate,
      ConnectorDomainType,
      'ConnectorDomainType'
    );

    switch (shape) {
      default:
      case ConnectorShapeType.Round:
        selectFields.push(
          {
            key: 'pipeworkEndType',
            options: FormUtils.mapSelectOptionsFromEnum(
              this.translate,
              PipeworkEndType,
              'PipeworkEndType'
            ),
          },
          {
            key: 'domain',
            options: domainTypes,
          }
        );
        break;

      case ConnectorShapeType.Rectangular:
        selectFields.push({
          key: 'domain',
          options: [domainTypes[0], domainTypes[1], domainTypes[3]],
        });
        break;

      case ConnectorShapeType.Oval:
        selectFields.push({
          key: 'domain',
          options: [domainTypes[0], domainTypes[1]],
        });
        break;
    }

    return selectFields;
  }

  getGeometrySelectFields(shape: ConnectorShapeType): DynamicFormSelectField[] {
    let selectFields: DynamicFormSelectField[] = [];

    switch (shape) {
      case ConnectorShapeType.Round:
        selectFields = selectFields.concat([
          {
            // if this property name is changed or moved to a different tab in the UI,
            // the breakpoint table custom component needs to be updated
            key: 'geometryType',
            options: FormUtils.mapSelectOptionsFromEnum(
              this.translate,
              RoundGeometryType,
              'RoundGeometryType'
            ),
          },
          {
            key: 'measurementMode',
            options: [
              {
                label: this.translate.instant(
                  LC.ENUMS.ROUND_CONNECTOR_MEASUREMENT_MODE.OUTSIDE_DIAMETER
                ),
                value: RoundConnectorMeasurementMode.OutsideDiameter,
              },
              {
                label: this.translate.instant(
                  LC.ENUMS.ROUND_CONNECTOR_MEASUREMENT_MODE.WALL_THICKNESS
                ),
                value: RoundConnectorMeasurementMode.WallThickness,
              },
            ],
          },
        ]);
        break;

      case ConnectorShapeType.Rectangular:
        selectFields = selectFields.concat([
          {
            key: 'geometryType',
            options: FormUtils.mapSelectOptionsFromEnum(
              this.translate,
              RectangularGeometryType,
              'RectangularGeometryType'
            ),
          },
        ]);
        break;

      case ConnectorShapeType.Oval:
      default:
        selectFields = selectFields.concat([
          {
            key: 'geometryType',
            options: FormUtils.mapSelectOptionsFromEnum(
              this.translate,
              OvalGeometryType,
              'OvalGeometryType'
            ),
          },
        ]);
        break;
    }

    return selectFields;
  }

  private getSchemaType(model: ConnectorInfo): string {
    switch (model.shape) {
      default:
      case ConnectorShapeType.Round:
        return EnvironmentConstants.FSS_SCHEMA_ROUND_CONNECTOR;

      case ConnectorShapeType.Rectangular:
        return EnvironmentConstants.FSS_SCHEMA_RECTANGULAR_CONNECTOR;

      case ConnectorShapeType.Oval:
        return EnvironmentConstants.FSS_SCHEMA_OVAL_CONNECTOR;
    }
  }

  private getCustomSchema(model: ConnectorInfo): JSONSchema7 {
    if (model.shape === ConnectorShapeType.Round) {
      return this.schemaService.getSchemaByDataElementType(DataElementType.Connector);
    } else if (model.shape === ConnectorShapeType.Rectangular) {
      return this.schemaService.getSchemaByDataElementType(
        EnvironmentConstants.FSS_SCHEMA_RECTANGULAR_CONNECTOR
      );
    }

    return this.schemaService.getSchemaByDataElementType(
      EnvironmentConstants.FSS_SCHEMA_OVAL_CONNECTOR
    );
  }

  private getSchemaVersion(model: ConnectorInfo): string {
    switch (model.shape) {
      default:
      case ConnectorShapeType.Round:
        return EnvironmentConstants.FSS_SCHEMA_ROUND_CONNECTOR_VERSION;

      case ConnectorShapeType.Rectangular:
        return EnvironmentConstants.FSS_SCHEMA_RECTANGULAR_CONNECTOR_VERSION;

      case ConnectorShapeType.Oval:
        return EnvironmentConstants.FSS_SCHEMA_OVAL_CONNECTOR_VERSION;
    }
  }

  fixMissingReferences(fabricationReferences: FabricationReference[]): FabricationReference[] {
    let references: FabricationReference[] = [];
    if (fabricationReferences?.length) {
      references = references.concat(fabricationReferences);
    }

    const fabRate = fabricationReferences?.find(
      (x) => x.dataType === DataElementType.FabricationRate
    );
    if (!fabRate) {
      references.push({
        externalId: null,
        dataType: DataElementType.FabricationRate,
        referenceType: FabricationReferenceType.Relationship,
      });
    }

    const installRate = fabricationReferences?.find(
      (x) => x.dataType === DataElementType.InstallationRate
    );
    if (!installRate) {
      references.push({
        externalId: null,
        dataType: DataElementType.InstallationRate,
        referenceType: FabricationReferenceType.Relationship,
      });
    }

    const matchingConnector = fabricationReferences?.find(
      (x) => x.dataType === DataElementType.Connector
    );
    if (!matchingConnector) {
      references.push({
        externalId: EnvironmentConstants.FCS_UNASSIGNED_CONNECTOR,
        dataType: DataElementType.Connector,
        referenceType: FabricationReferenceType.Relationship,
      });
    }

    return references;
  }

  dataFixes(): void {
    //
  }

  getIconName(): string {
    return 'file-assembly';
  }

  isFixable(): boolean {
    return true;
  }

  getInvalidDataErrors(model: ConnectorInfo & InvalidData): string {
    let schema;
    switch (model.shape) {
      default:
      case ConnectorShapeType.Round:
        schema = this.schemaService.getSchemaByDataElementType(DataElementType.Connector);
        break;

      case ConnectorShapeType.Rectangular:
        schema = this.schemaService.getSchemaByDataElementType(
          EnvironmentConstants.FSS_SCHEMA_RECTANGULAR_CONNECTOR
        );
        break;

      case ConnectorShapeType.Oval:
        schema = this.schemaService.getSchemaByDataElementType(
          EnvironmentConstants.FSS_SCHEMA_OVAL_CONNECTOR
        );
        break;
    }

    return this.getStandardInvalidDataError(DataElementType.Connector, model, schema);
  }

  requiresBinaryUpgrade(/*dataElement: ConnectorInfo*/): boolean {
    return false;
  }
}

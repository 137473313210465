import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AccountsEnvironmentSettings } from '@models/environment/environment-settings';
import { AuthService } from '@services/auth.service';
import { EnvironmentService } from '@services/environment.service';
import { Group, Header, Link, NavigationData, Submodule } from '@models/ui-state/navigation-data';
import { NotificationService } from '@services/notification.service';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { NotificationPlace } from '@models/notification/notification';
import { TranslateService } from '@ngx-translate/core';
import { filter, take } from 'rxjs/operators';
import {
  HelpItem,
  NotificationFlyoutTypes,
  NotificationListItem,
  ProfileInfo,
  Project,
} from '@models/ui-state/hig-navigation';
import { NavigationService } from '@services/navigation.service';
import { FeatureFlagService } from '@services/feature-flag.service';
import { FeatureFlagConstants } from '@constants/feature-flag-constants';
import { NavigationConstants as nc } from '@constants/navigation-constants';

@Component({
  selector: 'hig-global-nav',
  templateUrl: './global-nav.component.html',
  styleUrls: ['./global-nav.component.scss'],
})
export class HigGlobalNavComponent implements OnDestroy {
  notificationSubscription: Subscription;
  hideNotificationSubscription: Subscription;
  routerChangeSubscription: Subscription;
  navigationSubscription: Subscription;
  activeProject = '';
  defaultProject = '';
  profileSettingsLink = '';
  sideNavOpened = true;
  profileInfo: ProfileInfo = {};
  projects: Project[] = [];
  sideNavHeader: Header = {};
  sideNavLinks: Link[] = [];
  sideNavGroups: Group[] = [];
  helpItems: HelpItem[] = [];
  notifications: NotificationListItem[] = [];
  navigationData: NavigationData;
  isLoading = true;
  showLearningPanel = false;
  learningPanelOpened = false;
  private accountsEnvironment: AccountsEnvironmentSettings = null;

  constructor(
    private router: Router,
    private authService: AuthService,
    private envService: EnvironmentService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private navigationService: NavigationService,
    private featureFlagService: FeatureFlagService
  ) {
    this.authService.apploginComplete
      .pipe(
        filter((loginComplete: boolean) => loginComplete),
        take(1)
      )
      .subscribe(() => {
        this.accountsEnvironment = this.envService.environment.accounts;
        this.listenToNavigationChanges();
      });
  }

  listenToNavigationChanges = (): void => {
    this.navigationSubscription = this.navigationService.currentNavigationData
      .pipe(filter((navigationData) => !!navigationData))
      .subscribe((navigationData: NavigationData) => {
        this.navigationData = navigationData;
        this.isLoading = navigationData.appIsLoading;
        this.setupGlobalNav();
      });

    this.notificationSubscription = this.notificationService
      .getNotificationChanges()
      .subscribe(({ id, place, messages, type, link, onDismiss, description, timestamp }) => {
        if (place === NotificationPlace.Flyout) {
          this.notifications = [
            {
              id: id ?? uuidv4(),
              type: NotificationFlyoutTypes[type],
              content: messages.join('\n'),
              description,
              timestamp: timestamp ? new Date(timestamp) : new Date(),
              link,
              onDismiss,
            },
            ...this.notifications,
          ];
        }
      });

    this.hideNotificationSubscription = this.notificationService
      .getHideNotificationChanges()
      .subscribe(({ id }) => {
        if (id) {
          this.notifications = this.notifications.filter((notification) => notification.id !== id);
        }
      });
  };

  ngOnDestroy(): void {
    this.notificationSubscription?.unsubscribe();
    this.hideNotificationSubscription?.unsubscribe();
    this.routerChangeSubscription?.unsubscribe();
    this.navigationSubscription?.unsubscribe();
  }

  setupGlobalNav() {
    // setup the top nav
    this.setupTopNav();

    this.showLearningPanel = this.featureFlagService.isFeatureFlagEnabled(
      FeatureFlagConstants.LEARNING_PANEL
    );

    if (this.navigationData) {
      // setup the sidebar
      this.setupSideNav();
      // listen for route changes so we can setup the active side nav links
      // only setup the active links once navigation is finsihed successfully
      this.routerChangeSubscription =
        this.routerChangeSubscription ??
        this.router.events
          .pipe(
            filter(
              (e): e is NavigationEnd =>
                e instanceof NavigationEnd && this.currentRouteMatchesSideNavLinks()
            )
          )
          .subscribe(() => {
            this.setupSideNav();
          });
    }
  }

  setupTopNav() {
    const userData = this.authService.currentUserData;

    if (userData) {
      this.profileInfo = {
        image: userData.profileImages.sizeX40,
        name: `${userData.firstName} ${userData.lastName}`,
        email: userData.emailId,
      };

      this.profileSettingsLink = this.accountsEnvironment.userAccountUrl;
    }

    if (this.navigationData?.dataSource?.length) {
      const selectedDataId = this.navigationData.selectedId;
      this.defaultProject = selectedDataId;

      this.projects = this.navigationData.dataSource
        .map((data) => ({
          id: data.id,
          label: data.name,
          image: data.image,
        }))
        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0));
    } else {
      this.projects = [];
    }

    // help menu items
    this.helpItems =
      this.navigationData?.helpMenuItems.map((helpLink) => {
        const text = this.translate.instant(helpLink.title);
        const description = helpLink.description && this.translate.instant(helpLink.description);
        const { hasDivider, link, openInNewWindow } = helpLink;
        let onClick: (e?: any) => void;

        if (!helpLink.openInNewWindow) {
          onClick = (e) => {
            this.preventDefaultExecution(e);
            this.router.navigate([helpLink.link]);
          };
        }

        if (helpLink.title === nc.SIDE_NAV_SUB_MODULE_PRIVACY_SETTINGS_TITLE) {
          onClick = () => {
            this.navigationService.setShowUserConsentModal(true);
          };
        }

        return {
          text,
          description,
          onClick,
          hasDivider,
          link,
          openInNewWindow,
        };
      }) || [];
  }

  setupSideNav() {
    // check for active links that match the current url
    const currentNavDataMatchesUrl = this.currentRouteMatchesSideNavLinks();
    this.sideNavHeader = {
      title: this.navigationData.superHeaderTitle,
      label: this.navigationData.headerLabel,
      link: this.navigationData.headerLink,
    };

    this.sideNavLinks = this.navigationData.links.map((link) => ({
      title: this.translate.instant(link.title),
      link: link.link,
      target: '_blank',
    }));

    const navigationOptionsModules =
      this.navigationData && this.navigationData.modules && !this.navigationData.appIsLoading
        ? this.navigationData.modules
        : [];
    this.sideNavGroups = navigationOptionsModules.map((mod) => {
      if (!mod.isDisabled) {
        const submodules: Submodule[] = mod.subModules.map((subModule) => {
          if (!subModule.isDisabled) {
            return {
              title: this.translate.instant(subModule.title),
              active: (currentNavDataMatchesUrl && this.isSubModuleActive(subModule.link)) || false,
              link: subModule.link,
              onClick: (e: any) => {
                this.preventDefaultExecution(e);
                if (e.ctrlKey || e.metaKey) {
                  // Open the link in a new tab when pressed Ctrl/Cmd key
                  const url = this.router.createUrlTree([subModule.link]).toString();
                  window.open(url, '_blank');
                } else {
                  this.router.navigate([subModule.link]);
                }
              },
            };
          }
          return null;
        });

        const moduleIsActive = submodules.some((x) => {
          if (x) {
            return x.active;
          } else {
            return null;
          }
        });

        const group: Group = {
          modules: [
            {
              icon: mod.icon,
              title: this.translate.instant(mod.title),
              active: moduleIsActive,
              activeChildren: moduleIsActive,
              submodules,
            },
          ],
        };

        return group;
      } else {
        return {};
      }
    });
  }

  handleOnClickSignOut = (e): void => {
    this.preventDefaultExecution(e);
    this.authService.logout();
  };

  handleOnChangeProject = (project: Project): void => {
    this.router.navigate(['/data/config', `${project.id}`]);
  };

  handleOnClickHamburger() {
    this.sideNavOpened = !this.sideNavOpened;
  }

  handleOnClickLogo() {
    this.router.navigate([this.navigationData.headerLink]);
  }

  handleToggleLearningPanel() {
    this.learningPanelOpened = !this.learningPanelOpened;
  }

  preventDefaultExecution(event: any) {
    // prevent button click defaults
    event.stopPropagation();
    event.preventDefault();

    return true;
  }

  // check a side nav link to see if it matches the active route
  isSubModuleActive(subModuleLink: string): boolean {
    const { url } = this.router;
    const subModuleIsActive = new RegExp(subModuleLink).test(url);
    return subModuleIsActive;
  }

  // check to see if current route matches any of the side nav links
  // use to prevent too many check to set the active links
  currentRouteMatchesSideNavLinks(): boolean {
    // check empt menu
    if (!this.navigationData?.modules?.length) return false;
    const { url } = this.router;
    const sideNavLinks = this.navigationData.modules
      .map((x) => x.subModules)
      .reduce((prev, current) => [...prev, ...current])
      .map((x) => x.link);

    const hasActiveSubModuleLink = sideNavLinks.some((x) => new RegExp(x).test(url));
    return hasActiveSubModuleLink;
  }
}

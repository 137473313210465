import { Injectable } from '@angular/core';
import { DynamicDataElementTypeSetup } from '@data-management/dynamic-data-setup/base/dynamic-data';
import { DynamicTableOptions, ColumnDefinition } from '@models/dynamic-table/dynamic-table-options';
import { DynamicFormOperationType } from '@models/dynamic-form/dynamic-form-types';
import { DynamicFormOptions, DynamicFormStyle } from '@models/dynamic-form/dynamic-form-properties';
import { DataElementType } from '@constants/data-element-types';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers/index';
import { Config } from '@models/fabrication/config';
import {
  HangerEngagement,
  InsulationType,
  ModelBy,
  SystemInfo,
} from '@models/fabrication/system-info';
import { EnvironmentConstants } from '@constants/environment-constants';
import { map, take } from 'rxjs/operators';
import { DynamicGraphOptions } from '@models/dynamic-graph/dynamic-graph-options';
import {
  selectCurrentConfigSystemInfos,
  selectCurrentConfig,
  selectCurrentConfigServiceTemplateInfos,
} from '@store/selectors/configs.selectors';
import { selectSystemInfoById } from '@store/selectors/system-info.selectors';
import {
  LoadSystemInfos,
  LoadSystemInfosSuccess,
  AddSystemInfo,
  UpdateSystemInfo,
  CopySystemInfo,
  DeleteSystemInfos,
  DeleteSystemInfoSuccess,
  AddSystemInfoSuccess,
  UpdateSystemInfoSuccess,
} from '@store/actions/system-info.action';
import { UpdateConfigSystemInfoIds } from '@store/actions/configs.action';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { FormUtils } from '@utils/formly/formly-utils';
import { selectInternalInvalidData } from '@store/selectors/invalid-data.selectors';
import { FixInvalidData } from '@store/actions/invalid-data.action';
import { FabricationReference, FabricationReferenceType } from '@models/forge-content/references';
import { InvalidDataErrorService } from '@services/invalid-data-error.service';
import { ForgeSchemaInfo } from '@models/forge-content/forge-content-schema';
import helpLinks from '@assets/help/help-links.json';
import { EnvironmentService } from '@services/environment.service';
import { InvalidData, InvalidDataSchemaErrorReason } from '@models/fabrication/invalid-data';
import { SchemaService } from '@services/schema.service';
import { Observable } from 'rxjs';
import { ServiceTemplateInfo } from '@models/fabrication/service-template-info';

@Injectable()
export class DynamicSystemInfoSetup extends DynamicDataElementTypeSetup<SystemInfo> {
  constructor(
    store$: Store<FDMState>,
    translate: TranslateService,
    invalidDataService: InvalidDataErrorService<SystemInfo>,
    schemaService: SchemaService,
    environmentService: EnvironmentService
  ) {
    super(store$, translate, invalidDataService, schemaService, environmentService);
  }

  get helpLinkId(): string {
    return helpLinks.dataTypes.services;
  }

  setupOptions() {
    const serviceSchema: ForgeSchemaInfo = {
      namespace: EnvironmentConstants.FSS_SCHEMA_NAMESPACE,
      version: EnvironmentConstants.FSS_SCHEMA_SERVICE_VERSION,
      type: EnvironmentConstants.FSS_SCHEMA_SERVICE,
    };

    this.options = {
      dataType: DataElementType.Service,
      dependentDataTypes: [
        DataElementType.ServiceTemplate,
        DataElementType.Specification,
        DataElementType.InsulationSpecification,
      ],
      createNewInstance: () => new SystemInfo(),
      sortFields: ['category', 'name'],
      supportsDynamicUpdates: true,
      selectors: {
        selectAll: (includeInvalidData: boolean) =>
          this.store$.select(selectCurrentConfigSystemInfos(includeInvalidData)),
        selectById: (id: string, getInternalInvalidData?: boolean) =>
          getInternalInvalidData
            ? this.store$.select(selectInternalInvalidData(id, this.fixMissingReferences))
            : this.store$.select(selectSystemInfoById(id)),
      },
      actions: {
        loadAllAction: (config: Config) => this.store$.dispatch(new LoadSystemInfos({ config })),
        loadSuccessAction: () => new LoadSystemInfosSuccess(),
        deleteDataSuccessAction: () => new DeleteSystemInfoSuccess(),
        addDataSuccessAction: () => new AddSystemInfoSuccess(),
        updateDataSuccessAction: () => new UpdateSystemInfoSuccess(),
        updateDataReferencesAction: (
          config: Config,
          dataIds: string[],
          deleteReferences: boolean
        ) =>
          new UpdateConfigSystemInfoIds(
            {
              id: config.externalId,
              changes: dataIds,
            },
            deleteReferences
          ),
        createModelAction: (model: SystemInfo) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new AddSystemInfo({
                  config,
                  dataElement: model,
                })
              )
            );
        },
        editModelAction: (model: SystemInfo) =>
          this.store$.dispatch(new UpdateSystemInfo({ dataElement: model })),
        copyModelAction: (model: SystemInfo) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new CopySystemInfo({
                  config,
                  dataElement: model,
                })
              )
            );
        },
        deleteModelsAction: (models: SystemInfo[]) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(new DeleteSystemInfos({ config, dataElements: models }))
            );
        },
        fixModelAction: (model: SystemInfo) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new FixInvalidData({
                  config,
                  dataElement: model,
                  dataElementType: DataElementType.Service,
                  fixSchemaType: EnvironmentConstants.FSS_SCHEMA_SERVICE,
                  fixSchemaVersion: EnvironmentConstants.FSS_SCHEMA_SERVICE_VERSION,
                  nodeId: EnvironmentConstants.FCS_NODE_ID_SERVICE_DATA,
                  addSuccessAction: new AddSystemInfoSuccess(),
                })
              )
            );
        },
      },
      fcs: {
        dataTypeExternalNodeId: EnvironmentConstants.FCS_NODE_ID_SYSTEMINFOS,
        schemas: [
          {
            dataType: DataElementType.Service,
            schema: serviceSchema,
          },
        ],
      },
    };
  }

  getDynamicTableOptions(): Observable<DynamicTableOptions<SystemInfo>> {
    return this.store$.select(selectCurrentConfigServiceTemplateInfos(true)).pipe(
      map((serviceTemplates: ServiceTemplateInfo[]) => {
        const systemInfoTableColumns: ColumnDefinition[] = [
          {
            field: 'name',
            header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.NAME),
            link: { field: 'id' },
            visible: true,
          },
          {
            field: 'category',
            header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.CATEGORY),
            formatter: (value: string) =>
              value || this.translate.instant(LC.DATATYPES.DEFINITIONS.GENERIC.NOT_ASSIGNED),
            visible: true,
          },
          {
            field: 'abbreviation',
            header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.ABBREVIATION),
            visible: true,
          },
          {
            field: 'hangerEngagement',
            header: this.translate.instant(LC.DATATYPES.DEFINITIONS.SERVICES.HANGER_ENGAGEMENT),
            formatter: (value: HangerEngagement) => FormUtils.stringToSpaceCased(value),
            visible: false,
          },
          {
            field: 'insulationType',
            header: this.translate.instant(LC.DATATYPES.DEFINITIONS.SERVICES.INSULATION_TYPE),
            visible: false,
          },
          {
            field: 'modelBy',
            header: this.translate.instant(LC.DATATYPES.DEFINITIONS.SERVICES.MODEL_BY),
            formatter: (value: ModelBy) => FormUtils.stringToSpaceCased(value),
            visible: false,
          },
          {
            // note that this will only show the service template reference and will not show the
            // part spec or insulation spec reference.
            field: 'fabricationReferences',
            header: this.translate.instant(LC.DATATYPES.TYPES.SERVICE_TEMPLATE),
            disableSort: true,
            formatter: (value: FabricationReference[]) => {
              const serviceTemplateReference = value.find(
                (x) => x.dataType === DataElementType.ServiceTemplate
              );

              if (!serviceTemplateReference) {
                return ''; // this will be an invalid service template since no service template reference
              }

              return serviceTemplates.find(
                (x) => x.externalId === serviceTemplateReference?.externalId
              )?.name;
            },
            visible: true,
          },
        ];
        return this.createDynamicTableOptions(systemInfoTableColumns);
      })
    );
  }

  getDynamicFormOptions(
    formOperation: DynamicFormOperationType,
    modelId: string
  ): Observable<DynamicFormOptions<SystemInfo>> {
    const uniqueFieldRestrictions = ['name', 'category'];
    const categories = this.getItemListForTypeaheadControl('category');
    const titleField = 'name';

    return this.getFormModel(formOperation, modelId).pipe(
      map((model: SystemInfo) => {
        const referenceData = this.getFabricationReferenceFields(model);

        const basicReferenceData = referenceData.filter(
          (x) =>
            x.dataType === DataElementType.ServiceTemplate ||
            x.dataType === DataElementType.Specification
        );

        const insulationReferenceData = referenceData.filter(
          (x) => x.dataType === DataElementType.InsulationSpecification
        );
        return {
          model,
          formOperation,
          applyModelAction: this.getFormApplyAction(formOperation),
          isReadOnly: formOperation === 'view',
          uniqueFields: {
            fields: uniqueFieldRestrictions,
            allElements: () => this.options.selectors.selectAll(true),
          },
          groups: [
            {
              label: this.translate.instant(LC.DATATYPES.DEFINITIONS.SERVICES.BASIC),
              expanded: true,
              includeFields: ['abbreviation', 'category', 'fabricationReferences'],
              orderByIncludeFields: true,
              options: {
                formStyle: DynamicFormStyle.SIMPLE,
                hiddenFields: referenceData
                  .filter(
                    (ref) =>
                      ref.dataType !== DataElementType.ServiceTemplate &&
                      ref.dataType !== DataElementType.Specification
                  )
                  .map((ref) => ref.key),
                dropdownTypeaheadFields: [
                  {
                    key: 'category',
                    options: categories,
                  },
                ],
                labelMapping: basicReferenceData.map((ref) => ref.labelField),
                selectFields: [...basicReferenceData.map((ref) => ref.selectField)],
              },
            },
            {
              label: this.translate.instant(LC.DATATYPES.DEFINITIONS.SERVICES.INSULATION_SETTINGS),
              includeFields: [
                'fabricationReferences',
                'insulationType',
                'hangerEngagement',
                'modelBy',
              ],
              orderByIncludeFields: true,
              options: {
                formStyle: DynamicFormStyle.SIMPLE,
                hiddenFields: referenceData
                  .filter((ref) => ref.dataType !== DataElementType.InsulationSpecification)
                  .map((ref) => ref.key),
                labelMapping: insulationReferenceData.map((ref) => ref.labelField),
                selectFields: [
                  ...insulationReferenceData.map((ref) => ref.selectField),
                  {
                    key: 'modelBy',
                    options: FormUtils.mapSelectOptionsFromEnum(this.translate, ModelBy, 'ModelBy'),
                  },
                  {
                    key: 'hangerEngagement',
                    options: FormUtils.mapSelectOptionsFromEnum(
                      this.translate,
                      HangerEngagement,
                      'HangerEngagement'
                    ),
                  },
                  {
                    key: 'insulationType',
                    options: FormUtils.mapSelectOptionsFromEnum(
                      this.translate,
                      InsulationType,
                      'InsulationType'
                    ),
                  },
                ],
              },
            },
          ],
          formStyle: DynamicFormStyle.NONE,
          titleField,
        };
      })
    );
  }

  getDynamicGraphOptions(): DynamicGraphOptions {
    return {
      isUpstreamRefBlocked: true,
      nodeInfoFields: ['name', 'category'],
      isReplaceable: false,
      isEditable: true,
      upstreamReferenceDataTypes: () => [],
      clusterIcon: 'service16',
    };
  }

  fixMissingReferences(fabricationReferences: FabricationReference[]): FabricationReference[] {
    let references: FabricationReference[] = [];
    if (fabricationReferences?.length) {
      references = references.concat(fabricationReferences);
    }

    const serviceTemplate = fabricationReferences?.find(
      (x) => x.dataType === DataElementType.ServiceTemplate
    );
    if (!serviceTemplate) {
      references.push({
        externalId: null,
        dataType: DataElementType.ServiceTemplate,
        referenceType: FabricationReferenceType.Relationship,
      });
    }

    const spec = fabricationReferences?.find((x) => x.dataType === DataElementType.Specification);
    if (!spec) {
      references.push({
        externalId: EnvironmentConstants.FCS_UNASSIGNED_PART_SPEC,
        dataType: DataElementType.Specification,
        referenceType: FabricationReferenceType.Relationship,
      });
    }

    const insulationSpec = fabricationReferences?.find(
      (x) => x.dataType === DataElementType.InsulationSpecification
    );
    if (!insulationSpec) {
      references.push({
        externalId: EnvironmentConstants.FCS_UNASSIGNED_INSULATION_SPEC,
        dataType: DataElementType.InsulationSpecification,
        referenceType: FabricationReferenceType.Relationship,
      });
    }

    return references;
  }

  dataFixes(): void {
    //
  }

  getInvalidDataErrors(model: SystemInfo & InvalidData): string {
    const schema = this.schemaService.getSchemaByDataElementType(DataElementType.Service);
    const errors = this.invalidDataErrorService.parseErrors(model, schema);
    if (errors.length > 1) {
      return this.translate.instant(LC.ERROR_HANDLING.GENERIC.MULTIPLE_ERRORS);
    }

    const error = errors[0];
    if (error?.reason === InvalidDataSchemaErrorReason.ArrayTooShort) {
      // assume no service template
      return this.translate.instant(LC.ERROR_HANDLING.SERVICES.NO_PART_TEMPATE);
    }

    return this.getStandardInvalidDataError(DataElementType.Service, model, schema);
  }

  getIconName(): string {
    return 'service';
  }

  isFixable(): boolean {
    return true;
  }

  requiresBinaryUpgrade(/*dataElement: SystemInfo*/): boolean {
    return false;
  }
}

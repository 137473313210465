import { DataElementType } from '@constants/data-element-types';
import { EnvironmentConstants } from '@constants/environment-constants';
import { PartData } from '@models/fabrication/geometry';
import { Part } from '@models/fabrication/part';
import {
  FabricationConnectorReference,
  FabricationMaterialFinishReference,
  FabricationReferenceType,
} from '@models/forge-content/references';
import { SemVer } from 'semver';

/**
 * The purpose of this class is to be able to upgrade FCS data within the browser.
 */
export class PartUpgraderUtils {
  static CUSTOM_START_VALUE = 1000000;
  static DEPENDENT_VALUE = 1100000;
  static CALCULATED_VALUE = 1100001;

  static upgrade(part: Part, partData: PartData): boolean {
    if (!PartUpgraderUtils.partRequiresInBrowserUpgrade(part)) return false; // can't be done!

    const currentVersion = part.extensionDataVersion;

    part.extensionDataVersion = EnvironmentConstants.FSS_SCHEMA_PART_VERSION;

    this.partAliasUpgrade(part, partData);
    this.materialFinishUpgrade(part, partData);
    this.materialConnectivityUpgrade(part, partData);
    this.partProductNumberUpgrade(part, partData);
    this.partCentreLineUpgrade(part, partData);

    if (!part.productListData) {
      return true;
    }

    this.productEntryOptionsUpgrade(currentVersion, part, partData);
    this.calculationStringUpgrade(currentVersion, part, partData);
    this.weightAndOrderUpgrade(currentVersion, part, partData);

    return true;
  }

  public static partRequiresBinaryUpgrade(part: Part): boolean {
    const compatibleVersion = EnvironmentConstants.FSS_SCHEMA_PART_LAST_COMPATIBLE_VERSION;
    const currentVersion = part.extensionDataVersion;

    const compare = new SemVer(currentVersion).compare(compatibleVersion);

    return compare === -1;
  }

  public static partRequiresInBrowserUpgrade(part: Part): boolean {
    const requiredVersion = EnvironmentConstants.FSS_SCHEMA_PART_VERSION;
    const currentVersion = part.extensionDataVersion;

    const compare = new SemVer(currentVersion).compare(requiredVersion);

    return compare === -1;
  }

  /**
   * Makes sure the dimensions are correct for the part (trust that WA is correct)
   * @param part
   * @param partData
   * @param initialSetup
   */
  public static setupDims(part: Part, partData: PartData, initialSetup?: boolean): boolean {
    let changed = false;

    const calculatedValuesMap = Object.fromEntries(
      partData.productListData.calculatedValues?.map((x) => [x.id, x])
    );

    // some dims might become newly available, so make sure
    // they're added in if they aren't present
    Object.entries(partData.productListData.dimInfos).forEach(
      ([key, partDataDimInfo], infoIndex) => {
        const dimId = Number(key);
        const dimIndex = partData.productListData.entries[0].dimensions.findIndex(
          (x) => x.id === dimId
        );

        // check to see if the info exists
        const info = part.productListData.dimensionInfos.find((x) => x.id === dimId);
        if (!info) {
          // add the data at the same index as it appears from web assembly
          part.productListData.dimensionInfos.splice(infoIndex, 0, {
            calculation: partDataDimInfo.calculation,
            id: dimId,
            parameterType: partDataDimInfo.parameterType,
            sameValue: !partDataDimInfo.isTyped,
          });
          changed = true;
        }

        // check to see if the dim exists in the product entries
        part.productListData.entries.forEach((entry, index) => {
          const dim = entry.dimensions.find((x) => x.id === dimId);

          //Return if the dimension is already present
          if (dim) {
            //Set the calculated value for disabled dimensions
            if (initialSetup && partDataDimInfo.isFixed) {
              dim.value = calculatedValuesMap[entry.id]?.[dimId];
            }
            return;
          }

          const partDataEntry = partData.productListData.entries[index].dimensions[dimIndex];

          let value = partDataEntry.value;
          if (partDataEntry.optionIndex >= 0) {
            value = partDataDimInfo.options[partDataEntry.optionIndex].value;
          } else if (partDataDimInfo.isCalculated && partDataDimInfo.calculation?.length) {
            value = this.CALCULATED_VALUE;
          } else if (partDataDimInfo.isDependent) {
            value = this.DEPENDENT_VALUE;
          }

          entry.dimensions.splice(dimIndex, 0, {
            id: dimId,
            value,
          });
        });
      }
    );

    return changed;
  }

  /**
   * Makes sure the options are correct for the part (trust that WA is correct)
   * @param part
   * @param partData
   */
  public static setupOptions(part: Part, partData: PartData): boolean {
    let changed = false;

    // some options might become newly available, so make sure
    // they're added in if they aren't present
    Object.entries(partData.productListData.optionInfos).forEach(
      ([key, partDataOptionInfo], infoIndex) => {
        const optionId = Number(key);
        const optionIndex = partData.productListData.entries[0].options.findIndex(
          (x) => x.id === optionId
        );

        if (!part.productListData.optionInfos?.length) part.productListData.optionInfos = [];
        // check to see if the info exists
        const info = part.productListData.optionInfos?.find((x) => x.id === optionId);
        if (!info) {
          // add the data at the same index as it appears from web assembly
          part.productListData.optionInfos.splice(infoIndex, 0, {
            id: optionId,
            parameterType: partDataOptionInfo.parameterType,
            sameValue: !partDataOptionInfo.isTyped,
          });
          changed = true;
        }

        // check to see if the option exists in the product entries
        part.productListData.entries.forEach((entry, index) => {
          if (!entry.options?.length) entry.options = [];

          const option = entry.options.find((x) => x.id === optionId);
          if (option) return; // option already exists

          const partDataEntry = partData.productListData.entries[index].options[optionIndex];

          let value = partDataEntry.value;
          if (partDataEntry.optionIndex >= 0) {
            value = partDataOptionInfo.options[partDataEntry.optionIndex].value;
          }

          entry.options.splice(optionIndex, 0, {
            id: optionId,
            value,
          });
        });
      }
    );

    return changed;
  }

  /**
   * Makes sure the dimensions and options are correct for the part (trust that WA is correct)
   * @param part
   * @param partData
   */
  public static setupDimsAndOptions(
    part: Part,
    partData: PartData,
    initialSetup?: boolean
  ): boolean {
    if (!part?.productListData?.entries?.length || !partData?.productListData?.entries?.length)
      return false;

    let changed = false;

    if (this.setupDims(part, partData, initialSetup)) changed = true;
    if (this.setupOptions(part, partData)) changed = true;

    console.log('after setup', part);

    return changed;
  }

  private static materialFinishUpgrade(part: Part, partData: PartData): void {
    // check to see if the part has a material finish reference (should mean 2 material references are present)
    const hasMaterialFinishReference =
      part.fabricationReferences.filter((x) => x.dataType === DataElementType.Material).length ===
      2;
    if (!hasMaterialFinishReference) {
      const finishId = partData.additionalPartMetaData.finishId;
      const materialFinishReference: FabricationMaterialFinishReference = {
        isLocked: partData.additionalPartMetaData.finishLocked,
        externalId:
          finishId === '-1'
            ? EnvironmentConstants.FCS_UNASSIGNED_MATERIAL_FINISH
            : partData.additionalPartMetaData.finishId,
        dataType: DataElementType.Material,
        referenceType: FabricationReferenceType.Relationship,
      };

      part.fabricationReferences.push(materialFinishReference);
    }
  }

  private static materialConnectivityUpgrade(part: Part, partData: PartData): void {
    const couplingConnectors =
      partData.additionalPartMetaData?.connectors?.filter((x) => x.connectivityId?.length) ?? [];

    const partConnectorReferences = part.fabricationReferences.filter(
      (x) => x.dataType === DataElementType.Connector
    ) as FabricationConnectorReference[];

    couplingConnectors.forEach((connector) => {
      const reference = partConnectorReferences.find((x) => x.index === connector.index);
      if (reference && !reference.connectivityId?.length) {
        reference.connectivityId =
          connector.connectivityId === '-1'
            ? EnvironmentConstants.FCS_UNASSIGNED_MATERIAL
            : connector.connectivityId;
      }
    });
  }

  private static partAliasUpgrade(part: Part, partData: PartData): void {
    if (!part.alias && partData.additionalPartMetaData.alias) {
      part.alias = partData.additionalPartMetaData.alias;
    }
  }

  private static partCentreLineUpgrade(part: Part, partData: PartData): void {
    if (
      typeof part.isCentrelineInput === 'undefined' &&
      typeof partData.additionalPartMetaData.centreLineInput !== 'undefined'
    ) {
      part.isCentrelineInput = partData.additionalPartMetaData.centreLineInput;
    }
  }

  private static partProductNumberUpgrade(part: Part, partData: PartData): void {
    if (!part.productNumber && partData.additionalPartMetaData.product) {
      part.productNumber = partData.additionalPartMetaData.product;
    }
  }

  private static calculationStringUpgrade(
    currentVersion: string,
    part: Part,
    partData: PartData
  ): void {
    if (currentVersion === EnvironmentConstants.FSS_SCHEMA_PART_LAST_COMPATIBLE_VERSION) {
      // calculation strings are missing from FCS in v3.1.2
      const calcDims = Object.keys(partData.productListData?.dimInfos)?.filter(
        (x) => !!partData.productListData?.dimInfos[x]?.calculation
      );
      calcDims?.forEach((calcDim) => {
        const partDim = part.productListData?.dimensionInfos?.find(
          (x) => x.id.toString() === calcDim
        );
        if (partDim) partDim.calculation = partData.productListData?.dimInfos[calcDim].calculation;
      });
    }
  }

  private static productEntryOptionsUpgrade(
    currentVersion: string,
    part: Part,
    partData: PartData
  ): void {
    const currentSemVer = new SemVer(currentVersion);
    const requiredSemVer = new SemVer('3.4.0');
    if (currentSemVer >= requiredSemVer) return;

    if (!partData.productListData?.optionInfos) return;

    part.productListData.optionInfos = Object.keys(partData.productListData.optionInfos).map(
      (key) => {
        const info = partData.productListData.optionInfos[key];
        return {
          id: Number(key),
          parameterType: info.parameterType,
          sameValue: !info.isTyped,
        };
      }
    );

    part.productListData.entries.forEach((entry) => {
      const partDataOptions = partData.productListData.entries.find(
        (x) => x.id === entry.id
      )?.options;
      if (!partDataOptions) return;

      entry.options = partDataOptions.map((x) => {
        return {
          // todo - the IDs of the option may conflict with those of the dimensions
          id: x.id, // `opt-${x.id}`,
          value:
            x.optionIndex >= 0
              ? partData.productListData.optionInfos[x.id].options[x.optionIndex].value
              : x.value,
        };
      });
    });
  }

  private static weightAndOrderUpgrade(currentVersion: string, part: Part, partData: PartData) {
    const currentSemVer = new SemVer(currentVersion);
    const requiredSemVer = new SemVer('3.4.0');
    if (currentSemVer >= requiredSemVer) return;

    const partEntries = part.productListData?.entries ?? [];
    const partDataEntries = partData.productListData?.entries ?? [];
    const partEntriesMap = new Map(partEntries.map((x) => [x.id, x]));

    partDataEntries.forEach((entry) => {
      const partEntry = partEntriesMap.get(entry.id);

      if (partEntry) {
        partEntry.weight = entry.weight;
        partEntry.orderNumber = entry.order;
      }
    });
  }
}

export enum Theme {
  Default = 'Default',
  LightGrey = 'LightGrey',
  DarkGrey = 'DarkGrey',
  DarkBlue = 'DarkBlue',
}

export interface Metadata {
  colorSchemeName: string;
  colorSchemeId: string;
  className: string;
  densityId: string;
  densityName: string;
  name: string;
  fileName: string;
  id: string;
}

export interface ResolvedRoles {
  [key: string]: string;
}

export interface ThemeData {
  metadata: Metadata;
  resolvedRoles: ResolvedRoles;
}

import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppUpdateService } from '@services/app-updates/app-update.service';
import { ActivatedRouteSnapshot, CanMatchFn, UrlSegment } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { map, switchMap, take } from 'rxjs/operators';
import { FeatureFlagService } from '@services/feature-flag.service';

/**
 * Entry point Guard into application
 * will check for valid auth session and any updates to the application
 * from the Service Worker (in that order)
 * @export
 * @class AppEntryGuard
 */
@Injectable({
  providedIn: 'root',
})
class AppEntryGuardService {
  constructor(
    private appUpdateService: AppUpdateService,
    private authService: AuthService,
    private featureFlagService: FeatureFlagService
  ) {}

  canMatch = (route: ActivatedRouteSnapshot, urlSegments: UrlSegment[]): Observable<boolean> =>
    this.authService.isValidAuthSession().pipe(
      switchMap((isValid: boolean) => {
        if (isValid && this.appUpdateService.updateReady) {
          const targetUrl = urlSegments.join('/');

          return this.appUpdateService.activateVersionUpdate(targetUrl);
        }

        return of(isValid);
      }),
      switchMap((isValid: boolean) => {
        if (!isValid) {
          return of(false);
        }

        if (!this.authService.isInitialLoginFinished) {
          return this.authService.processNewLogin();
        }
        return of(true);
      }),
      switchMap((isValid: boolean) => {
        if (!isValid) {
          return of(false);
        }
        return this.featureFlagService.initialise().pipe(
          // don't block the app if the feature flag service returns false
          map(() => isValid)
        );
      }),
      take(1)
    );
}

export const AppEntryGuard: CanMatchFn = (
  route: ActivatedRouteSnapshot,
  urlSegments: UrlSegment[]
): Observable<boolean> => {
  return inject(AppEntryGuardService).canMatch(route, urlSegments);
};

import {
  BoxInfoMargin,
  DEFAULT_PAGE_SIZE,
  FIXED_NORESULT_NODE_DATA,
  NODE_CONTENT_ROW_HEIGHT,
  NODE_SPACE_X,
  PaginationAction,
  RectSizes,
  SortOptions,
} from './d3-graph.constants';
import { v4 as uuidv4 } from 'uuid';
import { HierarchyGraphNode, PaginationOptions } from './d3-graph.types';
import { FabricationConnectorReference } from '@models/forge-content/references';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';

/**
 * generate d3 hierarchy node hash like id based on data and parent
 *
 * @param {HierarchyGraphNode} node
 * @returns
 */
export const generateNodeId = (node: HierarchyGraphNode) => {
  let significantInfo = [
    node.data.dataType,
    node.data.id,
    node.data.internalRelationshipDataType,
    node.data.internalRelationshipId,
    node.depth,
    (node?.data?.referenceMetaData as FabricationConnectorReference)?.index,
    ...Object.values(node.data.info ?? {}),
  ];

  significantInfo = significantInfo.filter(Boolean);
  const hashCode = Array.from(significantInfo.join(',')).reduce(
    (s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0,
    0
  );
  return Math.abs(hashCode).toString();
};

export const getNodeHeight = (node: HierarchyGraphNode) => {
  const minNodeHeight = RectSizes.HEIGHT;
  const contentSize = Object.keys(node.data.info ?? {}).length;
  const dynamicHeightBasedOnContent = BoxInfoMargin.Y * 2 + contentSize * NODE_CONTENT_ROW_HEIGHT;

  return Math.max(minNodeHeight, dynamicHeightBasedOnContent);
};

export const getNextOrPrev = (totalPages: number, page: number, action: PaginationAction) => {
  page = action === PaginationAction.NEXT ? page + 1 : page - 1;
  page = page <= 0 ? 1 : page > totalPages ? totalPages : page;
  return page;
};

export const getPageString = (page: number, totalPages: number, translate: TranslateService) => {
  return `${page} ${translate.instant(LC.GRAPH.PAGINATION.FROM)} ${totalPages}`;
};

export const defaultPaginationOptions: PaginationOptions = {
  sort: SortOptions.ASC,
  page: 1,
  limit: DEFAULT_PAGE_SIZE,
  search: '',
};

export const generateFixedNoResultNodeData = (search: string, parent, dataType: string) => ({
  ...FIXED_NORESULT_NODE_DATA,
  data: {
    id: `no-results-${uuidv4()}`,
    hasNoResult: true,
    value: search,
    dataType,
  },
  depth: parent.depth + 1,
  y: parent.y + (parent.y < 0 ? -290 : +290),
  y0: parent.y0 + (parent.y0 < 0 ? -290 : +290),
  x: parent.x,
  x0: parent.x0,
  parent,
});

export const generateElbowPath = (d) => {
  const spaceFactor = d.target.y < 0 ? 1 : -1;
  return (
    'M' +
    d.source.y +
    ',' +
    d.source.x +
    'H' +
    (d.target.y + (NODE_SPACE_X / 2) * spaceFactor) +
    'V' +
    d.target.x +
    'H' +
    d.target.y
  );
};

export const generateHtmlTopControls = (translate: TranslateService) => {
  return `
    <ul class="group-node-filters">
      <li class="search-nodes" data-test-element="pagination-search-bar-container">
        <div></div>
      </li>
      <li class="filter-nodes" style="display:none;">
        <div class="divider"></div>
        <img tabindex="0" src="assets/hig/16/filter.svg" class="filter-icon" alt="${translate.instant(
          LC.GRAPH.TOP_CONTROLS.FILTER
        )}">
      </li>
      <li class="sort-nodes" style="display:none;">
        <div class="divider"></div>
        <img tabindex="0" src="assets/hig/16/sort.svg" class="sort-icon" alt="${translate.instant(
          LC.GRAPH.TOP_CONTROLS.SORT
        )}">
      </li>
    </ul>
  `;
};

export const generateHtmlBottomControls = ({ page, totalPages }, translate: TranslateService) => {
  return `
      <ul class="group-node-pagination">
        <li class="prev-nodes-page ${
          page === 1 ? 'disabled' : ''
        }" data-test-element="pagination-prev">
          <button class="page-control" type="button" aria-label="${translate.instant(
            LC.GRAPH.PAGINATION.PREVIOUS_PAGE
          )}">
            <span>
              <img src="assets/hig/16/caret-left.svg" class="previous-page-icon" alt="${translate.instant(
                LC.GRAPH.PAGINATION.PREVIOUS_PAGE
              )}">
            </span>
          </button>
        </li>
        <li class="pagination-control" data-test-element="pagination-counter">
          <span>${getPageString(page, totalPages, translate)}</span>
        </li>
        <li class="next-nodes-page ${
          page === totalPages ? 'disabled' : ''
        }" data-test-element="pagination-next">
          <button class="page-control" type="button" aria-label="${translate.instant(
            LC.GRAPH.PAGINATION.NEXT_PAGE
          )}" ${page === totalPages ? 'disabled' : ''}>
            <span>
              <img src="assets/hig/16/caret-right.svg" class="next-page-icon" alt="${translate.instant(
                LC.GRAPH.PAGINATION.NEXT_PAGE
              )}">
            </span>
          </button>
        </li>
      </ul>
    `;
};

import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  ServiceTemplatePalette,
  ServiceTemplateInfo,
} from '@models/fabrication/service-template-info';
import { v4 as uuidv4 } from 'uuid';

import { elementIdDictionary } from '@constants/element-id-dictionary';
import { DynamicTableOptions } from '@models/dynamic-table/dynamic-table-options';
import { BehaviorSubject, Subscription, Subject } from 'rxjs';
import { DynamicFormBaseCustomComponent } from '@shared/components/dynamic-form/dynamic-form-base-custom-component';
import { filter } from 'rxjs/operators';
import { DynamicFormCustomUpdateEvent } from '@models/dynamic-form/dynamic-form-properties';
import { InlineEditType } from '@models/inline-edit/inline-edit.options';
import { MapUtils } from '@utils/map-utils';
import { SchemaService } from '@services/schema.service';
import { ValidatorService, InlineValidatorFunction } from '@services/validator.service';
import {
  ToolBarButton,
  ToolBarButtons,
  ToolBarOptions,
  ToolBarButtonType,
} from '@models/tool-bar/tool-bar-options';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers';
import { DataElementType } from '@constants/data-element-types';
import { JSONSchema7 } from 'json-schema';
import { EnvironmentConstants } from '@constants/environment-constants';
import { DynamicTableChangeService } from '@services/data-services';

@Component({
  selector: 'fab-service-template-palettes',
  template: ` <fab-table-data [options]="tableOptions"></fab-table-data> `,
})
export class ServiceTemplatePalettesComponent
  extends DynamicFormBaseCustomComponent<ServiceTemplatePalette[]>
  implements OnInit, OnDestroy
{
  tableOptions: DynamicTableOptions<ServiceTemplatePalette> = null;
  tableChangeSubject: Subject<ServiceTemplatePalette[]> = new Subject<ServiceTemplatePalette[]>();
  tableChangeSubscription: Subscription;
  tableDataSource: BehaviorSubject<ServiceTemplatePalette[]>;
  disableMoveUp = false;
  disableMoveDown = false;
  idLookup = elementIdDictionary.partTemplateEdit;
  customModelChangesSubscription: Subscription;
  serviceTemplate: ServiceTemplateInfo;
  hasSingleSelection: boolean;
  hasMultipleSelection: boolean;

  constructor(
    // private changeRef: ChangeDetectorRef,
    private schemaService: SchemaService,
    private validatorService: ValidatorService,
    private translate: TranslateService,
    private tableChangeService: DynamicTableChangeService<ServiceTemplatePalette>,
    store$: Store<FDMState>
  ) {
    super(store$);
  }

  ngOnInit() {
    this.getModelUpdates();
  }

  ngOnDestroy() {
    this.tableChangeSubscription?.unsubscribe();
    this.customModelChangesSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  getValidator(): InlineValidatorFunction {
    const parentSchema = this.schemaService.getSchemaByDataElementType(
      DataElementType.ServiceTemplate
    );
    const schema = parentSchema.definitions[
      EnvironmentConstants.FSS_SUB_SCHEMA_PALETTE
    ] as JSONSchema7;
    const schemaValidator = this.validatorService.getInlineEditSchemaValidator(schema.$id);
    const uniqueValidator = this.validatorService.getInlineEditUniqueValidator(
      this.serviceTemplate.palettes,
      'name'
    );

    return this.validatorService.mergeInlineEditValidators(schemaValidator, uniqueValidator);
  }

  getModelUpdates() {
    // only need initial model update
    // palettes component is not affected by changes to parts or sizeRestrictions
    // no need to re-bind data from standard form field changes
    this.customModelChangesSubscription = this.formModelUpdater
      .pipe(
        filter(
          (changeEvent: DynamicFormCustomUpdateEvent) =>
            changeEvent.isFirstUpdate ||
            (changeEvent.subscriptionToken !== this.subscriptionToken &&
              !changeEvent.triggeredByStandardFormChange)
        )
      )
      .subscribe((changeEvent: DynamicFormCustomUpdateEvent) => {
        const model = changeEvent.data;
        this.serviceTemplate = model;

        // assume model has been reverted
        if (this.tableOptions && changeEvent.isFirstUpdate) {
          this.tableDataSource.next(this.serviceTemplate.palettes);
        }

        if (!this.tableOptions) {
          this.createTableDataOptions();
          this.reportErrors();

          // update the data when we make inline edits
          this.tableChangeSubscription = this.tableChangeSubject.subscribe(
            (rows: ServiceTemplatePalette[]) => {
              this.serviceTemplate.palettes = rows;
              this.refreshData();
            }
          );
        }
      });
  }

  createTableDataOptions() {
    if (!this.tableOptions) {
      this.tableDataSource = new BehaviorSubject<ServiceTemplatePalette[]>(
        this.serviceTemplate.palettes
      );

      this.tableOptions = {
        id: uuidv4(),
        data: this.tableDataSource,
        controlIdPrefix: 'part-template-palette-list',
        rowSelection: { visible: true },
        isReadOnly: this.isReadOnly,
        maintainSelectionOnUpdate: true,
        subscribeToTableChanges: this.tableChangeSubject,
        errorReporter: this.tableErrorReporterSubject,
        forceFullValidationOnEachChange: true,
        columns: [
          {
            field: 'name',
            header: this.translate.instant(LC.DATATYPES.DEFINITIONS.SERVICE_TEMPLATES.NAME),
            inlineEdit: {
              disabled: this.isReadOnly,
              fieldType: InlineEditType.text,
              validator: () => this.getValidator(),
            },
            disableSort: true,
          },
        ],
        toolbarOptions: this.createToolBarOptions(),
        enableMultiSelect: true,
      };
    }
  }

  createToolBarOptions(): ToolBarOptions<any> {
    return {
      permanentToolBarButtons: {
        visible: true,
        buttons: [
          {
            type: ToolBarButtonType.ADD,
            action: () => {
              this.addPalette();
              this.tableChangeService.submitForceFullValidation();
            },
            label: this.translate.instant(LC.TOOLBAR.LABEL.ADD),
            tooltipTitle: this.translate.instant(LC.TOOLTIP.ADD_PART_TEMPLATE_PALETTE),
          },
        ] as ToolBarButton<any>[],
      } as ToolBarButtons<any>,
      contextToolBarButtons: {
        buttons: [
          {
            type: ToolBarButtonType.UP,
            action: (palette: ServiceTemplatePalette) => this.movePaletteUp(palette),
            disableCondition: 'min',
            isDisabled: false,
            label: this.translate.instant(LC.TOOLBAR.LABEL.MOVE_UP),
            tooltipTitle: this.translate.instant(LC.TOOLTIP.MOVE_UP),
          },
          {
            type: ToolBarButtonType.DOWN,
            action: (palette: ServiceTemplatePalette) => this.movePaletteDown(palette),
            disableCondition: 'max',
            isDisabled: false,
            label: this.translate.instant(LC.TOOLBAR.LABEL.MOVE_DOWN),
            tooltipTitle: this.translate.instant(LC.TOOLTIP.MOVE_DOWN),
          },
          {
            type: ToolBarButtonType.DELETE,
            action: (palette: ServiceTemplatePalette) => {
              this.removePalettes([palette]);
              this.tableChangeService.submitForceFullValidation();
            },
            disableCondition: 'selectedAll',
            isDisabled: false,
            label: this.translate.instant(LC.TOOLBAR.LABEL.DELETE),
            tooltipTitle: this.translate.instant(LC.TOOLTIP.DELETE),
          },
        ] as ToolBarButton<any>[],
      } as ToolBarButtons<any>,
      multipleContextToolBarButtons: {
        buttons: [
          {
            type: ToolBarButtonType.DELETE,
            action: (palettes: any) => {
              const palettesToDelete = palettes as ServiceTemplatePalette[];
              this.removePalettes(palettesToDelete);
              this.tableChangeService.submitForceFullValidation();
            },
            disableCondition: 'selectedAll',
            label: this.translate.instant(LC.TOOLBAR.LABEL.DELETE),
            tooltipTitle: this.translate.instant(LC.TOOLTIP.DELETE),
          },
        ] as ToolBarButton<any>[],
      } as ToolBarButtons<any>,
      isReadOnly: this.isReadOnly,
      controlIdPrefix: 'part-template-palette-list',
    } as ToolBarOptions<any>;
  }

  addPalette() {
    const addPalette = new ServiceTemplatePalette();
    // assign new id
    addPalette.id = uuidv4();
    addPalette.name = 'Untitled';
    this.serviceTemplate.palettes.push(addPalette);
    this.refreshData();
    this.tableDataSource.next(this.serviceTemplate.palettes);
  }

  movePaletteUp(palette: ServiceTemplatePalette) {
    const groupIndex = this.serviceTemplate.palettes.findIndex((x) => x.id === palette.id);

    if (groupIndex !== 0) {
      this.serviceTemplate.palettes.splice(groupIndex, 1);
      this.serviceTemplate.palettes.splice(groupIndex - 1, 0, palette);
      this.refreshData();
      this.tableDataSource.next(this.serviceTemplate.palettes);
    }
  }

  movePaletteDown = (palette: ServiceTemplatePalette) => {
    const groupIndex = this.serviceTemplate.palettes.findIndex((x) => x.id === palette.id);

    if (groupIndex !== this.serviceTemplate.palettes.length - 1) {
      this.serviceTemplate.palettes.splice(groupIndex, 1);
      this.serviceTemplate.palettes.splice(groupIndex + 1, 0, palette);
      this.refreshData();
      this.tableDataSource.next(this.serviceTemplate.palettes);
    }
  };

  removePalettes(palettes: ServiceTemplatePalette[]) {
    this.serviceTemplate.palettes = this.serviceTemplate.palettes.filter(
      (p) => !palettes.includes(p)
    );
    this.refreshData();
    this.tableDataSource.next(this.serviceTemplate.palettes);
  }

  refreshData() {
    this.updateSource(this.serviceTemplate.palettes);
    this.tableOptions.columns[0].inlineEdit.uniqueColumnData = MapUtils.getMappedUniqueList(
      this.serviceTemplate.palettes
    );
  }
}

import { Injectable } from '@angular/core';
import { Observable, of, forkJoin, defer, BehaviorSubject, from } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { UserData, UserAnalytics } from '@models/auth/user-data';
import { LoggingService } from './logging.service';
import { EnvironmentService } from './environment.service';
import { Router } from '@angular/router';
import { KeyData } from '@models/auth/key-data';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { TranslateService } from '@ngx-translate/core';
import { UserSettingsService } from '@services/user-settings.service';
import { BroadcastChannel } from 'broadcast-channel';
import { ConfirmationService } from './confirmation/confirmation.service';
import { ConfirmationResult } from '@models/confirmation/confirmation';
import { AxiosError, AxiosResponse } from 'axios';
import { ResilientHttpClient, IResilientHttpOptions } from '@adsk/resilient-axios-client';
import { ResiliencyConstants as rc } from '@constants/resiliency-constants';
import { AppConstants } from '@constants/app-constants';
import { AuthClient, RedirectLoginResult } from '@adsk-testing/identity-web-sdk';
import { NavigationConstants } from '@constants/navigation-constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _httpClient: ResilientHttpClient = null;
  private _authClient: AuthClient = null;
  private _currentUserData: UserData = null;
  private _currentKeyData: KeyData = null;
  private _currentAnalyticsId: UserAnalytics = null;
  private _initialLoginFinished = false;
  private _logoutSyncChannel = new BroadcastChannel<boolean>('logout-sync-channel');
  private _hasKeyDataSubject = new BehaviorSubject<boolean>(false);
  private _hasUserDataSubject = new BehaviorSubject<boolean>(false);
  private _apploginComplete = new BehaviorSubject<boolean>(false);

  // constructor
  constructor(
    private envService: EnvironmentService,
    private loggingService: LoggingService,
    private router: Router,
    private translate: TranslateService,
    private userSettingsService: UserSettingsService,
    private confirmationService: ConfirmationService
  ) {
    this.setupClient();
    this.setupAuthClient();
    this.setupLogoutSyncChannels();
  }

  /**
   * Synchorize logout events across browser tabs
   * @returns void
   */
  private setupLogoutSyncChannels = (): void => {
    this._logoutSyncChannel.onmessage = () => {
      this._currentKeyData = null;
      this.warnUserCurrentSessionIsLoggedOut().subscribe(() => {
        this.reloadApp();
      });
    };
  };

  /**
   * The user data (oxygenId, email etc) for the currently logged in user
   * @returns UserData
   */
  public get currentUserData(): UserData {
    return this._currentUserData;
  }

  public get hasUserData(): Observable<boolean> {
    return this._hasUserDataSubject.asObservable();
  }

  /**
   * The key data required for the client to run extrenal operations such as feature flags and analytics
   * @returns KeyData
   */
  public get currentKeyData(): KeyData {
    return this._currentKeyData;
  }

  /**
   * The flag that indicates if the initial authentication process has completed (after first load)
   * @returns boolean
   */
  public get isInitialLoginFinished(): boolean {
    return this._initialLoginFinished;
  }

  public get hasKeyData(): Observable<boolean> {
    return this._hasKeyDataSubject.asObservable();
  }

  /**
   * output once oxygen user login complete
   * @returns Observable<boolean>
   */
  public get apploginComplete(): Observable<boolean> {
    return this._apploginComplete.asObservable();
  }

  /**
   * Logs out the current user, navigates to the LogoutComponent and remove sessionFDMStore
   * instead of a hard browser refresh so that any guards protecting un-saved data
   * can be run
   */
  public logout() {
    this._currentAnalyticsId = null;
    this.router.navigate(['/logout']);
  }

  /**
   * Get initial user data and key data from the server
   * @returns Observable
   */
  public processNewLogin(): Observable<boolean> {
    this._apploginComplete.next(true);
    return this.getAccessToken().pipe(
      switchMap(() =>
        forkJoin([this.getUserProfile(), this.getSetupData(), this.getOxygenAnalyticsId()])
      ),
      tap(() => (this._initialLoginFinished = true)),
      map(() => true),
      catchError((err) => {
        this.logAuthError(err, true);
        this.router.navigate([NavigationConstants.GENERIC_ERROR]);
        return of(false);
      }),
      take(1)
    );
  }

  /**
   * Get the initial access token from the server (this method is called on app load)
   * If the user is already logged in, get the access token silently (run the auth process in an iframe)
   * else redirect to login page
   * @returns Observable
   */
  public getInitialAccessToken = (): Observable<string> => {
    const startTime = performance.now();

    const currentLocation = window.location.href;
    const parsedUrl = new URL(currentLocation);
    const code = parsedUrl.searchParams.get('code');

    // if code param is present in the URL, the user has been redirected back from the auth server
    if (code) {
      return this.handleRedirectCallback().pipe(
        map((result: RedirectLoginResult) => {
          parsedUrl.search = ''; // Clear the query parameters
          window.location.replace(parsedUrl.toString());
          return result.accessToken;
        }),
        catchError((err) => {
          // We cannot call logAuthError here because the translation service might be not initialized yet,
          // translations are asynchronously fetched from the server
          this.translate.get(LC.NOTIFICATIONS.MSG_AUTH_ERROR_RESPONSE).subscribe(() => {
            this.logAuthError(err, true);
          });
          this.router.navigate([NavigationConstants.GENERIC_ERROR]);
          return of('');
        }),
        take(1)
      );
    }
    // if the user is already logged in, get the access token silently (run the auth flow in the iframe)
    else {
      return this.isAuthenticated().pipe(
        switchMap((isAuthenticated: boolean) => {
          if (isAuthenticated) {
            return this.getAccessTokenSilently().pipe(
              tap(() => {
                const endTime = performance.now();
                console.log(`Authorization process takes: ${endTime - startTime} milliseconds`);
              }),
              catchError((e) => {
                return this.loginWithRedirect().pipe(
                  map(() => ''),
                  take(1)
                );
              }),
              take(1)
            );
          } else {
            return this.loginWithRedirect().pipe(
              map(() => ''),
              take(1)
            );
          }
        }),
        take(1)
      );
    }
  };

  /**
   * Invoke the auth SDK client to get the access token.
   * Token validation/refreshing is handled by the SDK.
   * @returns Observable
   */
  public getAccessToken = (): Observable<string> => {
    return this.getAccessTokenSilently();
  };

  /**
   * Returns true if there’s valid session, otherwise returns false.
   * @returns Observable<boolean>
   */
  private isAuthenticated = (): Observable<boolean> => {
    return from(this._authClient.isAuthenticated()).pipe(take(1));
  };

  /**
   * Run the authentication flow in an iframe to get the access token.
   * If the token already exists, it will be returned from internal cache.
   * @returns Observable<string>
   */
  private getAccessTokenSilently = (): Observable<string> => {
    return from(this._authClient.getAccessTokenSilently()).pipe(take(1));
  };

  /**
   * Performs a redirect to authentication service (external domain - SSO)
   * @returns Observable<void>
   */
  private loginWithRedirect = (): Observable<void> => {
    return from(this._authClient.loginWithRedirect()).pipe(take(1));
  };

  /**
   * After successful login, authorization server will redirect the user to provided redirect_uri.
   * It also adds authorization code and state to the query parameter in URL
   * call handleRedirectCallback to fetch the token using the code received in the query parameter.
   * @returns Observable<RedirectLoginResult>
   */
  private handleRedirectCallback = (): Observable<RedirectLoginResult> => {
    return from(this._authClient.handleRedirectCallback()).pipe(take(1));
  };

  /**
   * Checks for a valid auth token
   * @returns Observable
   */
  public isValidAuthSession = (): Observable<boolean> => {
    if (this._initialLoginFinished) {
      return this.getAccessToken().pipe(
        map((token: string) => !!token),
        take(1)
      );
    } else {
      return this.getInitialAccessToken().pipe(
        map((token: string) => !!token),
        take(1)
      );
    }
  };

  /**
   * Reload the application
   */
  public reloadApp(targetUrl?: string) {
    if (targetUrl) {
      window.location.replace(targetUrl);
    } else {
      window.location.reload();
    }
  }

  public navigateToSignOutWindow() {
    this._logoutSyncChannel.postMessage(true);
    this._authClient.logout({ post_logout_redirect_uri: `${window.location.origin}/data` });
  }

  /**
   * Get auth and key data from server
   * @returns Observable
   */
  private getSetupData(): Observable<boolean> {
    return this.getAccessToken().pipe(
      switchMap((token: string) =>
        defer(() =>
          this._httpClient.get<KeyData>('/login/setup', {
            headers: {
              'ngsw-bypass': 'true',
              Authorization: `Bearer ${token}`,
            },
          })
        )
      ),
      map((response: AxiosResponse<KeyData>) => {
        this._currentKeyData = response.data;
        this._hasKeyDataSubject.next(true);
        return true;
      })
    );
  }

  /**
   * Gets the currently logged in user's profile
   * e.g. first/last name, email, oxygenId for displaying in UI
   * and for analytic collection
   * @returns Observable
   */
  private getUserProfile(): Observable<boolean> {
    return this.getAccessToken().pipe(
      switchMap((token: string) =>
        defer(() =>
          this._httpClient.get<UserData>(this.envService.environment.accounts.userProfileUrl, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        )
      ),
      tap((response: AxiosResponse<UserData>) => {
        this._currentUserData = response.data;
        this._hasUserDataSubject.next(true);
        // Initialize user settings
        this.userSettingsService.initializeUserSettings(this._currentUserData.userId);
      }),
      map(() => true)
    );
  }

  /**
   * Gets the current user's oxygen Id as a hashed string
   * API provided by oxygen team and is safe to use when a user Id is
   * required for storage e.g. feature flags
   * @returns Observable
   */
  public getOxygenAnalyticsId(): Observable<UserAnalytics> {
    if (this._currentAnalyticsId) return of(this._currentAnalyticsId);

    return this.getAccessToken().pipe(
      switchMap((token: string) =>
        defer(() =>
          this._httpClient.get<UserAnalytics>(
            this.envService.environment.accounts.oxygenAnalyticsIdUrl,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
        )
      ),
      map((response: AxiosResponse<UserAnalytics>) => {
        this._currentAnalyticsId = response.data;
        return this._currentAnalyticsId;
      }),
      catchError((err) => {
        this.loggingService.logError(err);
        return of(null);
      })
    );
  }

  private logAuthError(err: any, notify: boolean) {
    this.loggingService.logError(
      err,
      notify,
      this.translate.instant(LC.NOTIFICATIONS.MSG_AUTH_ERROR_RESPONSE)
    );
  }

  private warnUserCurrentSessionIsLoggedOut = (): Observable<boolean> => {
    return this.confirmationService
      .requestDisplayConfirmationDialog({
        title: this.translate.instant(LC.NOTIFICATIONS.SESSION_LOGGED_OUT.TITLE),
        message: this.translate.instant(LC.NOTIFICATIONS.SESSION_LOGGED_OUT.MESSAGE, {
          appName: AppConstants.APP_NAME,
        }),
        hideCancelButton: true,
      })
      .pipe(
        take(1),
        map((result: ConfirmationResult) => result === ConfirmationResult.OK)
      );
  };

  /**
   * Setup individual http client to avoid
   * circular refs with the main resilient http service
   */
  private setupClient(): void {
    const resilientHttpOptions: IResilientHttpOptions = {
      timeout: 60000,
      baseURL: '',
      logError: (error: AxiosError) => this.loggingService.logError(error),
      resilienceOptions: {
        retries: rc.DEFAULT_RETRY_COUNT,
      },
    };

    this._httpClient = new ResilientHttpClient(resilientHttpOptions);
  }

  /**
   * Setup the AuthClient from the identity-web-sdk
   * @returns void
   */
  private setupAuthClient(): void {
    this._authClient = new AuthClient({
      client_id: this.envService.environment.clientId,
      redirect_uri: `${window.location.origin}/data`,
      redirect_uri_iframe: `${window.location.origin}/oauth.html`,
      env: this.envService.environment.authEnvironment,
      cacheLocation: 'memory',
      useRefreshTokens: true,
      scope: 'openid data:write data:create data:read data:search user:read',
    });
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DynamicFormChangeService {
  private _invalidComponentsSubject: BehaviorSubject<Set<string>> = new BehaviorSubject<
    Set<string>
  >(new Set());

  //Saves the list of components that are currently being edited
  private _activeEditedComponentsSubject: BehaviorSubject<Set<string>> = new BehaviorSubject<
    Set<string>
  >(new Set());

  public updateInvalidComponent(component: string, invalid: boolean): void {
    const invalidComponents = this._invalidComponentsSubject.value;
    invalid ? invalidComponents.add(component) : invalidComponents.delete(component);
    this._invalidComponentsSubject.next(invalidComponents);
  }

  public resetInvalidComponents(initialValue?: Set<string>): void {
    const invalidComponents = initialValue ?? new Set();
    this._invalidComponentsSubject.next(invalidComponents);
  }

  public listenToHasInvalidComponents(): Observable<boolean> {
    return this._invalidComponentsSubject.pipe(map((value) => value.size > 0));
  }

  public getCurrentInvalidComponents(): Set<string> {
    return new Set(this._invalidComponentsSubject.value);
  }

  public updateActiveEditedComponent(component: string, edited: boolean): void {
    const editedComponents = this._activeEditedComponentsSubject.value;
    edited ? editedComponents.add(component) : editedComponents.delete(component);
    this._activeEditedComponentsSubject.next(editedComponents);
  }

  public clearActiveEditedComponents(): void {
    const editedComponents = this._activeEditedComponentsSubject.value;
    editedComponents.clear();
    this._activeEditedComponentsSubject.next(editedComponents);
  }

  public waitTillNoActiveEditedComponents(): Promise<void> {
    return new Promise((resolve) => {
      if (this._activeEditedComponentsSubject.value.size === 0) {
        resolve();
        return;
      }
      const subscription = this._activeEditedComponentsSubject.subscribe((value) => {
        if (value?.size === 0) {
          subscription.unsubscribe();
          resolve();
        }
      });
    });
  }
}

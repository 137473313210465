import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DynamicFormBaseCustomComponent } from '@shared/components/dynamic-form/dynamic-form-base-custom-component';
import { DynamicFormSelectType } from '@models/dynamic-form/dynamic-form-properties';
import { DataElementType } from '@constants/data-element-types';
import { FabricationReferenceType, FabricationReference } from '@models/forge-content/references';
import { Observable, of, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers';
import { catchError, map, switchMap, take, tap, takeUntil } from 'rxjs/operators';
import { LoggingService } from '@services/logging.service';
import { selectExtraDataElementInfoById } from '@store/selectors/extra-data-element-info.selectors';
import { DataElementTypeUtils } from '@utils/data-element-type-utils';
import { FormControl } from '@angular/forms';

interface GroupedItems {
  group: string;
  items: DynamicFormSelectType[];
}

@Component({
  selector: 'fab-multi-reference-select',
  templateUrl: './multi-reference-select.component.html',
  styleUrls: ['./multi-reference-select.component.scss'],
})
export class MultiReferenceSelectComponent
  extends DynamicFormBaseCustomComponent<FabricationReference[]>
  implements OnInit
{
  constructor(
    private translateService: TranslateService,
    store$: Store<FDMState>,
    private changeDetector: ChangeDetectorRef,
    private loggingService: LoggingService
  ) {
    super(store$);
  }

  groupedItems: GroupedItems[] = [];
  groupedItemsFiltered: GroupedItems[] = [];
  items: DynamicFormSelectType[];
  selectedItems: DynamicFormSelectType[] = [];
  dataType: DataElementType;

  // full list of references on the model (may include other data types or reference types)
  private originalReferences: FabricationReference[] = [];
  public groupedItemsFilterCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();

  ngOnInit() {
    this.setupData().pipe(take(1)).subscribe();

    this.groupedItemsFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterGroupedItemsMulti();
    });
  }

  get selectedText(): string {
    const selectedText = this.translateService.instant(LC.MULTI_SELECT.SELECTED);
    return `${this.selectedItems.length} ${selectedText}`;
  }

  get searchText(): string {
    return this.translateService.instant(LC.MULTI_SELECT.SEARCH);
  }

  get noMatchesText(): string {
    return this.translateService.instant(LC.MULTI_SELECT.NO_MATCHES);
  }

  openedChange(open: boolean): void {
    if (open) {
      return;
    }
    // remove all refs of our data type
    const filteredRefs = this.originalReferences.filter((x) => x.dataType !== this.dataType);

    const selectedReferences = this.selectedItems.map((x) => {
      return {
        dataType: this.dataType,
        referenceType: FabricationReferenceType.Reference,
        externalId: x.value,
      } as FabricationReference;
    });

    const newReferences = filteredRefs
      .concat(selectedReferences)
      .sort((a, b) => a.externalId.localeCompare(b.externalId));

    this.model.fabricationReferences = newReferences;
    this.updateSource(newReferences);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private setupData(): Observable<boolean> {
    return (this.props.multiSelectOptions.options as Observable<DynamicFormSelectType[]>).pipe(
      tap((options: DynamicFormSelectType[]) => {
        this.items = options;
        this.items.sort((a, b) => a.label.localeCompare(b.label));

        this.groupedItems = [];

        const notAssignedText = this.translateService.instant(LC.MULTI_SELECT.NOT_ASSIGNED);
        this.items.forEach((x) => {
          const groupName = x.group ? x.group : notAssignedText;
          const index = this.groupedItems.findIndex((y) => y.group === groupName);
          if (index < 0) {
            this.groupedItems.push({
              group: groupName,
              items: [x],
            });
          } else {
            this.groupedItems[index].items.push(x);
          }
        });

        this.groupedItems.sort((a, b) => a.group.localeCompare(b.group));
        this.groupedItemsFiltered = this.copyGroupedItems(this.groupedItems);

        this.dataType = this.props.dataType;
        const references: FabricationReference[] = this.model.fabricationReferences.filter(
          (x) => x.dataType === this.dataType
        );

        this.selectedItems = references
          .map((x) => {
            return this.items.find((y) => y.value === x.externalId);
          })
          .filter((x) => !!x);
      }),
      switchMap(() => {
        return this.store$
          .select(selectExtraDataElementInfoById(this.model.id))
          .pipe(map((x) => x?.interdependentReferenceIds ?? []));
      }),
      tap((disabledIds: string[]) => {
        this.selectedItems.forEach((x) => {
          x.disabled = disabledIds.includes(x.value.toString());
        });
        this.changeDetector.detectChanges();
      }),
      map(() => true),
      catchError((error) => {
        this.loggingService.logError(
          error,
          true,
          this.translateService.instant(LC.ERROR_HANDLING.GENERIC.UNKNOWN)
        );
        return of(false);
      }),
      take(1)
    );
  }

  getTooltip(item: DynamicFormSelectType) {
    if (!item.disabled) {
      return null;
    }

    let dataType1 = DataElementTypeUtils.getDataTypeFromSchema(
      `${this.model.extensionDataNamespace}:${this.model.extensionDataType}-${this.model.extensionDataVersion}`
    );
    const dataType1Reference = `${dataType1.replace(/([a-z])([A-Z])/g, '$1_$2').toUpperCase()}`;
    if (LC.DATATYPES.TYPES[dataType1Reference]) {
      dataType1 = this.translateService.instant(LC.DATATYPES.TYPES[dataType1Reference]);
    }

    let dataType2 = this.dataType;
    const dataType2Reference = `${dataType2.replace(/([a-z])([A-Z])/g, '$1_$2').toUpperCase()}`;
    if (LC.DATATYPES.TYPES[dataType2Reference]) {
      dataType2 = this.translateService.instant(LC.DATATYPES.TYPES[dataType2Reference]);
    }

    return this.translateService.instant(LC.MULTI_SELECT.REFERENCES_EXIST, {
      dataType1,
      dataType2,
    });
  }

  protected filterGroupedItemsMulti() {
    if (!this.groupedItemsFiltered) {
      return;
    }

    let search = this.groupedItemsFilterCtrl.value;
    const groupedItemsFilteredCopy = this.copyGroupedItems(this.groupedItemsFiltered);
    if (!search) {
      this.groupedItems = groupedItemsFilteredCopy;
      return;
    } else {
      search = search.toLowerCase();
    }

    this.groupedItems = groupedItemsFilteredCopy.filter((itemsGroup) => {
      const showItemsGroup = itemsGroup.group.toLowerCase().includes(search);
      if (!showItemsGroup) {
        itemsGroup.items = itemsGroup.items.filter((item: DynamicFormSelectType) =>
          item.label.toLowerCase().includes(search)
        );
      }

      return itemsGroup.items.length > 0;
    });
  }

  protected copyGroupedItems(groupedItems: GroupedItems[]) {
    const groupedItemsCopy = [];
    groupedItems.forEach((itemsGroup) => {
      groupedItemsCopy.push({
        group: itemsGroup.group,
        items: itemsGroup.items.slice(),
      });
    });

    return groupedItemsCopy;
  }
}

import { ForgeContentDataElement } from '@models/forge-content/forge-content-data-element';
import { Observable, of } from 'rxjs';
import {
  DynamicTableOptions,
  ColumnDefinition,
  DataElementListTableOptions,
  HelpContentOptions,
  CreateOptions,
} from '@models/dynamic-table/dynamic-table-options';
import {
  DynamicFormOptions,
  DynamicFormSelectField,
  DynamicFormFieldLabelMapping,
  DynamicFormSelectType,
} from '@models/dynamic-form/dynamic-form-properties';
import { DynamicFormOperationType } from '@models/dynamic-form/dynamic-form-types';
import { DataElementType } from '@constants/data-element-types';
import { Config, ConfigUnitSystem } from '@models/fabrication/config';
import { ForgeSchemaInfo } from '@models/forge-content/forge-content-schema';
import { map, take } from 'rxjs/operators';
import { FDMState } from '@store/reducers/index';
import { Store } from '@ngrx/store';
import { ContentNode } from '@models/fabrication/content-node';
import { TempModel } from '@models/temp-data/temp-model';
import { DynamicGraphOptions } from '@models/dynamic-graph/dynamic-graph-options';
import { ConnectorInfo, ConnectorDomainType } from '@models/fabrication/connector-info';
import { EnvironmentConstants } from '@constants/environment-constants';
import {
  FabricationReference,
  FabricationIndexableReference,
  FabricationConnectorReference,
  FabricationReferenceType,
} from '@models/forge-content/references';
import { startCase, omit, isArray, isUndefined, isEmpty, compact, uniq, orderBy } from 'lodash';
import { ToolBarOptions } from '@models/tool-bar/tool-bar-options';
import { getReferenceSelector } from '@store/selectors/configs.selectors';
import { UpsertTempModel, DeleteTempModel } from '@store/actions/temp-model.action';
import { selectTempModelById } from '@store/selectors/temp-model.selectors';
import { StorageFileType } from '@models/fabrication/files';
import {
  DeleteDataElementsSuccessAction,
  AddDataElementSuccessAction,
  UpdateDataElementSuccessAction,
  LoadDataElementsSuccessAction,
} from '@store/actions/base/data-element.action';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { TranslateService } from '@ngx-translate/core';
import { getI18nConstantRef } from '@utils/translate-utils';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import { InvalidData, InvalidDataErrorReason } from '@models/fabrication/invalid-data';
import { InvalidDataErrorService } from '@services/invalid-data-error.service';
import { DynamicDataService } from '@services/dynamic-data.service';
import { EnvironmentService } from '@services/environment.service';
import { Schema } from 'ajv';
import { SchemaService } from '@services/schema.service';
import { DataElementSchema, SubSchema } from '@models/forge-content/forge-content-schema';
import { DataElementTypeUtils } from '@utils/data-element-type-utils';
import { CopyUtils } from '@utils/copy-utils';

export interface DynamicFormReference {
  key: string;
  selectField: DynamicFormSelectField;
  labelField: DynamicFormFieldLabelMapping;
  dataType: DataElementType;
  lockField: string;
  referenceType: FabricationReferenceType;
}

export interface ExtraDataElementInfoOptions {
  /**
   * Inter-dependent reference types to check that form a combination of references on other data types
   * that need to be checked. e.g. the material and material specification reference combination on
   * a part specification.
   */
  interdependentReferenceDataTypes?: DataElementType[];
}

export interface DynamicDataElementTypeOptions<T extends ForgeContentDataElement> {
  dataType: DataElementType;
  createNewInstance: () => T;
  sortFields: string[];
  /**
   * Dependent types that need to be loaded when this data type is loaded.
   * @type {DataElementType[]}
   * @memberof DynamicDataElementTypeOptions
   */
  dependentDataTypes: DataElementType[];

  extraDataElementInfoOptions?: ExtraDataElementInfoOptions;

  /**
   * Exclude this data type as part of any bulk loading operations.
   * @type {boolean}
   * @memberof DynamicDataElementTypeOptions
   */
  excludeDataTypeFromBulkLoad?: boolean;
  /**
   * The supported storage types for the dataType
   * required to be set when content files are downloaded during bulk download
   * @type {StorageFileType[]}
   * @memberof DynamicDataElementTypeOptions
   */
  bulkLoadFileTypesSupported?: StorageFileType[];
  /**
   * Defines if the data type supports dynamic updates when using service worker caching
   * via activities (real time and on first instance of data type load)
   * Note - if the data type does not require dynamic updates ensure this is set to false to
   * prevent unnecessary api calls for activities on first load
   * @type {boolean}
   * @memberof DynamicDataElementTypeOptions
   */
  supportsDynamicUpdates: boolean;

  /**
   * Defines if the files and thumbnails are referenced on other contents.
   */
  filesAreReferenced?: boolean;
  selectors: {
    selectAll(includeInvalidData: boolean): Observable<T[]>;
    selectById: (id: string, getInternalInvalidData?: boolean) => Observable<T>;
  };
  actions: {
    loadAllAction: (config: Config) => void;
    loadSuccessAction: () => any;
    loadReferencedFilesSuccessAction?: () => LoadDataElementsSuccessAction<ForgeContentDataElement>;
    deleteDataSuccessAction: () => DeleteDataElementsSuccessAction;
    addDataSuccessAction: () => AddDataElementSuccessAction<T>;
    updateDataSuccessAction: () => UpdateDataElementSuccessAction<T>;
    updateDataReferencesAction: (
      parent: Config | ContentNode,
      dataIds: string[],
      deleteReferences: boolean
    ) => any;
    createModelAction: (model: T) => void;
    editModelAction: (model: T, oldModel?: T) => void;
    copyModelAction: (model: T) => void;
    deleteModelsAction: (models: T[]) => void;
    fixModelAction: (model: T) => void;
    startUpgradeAction?: (id: string) => void;
  };
  fcs: {
    dataTypeExternalNodeId: string;

    /**
     * The schemas stored in FSS for the purpose of validation.
     */
    schemas: DataElementSchema[];
    subSchemas?: SubSchema[];

    /**
     * The create schemas define the schema names used when creating a new data type.
     */
    createSchemaOverride?: () => ForgeSchemaInfo;
  };
}

export interface DynamicDataNodeTypeOptions {
  dataType: DataElementType;
  selectors: {
    selectAll: Observable<ContentNode[]>;
    selectById: (id: string) => Observable<ContentNode>;
  };
  actions: {
    loadAllAction: (config: Config) => void;
  };
  fcs: {
    dataTypeExternalNodeId: string;
  };
}

export abstract class DynamicDataNodeTypeSetup {
  options: DynamicDataNodeTypeOptions = null;

  constructor(protected store$: Store<FDMState>) {}

  abstract setupOptions(): void;
}

export abstract class DynamicDataElementTypeSetup<T extends ForgeContentDataElement> {
  options: DynamicDataElementTypeOptions<T> = null;

  /**
   * This property is set in the DynamicDataService itself in order to avoid
   * circular DI references.
   */
  dynamicDataService: DynamicDataService;

  constructor(
    protected store$: Store<FDMState>,
    protected translate: TranslateService,
    protected invalidDataErrorService: InvalidDataErrorService<T>,
    protected schemaService: SchemaService,
    private environmentService: EnvironmentService
  ) {
    this.setupOptions();
  }

  abstract setupOptions(): void;

  abstract get helpLinkId(): string;

  private validateSetup() {
    if (!this.options) {
      throw new Error('Dynamic data options have not setup');
    }
  }

  // get dynamic form model dependant on operation
  // if we are running a create operation, create new model instance
  protected getFormModel(
    formOperation: DynamicFormOperationType,
    existingModelId: string | null
  ): Observable<T> {
    this.validateSetup();
    return (
      (formOperation === 'create' && of(this.options.createNewInstance())) ||
      (formOperation === 'copy' && this.getModelCopy(existingModelId)) ||
      this.options.selectors.selectById(existingModelId, formOperation === 'fix')
    );
  }

  protected getFormApplyAction(
    formOperation: DynamicFormOperationType
  ): (model: T, oldModel?: T) => void {
    this.validateSetup();

    switch (formOperation) {
      case 'create':
        return this.options.actions.createModelAction;
      case 'edit':
        return this.options.actions.editModelAction;
      case 'copy':
        return this.options.actions.copyModelAction;
      case 'fix':
        return this.options.actions.fixModelAction;

      default:
      case 'view':
        return null;
    }
  }

  abstract getDynamicFormOptions(
    formOperation: DynamicFormOperationType,
    modelId: string
  ): Observable<DynamicFormOptions<T>>;

  abstract getDynamicTableOptions(
    configUnitSystem: ConfigUnitSystem
  ): Observable<DynamicTableOptions<T>>;

  private getDynamicDataTitle(dataType: DataElementType): string {
    return DataElementTypeUtils.getStartCase(this.translate, dataType, true);
  }

  private getDynamicHelpContentOptions(dataType: DataElementType): HelpContentOptions | any {
    const dataTypeReference = DataElementTypeUtils.getLocalisationConstantRef(dataType, false);
    if (
      !isUndefined(LC.HELP_CONTENT[dataTypeReference]) &&
      !isUndefined(LC.HELP_CONTENT[dataTypeReference].TITLE) &&
      !isEmpty(LC.HELP_CONTENT[dataTypeReference]) &&
      !isEmpty(LC.HELP_CONTENT[dataTypeReference].TITLE)
    ) {
      return {
        title: this.translate.instant(LC.HELP_CONTENT[dataTypeReference].TITLE),
        content: this.translate.instant(LC.HELP_CONTENT[dataTypeReference].CONTENT),
        linkText: this.translate.instant(LC.NOTIFICATIONS.COMMON.LEARN_MORE),
        link: `${this.environmentService.environment.helpLinks.baseUrl}${this.helpLinkId}`,
      };
    } else {
      return {
        title: this.translate.instant(LC.HELP_CONTENT.DEFAULT.TITLE),
        content: this.translate.instant(LC.HELP_CONTENT.DEFAULT.CONTENT),
        linkText: this.translate.instant(LC.NOTIFICATIONS.COMMON.LEARN_MORE),
        link: `${this.environmentService.environment.helpLinks.baseUrl}${this.helpLinkId}`,
      };
    }
  }

  protected createDynamicTableOptions = (
    columns: ColumnDefinition[],
    createOptions?: CreateOptions
  ): DynamicTableOptions<T> => {
    const title = this.getDynamicDataTitle(this.options.dataType);
    const helpContentOpts = this.getDynamicHelpContentOptions(this.options.dataType);

    if (columns.length) {
      // add the last modified time to each data type
      columns.push({
        field: 'lastModifiedTime',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.GENERIC.LAST_MODIFIED),
        formatter: (value: string) => {
          return DynamicDataElementTypeSetup.convertUtcToRelativeTime(
            value,
            this.translate.currentLang
          );
        },
        visible: true,
      });
    }

    this.validateSetup();
    return {
      id: uuidv4(),
      data: this.options.selectors.selectAll(false),
      controlIdPrefix: `${this.options.dataType.toLowerCase()}-list`,
      dataElementType: this.options.dataType,
      title,
      filter: {
        text: this.translate.instant(LC.DATATYPES.DEFINITIONS.GENERIC.SEARCH),
      },
      paging: true,
      rowSelection: { visible: true },
      columns,
      tableTypeOptions: {
        deleteAction: { delete: this.options.actions.deleteModelsAction },
      } as DataElementListTableOptions<T>,
      sortFields: this.options.sortFields,
      toolbarOptions: {} as ToolBarOptions<T>,
      customCreate: createOptions,
      ...(!isEmpty(helpContentOpts) && { helpContent: helpContentOpts }),
      showColumnSettings: true,
    };
  };

  /**
   * It returns the graph view options object of a specific data type.
   * That options are the form tabs of the
   * graph view side form (if the original model is tabbed)
   * and the visible node info in each node/box of certain data type
   * in the graph view. The default tab is none (no tabs)
   * and default node visible is name.
   *
   * @returns {DynamicGraphOptions}
   * @memberof DynamicDataElementTypeSetup
   */
  getDynamicGraphOptions(): DynamicGraphOptions {
    return {
      nodeInfoFields: ['name'],
      isReplaceable: true,
      isRemovable: true,
      requireModelOnLoadList: false,
      upstreamReferenceDataTypes: () => [],
    };
  }

  abstract requiresBinaryUpgrade(dataElement: T): boolean;

  protected getModelCopy(modelId: string): Observable<T> {
    this.validateSetup();

    return this.options.selectors.selectAll(true).pipe(
      map((all) => {
        const existingModel = all.find((x) => x.id === modelId);

        const existingNames = all.map((x) => x.name);

        const copyOfModel = { ...existingModel };

        const { copiedText: copyName } = CopyUtils.getCopiedString(
          existingModel.name,
          existingNames
        );

        copyOfModel.name = copyName;

        return copyOfModel;
      }),
      take(1)
    );
  }

  /**
   * Create form label for referenced data that has multiple entries e.g. connectors
   * @protected
   * @memberof DynamicDataElementTypeSetup
   */
  protected createReferenceLabel = (
    dataType: DataElementType,
    model: ForgeContentDataElement,
    referenceIndex: number
  ): string => {
    const dataTypeCount = model.fabricationReferences.filter((x) => x.dataType === dataType).length;

    const dataTypeTranslation = DataElementTypeUtils.getStartCase(this.translate, dataType, false);

    if (dataTypeCount > 1) {
      const reference: FabricationReference = model.fabricationReferences[referenceIndex];

      if ('index' in reference) {
        const labelNumber = (reference as unknown as FabricationIndexableReference).index + 1;
        return `${startCase(dataTypeTranslation) + ' ' + labelNumber}`;
      }
    }
    return startCase(dataTypeTranslation);
  };

  /**
   * Add any missing references to the model, e.g. adding a service template to a service
   */
  abstract fixMissingReferences(
    fabricationReferences: FabricationReference[]
  ): FabricationReference[];

  /**
   * Method that gets called after loading the data that can be used to fix data up
   * until the data is upgraded.
   * @param model
   */
  abstract dataFixes(model: T[]): void;

  /**
   * Get the name of the icon to use in the invalid data table.
   */
  abstract getIconName(): string;

  /**
   * Determine if the model is fixable or not.
   * @param model
   */
  abstract isFixable(model?: InvalidData & T): boolean;

  protected getFabricationReferenceFields(model: T): DynamicFormReference[] {
    let references: DynamicFormReference[] = [];

    if (!model.fabricationReferences || model.fabricationReferences.length === 0) {
      return references;
    }

    references = model.fabricationReferences
      .map<DynamicFormReference>((ref, i) => {
        const key = `fabricationReferences.${i}.externalId`;
        return {
          key,
          dataType: ref.dataType,
          referenceType: ref.referenceType,
          lockField: (this.referenceDataTypeSupportsLocking(ref.dataType) && key) || '',
          labelField: {
            key,
            label: this.createReferenceLabel(ref.dataType, model, i),
          },
          selectFieldReadOnlyOverride: {
            label: startCase(ref.dataType),
            value: this.getReferenceSelector(ref.dataType).pipe(
              map((referenceInfoList: any) => {
                const data = referenceInfoList.find((x) => x.externalId === ref.externalId);
                return (data && data.name) || this.translate.instant(LC.GRAPH.UNASSIGNED);
              })
            ),
            referenceIndex: i,
          },
          selectField: {
            key,
            options: this.getReferenceSelector(ref.dataType).pipe(
              map((referenceInfoList: any) => {
                return this.getDataElementTypeAsSummaryList(
                  ref.dataType,
                  referenceInfoList,
                  model,
                  i
                );
              })
            ),
          },
        };
      })
      .filter((x) => x.referenceType === FabricationReferenceType.Relationship);

    return references;
  }

  protected getMultiSelectOptions(dataType: DataElementType): DynamicFormSelectType[] {
    let data: DynamicFormSelectType[] = [];
    this.getReferenceSelector(dataType)
      .pipe(take(1))
      .subscribe((referenceInfoList: any) => {
        data = this.getDataElementTypeAsSummaryList(dataType, referenceInfoList);
      });

    return data;
  }

  protected getItemListForTypeaheadControl(propertyToMap: string): string[] {
    let itemList: string[] = [];

    // eslint-disable-next-line consistent-return
    this.options.selectors
      .selectAll(false)
      .pipe(take(1))
      .subscribe((modelList) => {
        itemList = modelList.map((model) => {
          if (isEmpty(model[propertyToMap])) {
            return undefined;
          }

          return model[propertyToMap];
        });
      });

    //remove undefined or duplicates items from array and sort asc
    return orderBy(uniq(compact(itemList)), null, ['asc']);
  }

  /**
   * Return summary list of data for data type
   * containing name/group structure that
   * can be bound to select field for display
   * @memberof DynamicDataElementTypeSetup
   */
  getDataElementTypeAsSummaryList = (
    dataType: DataElementType,
    referenceInfoList: any[],
    model: ForgeContentDataElement = null,
    dataIndex: number = null
  ): DynamicFormSelectType[] => {
    switch (dataType) {
      case DataElementType.ServiceTemplate:
        return this.filterStandardReferenceType(referenceInfoList);

      case DataElementType.FabricationRate:
      case DataElementType.InstallationRate:
        return this.filterStandardReferenceType(referenceInfoList);

      case DataElementType.Stiffener:
        return [
          {
            value: EnvironmentConstants.FCS_UNASSIGNED_STIFFENER,
            label: this.translate.instant(LC.GRAPH.UNASSIGNED),
          },
          ...this.filterStandardReferenceType(referenceInfoList),
        ];
      case DataElementType.Specification:
        return [
          {
            value: EnvironmentConstants.FCS_UNASSIGNED_PART_SPEC,
            label: this.translate.instant(LC.GRAPH.UNASSIGNED),
          },
          ...this.filterStandardReferenceType(referenceInfoList),
        ];
      case DataElementType.InsulationSpecification:
        return [
          {
            value: EnvironmentConstants.FCS_UNASSIGNED_INSULATION_SPEC,
            label: this.translate.instant(LC.GRAPH.UNASSIGNED),
          },
          ...this.filterStandardReferenceType(referenceInfoList),
        ];
      case DataElementType.StiffenerSpecification:
        return [
          {
            value: EnvironmentConstants.FCS_UNASSIGNED_STIFFENER_SPEC,
            label: this.translate.instant(LC.GRAPH.UNASSIGNED),
          },
          ...this.filterStandardReferenceType(referenceInfoList),
        ];
      case DataElementType.Connector:
        return this.filterConnectorReferences(referenceInfoList, model, dataIndex);

      case DataElementType.Material:
        // we don't want the Unassigned option to be on the material's multi select control
        // but we will want this on the edit part dialog
        return this.filterStandardReferenceType(referenceInfoList);

      case DataElementType.MaterialSpecification:
        // we don't want the Unassigned option to be on the material's multi select control
        // but we will want this on the edit part dialog
        return this.filterStandardReferenceType(referenceInfoList);

      default:
        throw new Error('Unsupported references data type');
    }
  };

  /**
   * DataTypes that support locking
   * @private
   * @memberof DynamicDataElementTypeSetup
   */
  private referenceDataTypeSupportsLocking = (dataType: DataElementType): boolean =>
    dataType === DataElementType.Connector ||
    dataType === DataElementType.Damper ||
    dataType === DataElementType.MaterialSpecification;

  /**
   * Standard reference type filter
   *
   * @param {any[]} referenceList
   * @param {boolean} group
   * @returns {DynamicFormSelectType[]}
   * @memberof DynamicDataElementTypeSetup
   */
  filterStandardReferenceType(referenceList: any[]): DynamicFormSelectType[] {
    return referenceList.map((x) => {
      const referenceEntry: DynamicFormSelectType = {
        value: x.externalId,
        label: x.name || x.description,
      };

      referenceEntry.group = `${
        x.category || this.translate.instant(LC.DATATYPES.DEFINITIONS.GENERIC.NOT_ASSIGNED)
      }`;

      return referenceEntry;
    });
  }

  /**
   * Filter connector references
   * Apply shape and domain filters if required
   *
   * @param {ConnectorInfo[]} connectors
   * @param {ForgeContentDataElement} model
   * @param {number} referenceIndex
   * @returns {DynamicFormSelectType[]}
   * @memberof DynamicDataElementTypeSetup
   */
  filterConnectorReferences(
    connectors: ConnectorInfo[],
    model: ForgeContentDataElement,
    referenceIndex: number
  ): DynamicFormSelectType[] {
    // filter connectors to shape and domain
    // if db connector matches shape but has domain of 'Not Set' list out
    // only if model is part
    let connectorOptions: DynamicFormSelectType[] = [];
    if (model.extensionDataType === EnvironmentConstants.FSS_SCHEMA_PART) {
      connectorOptions = connectors
        .filter(
          (connectorInfo: ConnectorInfo) =>
            (connectorInfo.shape ===
              (model.fabricationReferences[referenceIndex] as FabricationConnectorReference)
                .shape &&
              connectorInfo.domain ===
                (model.fabricationReferences[referenceIndex] as FabricationConnectorReference)
                  .domain) ||
            connectorInfo.domain === ConnectorDomainType.NotSet
        )
        .map((x) => ({
          value: x.externalId,
          label: x.name,
          group: `${
            x.category || this.translate.instant(LC.DATATYPES.DEFINITIONS.GENERIC.NOT_ASSIGNED)
          }`,
        }));
    } else {
      connectorOptions = this.filterStandardReferenceType(connectors);
    }

    return [
      {
        value: EnvironmentConstants.FCS_UNASSIGNED_CONNECTOR,
        label: this.translate.instant(LC.GRAPH.UNASSIGNED),
      },
      ...connectorOptions,
    ];
  }

  protected getReferenceSelector(dataType: DataElementType) {
    return this.store$.select(getReferenceSelector(dataType));
  }

  public storeTempModel(tempModel: TempModel<T>) {
    this.store$.dispatch(new UpsertTempModel({ data: tempModel }));
  }

  public getTempModel(tempModelId: string): Observable<TempModel<any>> {
    return this.store$.select(selectTempModelById(tempModelId));
  }

  public deleteTempModel(tempModelId: string) {
    this.store$.dispatch(new DeleteTempModel({ id: tempModelId }));
  }

  public removeUnecessaryModelProperties(
    model: any,
    propertyReference: string,
    propertyToRemove: string
  ): any {
    if (
      model &&
      model[propertyReference] &&
      model[propertyReference].map &&
      isArray(model[propertyReference]) &&
      propertyReference === 'breakPointTable'
    ) {
      model[propertyReference] = model[propertyReference].map((o) => {
        return o.constructor.name !== 'Object' ? omit(o, propertyToRemove) : o;
      });
    }
    return model;
  }

  public static convertUtcToRelativeTime(utcTime: string, currentLang: string): string {
    const dateTime = DateTime.fromISO(utcTime, { zone: 'utc' });
    const relative = dateTime.toRelative({ locale: currentLang });

    return relative;
  }

  abstract getInvalidDataErrors(model: T & InvalidData): string;

  protected getStandardInvalidDataError(
    dataType: DataElementType,
    model: T & InvalidData,
    schema: Schema
  ): string {
    if (model.reason === InvalidDataErrorReason.PayloadTooBig) {
      return this.translate.instant(LC.ERROR_HANDLING.GENERIC.PAYLOAD_TOO_BIG);
    }

    if (model?.reason === InvalidDataErrorReason.NameTooLong) {
      return this.translate.instant(LC.ERROR_HANDLING.GENERIC.NAME_TOO_LONG);
    }

    const parsedErrors = this.invalidDataErrorService.parseErrors(model, schema);
    if (!parsedErrors.length) {
      return this.translate.instant(LC.ERROR_HANDLING.GENERIC.UNKNOWN);
    }

    if (parsedErrors.length > 1) {
      return this.translate.instant(LC.ERROR_HANDLING.GENERIC.MULTIPLE_ERRORS);
    }

    const error = parsedErrors[0];
    const genericAttributes = ['externalId', 'contentExternalId'];
    if (genericAttributes.includes(error.attribute)) {
      return this.translate.instant(LC.ERROR_HANDLING.GENERIC.INVALID_REFERENCE);
    }

    const dataTypeReference = DataElementTypeUtils.getLocalisationConstantRef(dataType, true);
    const attributeReference = getI18nConstantRef(error.attribute);

    const dataKey = LC.DATATYPES.DEFINITIONS[dataTypeReference];
    if (dataKey) {
      const attributeKey = dataKey[attributeReference];
      if (attributeKey) {
        const translatedAttribute = this.translate.instant(attributeKey);

        // todo - we can also look at using the reason and not just the attribute in the error message
        return this.translate.instant(LC.ERROR_HANDLING.GENERIC.INVALID_ATTRIBUTE, {
          attribute: translatedAttribute,
        });
      }
    }

    return this.translate.instant(LC.ERROR_HANDLING.GENERIC.UNKNOWN);
  }
}

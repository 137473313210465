import { IllustrationVariantsType } from '@shared/react/components/error-page/error-page.component';
import { LocalisationConstants } from '@constants/localisation-constants';
import { NavigationConstants } from '@constants/navigation-constants';

export enum ErrorPageType {
  NoEntitlement = 'no-entitlement',
  NoData = 'getting-started',
  AppUnavailable = 'app-unavailable',
  AppLoadingError = 'app-loading-error',
}

export enum SubscriptionName {
  Revit = 'Revit',
  AECCollection = 'AEC Collection',
  MEPCE_Sync = 'MEP Content Editor Sync',
}

export interface ErrorPageButton {
  title: string;
  onClick: () => void;
  subscriptionName?: SubscriptionName;
}

export interface ErrorPageDetails {
  svgPath?: string;
  message: string;
  helpLinkSegment?: string;
  helpLinkText?: string;
  title?: string;
  illustrationVariant?: IllustrationVariantsType;
  subTitle?: string;
  buttons?: ErrorPageButton[];
}

export const errorPageSettings: { [key: string]: ErrorPageDetails } = {
  [ErrorPageType.AppUnavailable]: {
    svgPath: '/assets/svg/SystemError.svg',
    message: LocalisationConstants.APP_UNAVAILABLE.DEFAULT_MESSAGE,
  },
  [ErrorPageType.AppLoadingError]: {
    svgPath: '/assets/svg/SystemError.svg',
    message: LocalisationConstants.APP_LOADING_ERROR.DEFAULT_MESSAGE,
  },
  [ErrorPageType.NoData]: {
    title: LocalisationConstants.GETTING_STARTED.TITLE,
    illustrationVariant: 'first-use',
    subTitle: LocalisationConstants.GETTING_STARTED.SUB_TITLE,
    message: LocalisationConstants.GETTING_STARTED.MESSAGE,
    helpLinkSegment: 'MEPCE_Help_GetStarted_html',
    helpLinkText: LocalisationConstants.NO_ENTITLEMENT.LEARN_MORE,
    buttons: [
      {
        title: LocalisationConstants.NO_ENTITLEMENT.GET_SUBSCRIPTION,
        onClick: () => window.open(NavigationConstants.UPDATES_LINK, '_blank'),
        subscriptionName: SubscriptionName.MEPCE_Sync,
      },
    ],
  },
  [ErrorPageType.NoEntitlement]: {
    title: LocalisationConstants.NO_ENTITLEMENT.TITLE,
    illustrationVariant: 'no-permission',
    subTitle: LocalisationConstants.NO_ENTITLEMENT.SUB_TITLE,
    message: LocalisationConstants.NO_ENTITLEMENT.MESSAGE,
    buttons: [
      {
        title: LocalisationConstants.NO_ENTITLEMENT.GET_SUBSCRIPTION,
        onClick: () => window.open(NavigationConstants.GET_REVIT_LINK, '_blank'),
        subscriptionName: SubscriptionName.Revit,
      },
      {
        title: LocalisationConstants.NO_ENTITLEMENT.GET_SUBSCRIPTION,
        onClick: () => window.open(NavigationConstants.GET_AEC_COLLECTION_LINK, '_blank'),
        subscriptionName: SubscriptionName.AECCollection,
      },
    ],
    helpLinkSegment: 'MEPCE_Help_GetStarted_html',
    helpLinkText: LocalisationConstants.NO_ENTITLEMENT.LEARN_MORE,
  },
};

import ProjectAccountSwitcher from '@hig/project-account-switcher';
import ThemeContext from '@weave-design/theme-context';
import TopNav, {
  HelpAction,
  Interactions,
  Logo,
  NotificationsAction,
  ProfileAction,
  Separator,
  NavAction,
} from '@hig/top-nav';
import Menu, { MenuGroup, Option } from '@weave-design/menu';
import { Settings24, HamburgerCloseUI, HamburgerDefaultUI } from '@weave-design/icons';
import Button from '@weave-design/button';
import { ProfileContent } from '@hig/profile-flyout';
import * as React from 'react';
import styled from 'styled-components';
import TextLink from '@weave-design/text-link';
import Timestamp from '@weave-design/timestamp';
import Typography from '@weave-design/typography';
import {
  Project,
  ProfileInfo,
  HelpItem,
  NotificationListItem,
} from '@models/ui-state/hig-navigation';
import { Theme, ThemeData } from '@models/theme/theme';
import { DebugMode } from '@models/debug-mode/debug-mode';
import Divider from '@weave-design/divider';
import { typographyVariants } from '@weave-mui/enums';

const Controls = styled('div')`
  display: flex;
  justify-content: space-around;
  margin-top: 0.5rem;
`;

const HelpLinksContainer = styled('div')`
  padding: 0.2rem 1.5rem;
`;

const HelpLinkItem = styled('div')`
  margin: 0.5rem 0;
`;

const TextLinkStyled = styled(TextLink)`
  && {
    color: #6dd2ff;
  }
`;

export type Props = {
  isLoading: boolean;
  projects: Project[];
  defaultProject: string;
  profileInfo: ProfileInfo;
  profileSettingsLink: string;
  sideNavOpened: boolean;
  helpItems: HelpItem[];
  notifications: NotificationListItem[];
  onClickSignOut: (e: any) => void;
  onChangeProject: (project: string) => void;
  onClickHamburger: (e: any) => void;
  onClickLogo: (e: any) => void;
  onClickTheme: (theme: Theme) => void;
  onClickDebugMode: (debugMode: DebugMode) => void;
  themeData: ThemeData;
  logo: string;
  showSettingsButton: boolean;
};

export const HigTopNav: React.FC<Props> = ({
  isLoading,
  projects,
  defaultProject,
  profileInfo,
  profileSettingsLink,
  sideNavOpened,
  helpItems,
  notifications,
  onClickSignOut,
  onChangeProject,
  onClickHamburger,
  onClickLogo,
  onClickTheme,
  onClickDebugMode,
  themeData,
  logo,
  showSettingsButton,
}) => {
  const flyoutNotifications = notifications.map((notification) => ({
    ...notification,
    showDismissButton: true,
    timestamp: notification.timestamp && (
      <Timestamp timestamp={notification.timestamp.toISOString()} />
    ),
    content: (
      <div>
        <p>{notification.content}</p>

        {notification.description && (
          <p>
            <small style={{ display: 'block' }}>{notification.description}</small>
          </p>
        )}

        {notification.link && (
          <TextLink onClick={notification.link.action}>{notification.link.text}</TextLink>
        )}
      </div>
    ),
  }));

  return (
    <ThemeContext.Provider value={themeData}>
      <TopNav
        leftActions={
          <Interactions>
            <Button className="fdm__open-close-sidenav-btn" onClick={onClickHamburger}>
              {sideNavOpened ? <HamburgerCloseUI /> : <HamburgerDefaultUI />}
            </Button>
          </Interactions>
        }
        logo={
          <Logo onClick={onClickLogo}>
            <img className="top-nav-logo" src={logo} alt="MEP Content Editor" />
            <Typography
              fontWeight={600}
              variant="h1"
              stylesheet={(styles) => ({
                ...styles,
                typography: {
                  ...styles.typography,
                  fontSize: '20px',
                  overflowWrap: 'break-word',
                  minWidth: '250px',
                  marginTop: '6px',
                },
              })}
            ></Typography>
          </Logo>
        }
        rightActions={
          (!isLoading && (
            <Interactions>
              <nav aria-label="config-navigation">
                {(projects?.length && (
                  <ProjectAccountSwitcher
                    projectTitle="Fabrication Configurations"
                    projects={projects}
                    defaultProject={defaultProject}
                    onChange={(e) => {
                      defaultProject = e.project;
                      onChangeProject(e.project);
                    }}
                    stylesheet={(styles) => ({
                      ...styles,
                      image: {
                        ...styles.image,
                        width: '30px',
                        height: '30px',
                        objectFit: 'contain',
                      },
                    })}
                  />
                )) || <></>}
              </nav>
              <Separator />
              <NotificationsAction
                emptyMessage="Up to date"
                heading="Notifications"
                notifications={flyoutNotifications}
              />

              <HelpAction>
                <HelpLinksContainer>
                  {helpItems &&
                    helpItems.map((item) => (
                      <HelpLinkItem key={item.text} onClick={item.onClick}>
                        <TextLinkStyled
                          stylesheet={(styles) => ({
                            ...styles,
                            color:
                              themeData.metadata.colorSchemeId === 'fdm-light-theme' &&
                              `${themeData.resolvedRoles['basics.colors.textLink.secondary.againstLight']} !important`,
                          })}
                          role="button"
                          link={item.link}
                          target={item.openInNewWindow ? '_blank' : '_self'}
                          onClick={(e) => {
                            if (!item.openInNewWindow) {
                              //call angular router.navigate when should be open in the same window
                              e.stopPropagation();
                              e.preventDefault();
                              item.onClick(e);
                            }
                          }}
                        >
                          {item.text}
                        </TextLinkStyled>
                        <>
                          {item.description && (
                            <Typography
                              stylesheet={(styles) => ({
                                ...styles,
                                typography: {
                                  ...styles.typography,
                                  display: 'block',
                                  opacity: 0.7,
                                  color:
                                    themeData.metadata.colorSchemeId === 'fdm-light-theme'
                                      ? themeData.resolvedRoles[
                                          'basics.colors.textLink.secondary.againstLight'
                                        ]
                                      : themeData.resolvedRoles[
                                          'basics.colors.textLink.secondary.againstDark'
                                        ],
                                },
                              })}
                              variant={typographyVariants.CAPTION_REGULAR}
                            >
                              {item.description}
                            </Typography>
                          )}
                        </>

                        {item.hasDivider && <Divider length="100%" />}
                      </HelpLinkItem>
                    ))}
                </HelpLinksContainer>
              </HelpAction>

              {showSettingsButton && (
                <NavAction title="Settings" icon={<Settings24 />}>
                  <MenuGroup>
                    <Menu>
                      <Option id="theme-header" role="presentation">
                        Themes
                      </Option>
                      <Option id="default-theme" onClick={() => onClickTheme(Theme.Default)}>
                        Default
                      </Option>
                      <Option id="light-grey-theme" onClick={() => onClickTheme(Theme.LightGrey)}>
                        Light Grey
                      </Option>
                      <Option id="dark-grey-theme" onClick={() => onClickTheme(Theme.DarkGrey)}>
                        Dark Grey
                      </Option>
                      <Option id="dark-blue-theme" onClick={() => onClickTheme(Theme.DarkBlue)}>
                        Dark Blue
                      </Option>
                    </Menu>
                  </MenuGroup>

                  <MenuGroup multiple={true}>
                    <Menu>
                      <Option id="debug-mode-header" role="presentation">
                        Debug Modes
                      </Option>
                      <Option
                        id="acm-down"
                        onClick={() => onClickDebugMode(DebugMode.CouldNotGetAccessRole)}
                      >
                        Simulate getting access role failure
                      </Option>
                      <Option
                        id="schema-down"
                        onClick={() => onClickDebugMode(DebugMode.CouldNotGetSchema)}
                      >
                        Simulate getting schema failure
                      </Option>
                      <Option
                        id="webassembly-heavy-processing"
                        onClick={() => onClickDebugMode(DebugMode.WebAssemblyHeavyProcessing)}
                      >
                        Simulate heavy processing getting part data
                      </Option>
                      <Option
                        id="enable-pattern-number-search"
                        onClick={() => onClickDebugMode(DebugMode.EnablePatternNumberSearch)}
                      >
                        Enable pattern number search
                      </Option>
                      <Option
                        id="enable-bu-for-material-spec"
                        onClick={() =>
                          onClickDebugMode(DebugMode.EnableBinaryUpdateForMaterialSpec)
                        }
                      >
                        {DebugMode.EnableBinaryUpdateForMaterialSpec}
                      </Option>
                    </Menu>
                  </MenuGroup>
                </NavAction>
              )}

              {profileInfo && (
                <div id="user-profile">
                  <ProfileAction avatarName={profileInfo.name} avatarImage={profileInfo.image}>
                    <ProfileContent profileName={profileInfo.name} profileEmail={profileInfo.email}>
                      <Controls>
                        <Button title="Sign Out" type="secondary" onClick={onClickSignOut} />
                        <Button
                          title="Profile Settings"
                          type="secondary"
                          link={profileSettingsLink}
                          target="_blank"
                        />
                      </Controls>
                    </ProfileContent>
                  </ProfileAction>
                </div>
              )}
            </Interactions>
          )) || <></>
        }
      />
    </ThemeContext.Provider>
  );
};

import { Injectable } from '@angular/core';
import { NavigationConstants as nc } from '@constants/navigation-constants';
import { Config } from '@models/fabrication/config';
import {
  NavigationData,
  NavigationLink,
  NavigationModule,
  NavigationSubModule,
} from '@models/ui-state/navigation-data';
import { NavigationDataEntry } from '@models/ui-state/navigation-data';
import { FeatureFlagService } from './feature-flag.service';
import { FeatureFlagConstants } from '@constants/feature-flag-constants';
import { EnvironmentService } from './environment.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { FeedbackService } from './feedback.service';
import { URLSanitizerPipe } from '@shared/pipes/dom-sanitizer.pipe';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import helpLinks from '@assets/help/help-links.json';
import { ContentUserRole, ContentUserRoleEnum } from '@models/access-rights/access-rights';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private _currentNavigationData = new BehaviorSubject<NavigationData>(null);
  public get currentNavigationData(): Observable<NavigationData> {
    return this._currentNavigationData.asObservable();
  }
  private _showUserConsentModal = new BehaviorSubject<boolean>(false);
  showUserConsentModal$ = this._showUserConsentModal.asObservable();

  urlSanitizerPipe: URLSanitizerPipe;

  constructor(
    private featureFlagService: FeatureFlagService,
    private envService: EnvironmentService,
    private feedbackService: FeedbackService,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {
    this.urlSanitizerPipe = new URLSanitizerPipe(this.sanitizer);
    this.createNavigationDataForAppLoading();
  }

  createNavigationDataForConfig(
    configs: Config[],
    selectedConfig: Config,
    role: ContentUserRole
  ): void {
    let dataSource: NavigationDataEntry[] = [];
    let modules: NavigationModule[] = [];

    if (selectedConfig) {
      dataSource = configs.map((config) => ({
        name: config.name,
        image: config.thumbnailUrl,
        id: config.externalId,
      }));

      modules = this.createModules(selectedConfig.externalId, role);
    }

    this._currentNavigationData.next({
      appIsLoading: false,
      superHeaderTitle:
        (selectedConfig && nc.NAV_SUPER_HEADER_HAS_DATA) || nc.NAV_SUPER_HEADER_NO_DATA, //xlate
      headerLabel: (selectedConfig && selectedConfig.name) || nc.NAV_SUPER_HEADER_NO_DATA, //xlate
      headerLink:
        (selectedConfig &&
          nc.NAV_HEADER_LINK.replace(nc.NAV_DATA_ID_PLACE_HOLDER, selectedConfig.externalId)) ||
        ' ',
      onClickHeader: () => {
        if (!selectedConfig) return;

        this.router.navigate([
          nc.NAV_HEADER_LINK.replace(nc.NAV_DATA_ID_PLACE_HOLDER, selectedConfig.externalId),
        ]);
      },
      modules,
      links: this.createLinks(false),
      selectedId: selectedConfig?.externalId,
      dataSource,
      dataSwitcherTitle: nc.NAV_DATA_SELECTOR_TITLE,
      helpMenuItems: this.createHelpMenuItems(false),
    });
  }

  setShowUserConsentModal(value: boolean) {
    this._showUserConsentModal.next(value);
  }

  private createNavigationDataForAppLoading = (): void => {
    this._currentNavigationData.next({
      appIsLoading: true,
      superHeaderTitle: '...',
      headerLabel: '...',
      headerLink: ' ',
      modules: this.createModules('', ContentUserRoleEnum.OWNER),
      links: this.createLinks(true),
      selectedId: '',
      dataSource: [],
      dataSwitcherTitle: '',
      helpMenuItems: this.createHelpMenuItems(true),
      onClickHeader: () => {
        // do nothing
      },
    });
  };

  private createModules = (configExternalId: string, role: ContentUserRole): NavigationModule[] => {
    const generalAllowedRoles = [ContentUserRoleEnum.OWNER, ContentUserRoleEnum.WRITE];
    const adminSubModules: NavigationSubModule[] = [
      {
        title: nc.SIDE_NAV_SUB_MODULE_RESOLVE_DATA,
        link: nc.SIDE_NAV_SUB_MODULE_RESOLVE_LINK.replace(
          nc.NAV_DATA_ID_PLACE_HOLDER,
          configExternalId
        ),
      },
      {
        title: nc.SIDE_NAV_SUB_MODULE_ACCESS_RIGHTS,
        link: nc.SIDE_NAV_SUB_MODULE_ACCESS_RIGHTS_LINK.replace(
          nc.NAV_DATA_ID_PLACE_HOLDER,
          configExternalId
        ),
        isDisabled: role !== ContentUserRoleEnum.OWNER,
      },
    ];

    const isGeneralNavDisabled = !generalAllowedRoles.includes(role as ContentUserRoleEnum);

    return [
      {
        icon: nc.SIDE_NAV_MODULE_ADMIN_ICON,
        title: nc.SIDE_NAV_MODULE_ADMIN_TITLE,
        isDisabled: isGeneralNavDisabled,
        subModules: adminSubModules,
      },
      {
        icon: nc.SIDE_NAV_MODULE_FABRICATION_SERVICES_ICON,
        title: nc.SIDE_NAV_MODULE_FABRICATION_SERVICES_TITLE,
        subModules: [
          {
            title: nc.SIDE_NAV_SUB_MODULE_SERVICES_TITLE,
            link: nc.SIDE_NAV_SUB_MODULE_SERVICES_LINK.replace(
              nc.NAV_DATA_ID_PLACE_HOLDER,
              configExternalId
            ),
          },
          {
            title: nc.SIDE_NAV_SUB_MODULE_SERVICE_TEMPLATES_TITLE,
            link: nc.SIDE_NAV_SUB_MODULE_SERVICE_TEMPLATES_LINK.replace(
              nc.NAV_DATA_ID_PLACE_HOLDER,
              configExternalId
            ),
          },
        ],
      },
      {
        icon: nc.SIDE_NAV_MODULE_DATABASE_COMPONENTS_ICON,
        title: nc.SIDE_NAV_MODULE_DATABASE_COMPONENTS_TITLE,
        subModules: [
          {
            title: nc.SIDE_NAV_SUB_MODULE_PARTS_TITLE,
            link: nc.SIDE_NAV_SUB_MODULE_PARTS_LINK.replace(
              nc.NAV_DATA_ID_PLACE_HOLDER,
              configExternalId
            ),
          },
          {
            title: nc.SIDE_NAV_SUB_MODULE_CONNECTORS_TITLE,
            link: nc.SIDE_NAV_SUB_MODULE_CONNECTORS_LINK.replace(
              nc.NAV_DATA_ID_PLACE_HOLDER,
              configExternalId
            ),
          },
          {
            title: nc.SIDE_NAV_SUB_MODULE_DAMPERS_TITLE,
            link: nc.SIDE_NAV_SUB_MODULE_DAMPERS_LINK.replace(
              nc.NAV_DATA_ID_PLACE_HOLDER,
              configExternalId
            ),
            isDisabled: !this.featureFlagService.isFeatureFlagEnabled(FeatureFlagConstants.DAMPERS),
          },
          {
            title: nc.SIDE_NAV_SUB_MODULE_MATERIALS_TITLE,
            link: nc.SIDE_NAV_SUB_MODULE_MATERIALS_LINK.replace(
              nc.NAV_DATA_ID_PLACE_HOLDER,
              configExternalId
            ),
          },
          {
            title: nc.SIDE_NAV_SUB_MODULE_STIFFENERS_TITLE,
            link: nc.SIDE_NAV_SUB_MODULE_STIFFENERS_LINK.replace(
              nc.NAV_DATA_ID_PLACE_HOLDER,
              configExternalId
            ),
            isDisabled:
              (!configExternalId && true) ||
              !this.featureFlagService.isFeatureFlagEnabled(FeatureFlagConstants.STIFFENERS),
          },
        ],
      },
      {
        icon: nc.SIDE_NAV_MODULE_SPECIFICATIONS_ICON,
        title: nc.SIDE_NAV_MODULE_SPECIFICATIONS_TITLE,
        subModules: [
          {
            title: nc.SIDE_NAV_SUB_MODULE_INS_SPECS_TITLE,
            link: nc.SIDE_NAV_SUB_MODULE_INS_SPECS_LINK.replace(
              nc.NAV_DATA_ID_PLACE_HOLDER,
              configExternalId
            ),
          },
          {
            title: nc.SIDE_NAV_SUB_MODULE_MATERIAL_SPECS_TITLE,
            link: nc.SIDE_NAV_SUB_MODULE_MATERIAL_SPECS_LINK.replace(
              nc.NAV_DATA_ID_PLACE_HOLDER,
              configExternalId
            ),
          },
          {
            title: nc.SIDE_NAV_SUB_MODULE_PART_SPECS_TITLE,
            link: nc.SIDE_NAV_SUB_MODULE_PART_SPECS_LINK.replace(
              nc.NAV_DATA_ID_PLACE_HOLDER,
              configExternalId
            ),
          },
          {
            title: nc.SIDE_NAV_SUB_MODULE_STIFFENER_SPECS_TITLE,
            link: nc.SIDE_NAV_SUB_MODULE_STIFFENER_SPECS_LINK.replace(
              nc.NAV_DATA_ID_PLACE_HOLDER,
              configExternalId
            ),
            isDisabled: !this.featureFlagService.isFeatureFlagEnabled(
              FeatureFlagConstants.STIFFENER_SPECS
            ),
          },
        ],
      },
    ];
  };

  private createHelpMenuItems = (forLoading: boolean): NavigationLink[] => {
    return [
      {
        title: nc.SIDE_NAV_HELP_TITLE,
        link: this.envService.environment.navSubModuleHelp.helpUrl,
        openInNewWindow: true,
      },
      this.provideFeedbackLink(forLoading),
      {
        title: nc.SIDE_NAV_SUB_MODULE_CONTACT_SUPPORT_TITLE,
        description: nc.SIDE_NAV_SUB_MODULE_CONTACT_SUPPORT_SUB_TEXT,
        link: nc.SIDE_NAV_SUB_MODULE_CONTACT_SUPPORT_LINK,
        openInNewWindow: true,
        hasDivider: true,
      },
      {
        title: nc.SIDE_NAV_SUB_MODULE_ABOUT_TITLE,
        link: nc.SIDE_NAV_SUB_MODULE_ABOUT_LINK,
      },
      {
        title: nc.SIDE_NAV_SUB_MODULE_RELEASE_NOTES_TITLE,
        link: `${this.envService.environment.helpLinks.baseUrl}${helpLinks.general.releaseNotes}`,
        openInNewWindow: true,
        hasDivider: true,
      },
      {
        title: nc.SIDE_NAV_SUB_MODULE_TERMS_TITLE,
        link: nc.SIDE_NAV_SUB_LINK_PRODUCTION_TERMS_LINK,
        openInNewWindow: true,
      },
      {
        title: nc.SIDE_NAV_SUB_MODULE_PRIVACY_SETTINGS_TITLE,
      },
    ];
  };

  private createLinks = (forLoading: boolean): NavigationLink[] => {
    return [
      this.provideFeedbackLink(forLoading),
      {
        title: nc.SIDE_NAV_LINK_PRIVACY_COOKIES_TITLE,
        link: nc.SIDE_NAV_LINK_PRIVACY_COOKIES_LINK,
      },
      {
        title: nc.SIDE_NAV_HELP_TITLE,
        link: this.envService.environment.navSubModuleHelp.helpUrl,
      },
    ];
  };

  private provideFeedbackLink = (forLoading: boolean): NavigationLink => ({
    title: nc.SIDE_NAV_SUB_MODULE_FEEDBACK_TITLE,
    link: forLoading ? '' : this.feedbackService.getFeedbackLink(),
    openInNewWindow: true,
  });
}

import { Injectable } from '@angular/core';
import { ForgeContentService } from './forge-content.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers';
import { take, map, switchMap } from 'rxjs/operators';
import { selectCurrentConfigMaterials } from '@store/selectors/configs.selectors';
import { Material, MaterialType } from '@models/fabrication/material';
import {
  PartFacetOptionData,
  PartFacetType,
  PartFacets,
  PartFacetsSelectedOption,
  PartFilterConfig,
  PartSearchResponse,
} from '@models/forge-content/part-search';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { Observable, combineLatest, of } from 'rxjs';
import { FilterForm } from '@shared/components/part-search/part-filter/part-filter.component';
import { Config } from '@models/fabrication/config';
import { Part } from '@models/fabrication/part';
import { SearchContentItemsResponse } from '@adsk/content-sdk';
import { UpsertContentFiles } from '@store/actions/content-file.action';
import { UpsertThumbnailFiles } from '@store/actions/thumbnail-file.action';
import { ThumbnailFile } from '@models/fabrication/files';
import { EnvironmentConstants } from '@constants/environment-constants';
import { LoadPartsSuccess } from '@store/actions/part.action';
import { ForgeSearchService } from './forge-search.service';
import {
  FacetResponse,
  ForgeDataSearchFacet,
  ForgeDataSearchOption,
  ForgeSearchConfig,
} from '@models/forge-content/forge-data-search';
import { selectPartFilterById } from '@store/selectors/part-filter.selectors';
import { UpsertPartFilter } from '@store/actions/part-filter.action';
import { cloneDeep } from 'lodash';
import { DataElementType } from '@constants/data-element-types';
import { BinaryStorageService } from '@cache/binary-storage.service';
import { DynamicDataService } from './dynamic-data.service';
import { PartsConstants } from '@constants/parts-constants';
import { MaterialFinish } from '@models/fabrication/material-finish';
import { DebugModeService } from './debug-mode.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentUserRole, ContentUserRoleEnum } from '@models/access-rights/access-rights';

@Injectable({ providedIn: 'root' })
export class PartSearchService extends ForgeSearchService<
  Part,
  keyof typeof PartFacetType,
  PartFacetOptionData
> {
  constructor(
    forgeContentService: ForgeContentService,
    private fileStorageService: BinaryStorageService,
    private dynamicDataService: DynamicDataService,
    private store$: Store<FDMState>,
    private translate: TranslateService,
    private debugModeService: DebugModeService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super(forgeContentService);
  }

  private get orderBy(): string[] {
    return [
      `info.name:asc`,
      `fabricationData.brand:asc`,
      `fabricationData.range:asc`,
      'attributes.externalId:asc', // as a last resort, the external ID will ensure consistent ordering
    ];
  }

  searchConfig: ForgeSearchConfig<PartFacetType> = {
    dataType: DataElementType.Part,
    schemaType: 'autodesk.fabricationdm:part',
    facets: {
      [PartFacetType.brands]: {
        forgeDataKey: PartsConstants.FORGE_KEY_BRAND,
        label: 'BRAND_FILTER',
      },
      [PartFacetType.ranges]: {
        forgeDataKey: PartsConstants.FORGE_KEY_RANGE,
        label: 'RANGE_FILTER',
      },
      [PartFacetType.materials]: {
        forgeDataKey: PartsConstants.FORGE_KEY_FABRICATION_REF_EXTERNAL_ID,
        label: 'MATERIAL_FILTER',
      },
      [PartFacetType.finishes]: {
        forgeDataKey: PartsConstants.FORGE_KEY_FABRICATION_REF_EXTERNAL_ID,
        label: 'FINISH_FILTER',
      },
      [PartFacetType.patternNumber]: {
        forgeDataKey: PartsConstants.FORGE_KEY_PATTERN_NUMBER,
        label: 'PATTERN_NUMBER_FILTER',
      },
    },
  };

  getFacetRequest(
    selectedFacets: PartFacetsSelectedOption,
    includeFacets: boolean
  ): Observable<ForgeDataSearchFacet[]> {
    return this.store$.select(selectCurrentConfigMaterials(true, true)).pipe(
      take(1),
      map((materials: (Material | MaterialFinish)[]) => {
        //Create all possible material and finish facets so that it can be used to filter out the facet response for fabricationReferences.externalId
        const possibleMaterialFacets = new Set<string>([
          EnvironmentConstants.FCS_UNASSIGNED_MATERIAL,
        ]);
        const possibleFinishFacets = new Set<string>([
          EnvironmentConstants.FCS_UNASSIGNED_MATERIAL_FINISH,
        ]);

        materials.forEach((x) => {
          if (x.materialType === MaterialType.Finish) {
            possibleFinishFacets.add(x.externalId);
          } else {
            possibleMaterialFacets.add(x.externalId);
          }
        });

        return Object.entries(this.searchConfig.facets).map(([key, facetConfig]) => {
          let possibleFacets: Set<string>;
          if (key === PartFacetType.materials) {
            possibleFacets = possibleMaterialFacets;
          } else if (key === PartFacetType.finishes) {
            possibleFacets = possibleFinishFacets;
          }
          return {
            key,
            forgeDataKey: facetConfig.forgeDataKey,
            value: this.getFacetValues(selectedFacets?.[key] ?? []),
            excludeResponse: !includeFacets,
            label: this.getFacetLabels(selectedFacets?.[key] ?? []),
            possibleFacets,
          };
        });
      })
    );
  }

  getOption(config: Config, offset: number): Observable<ForgeDataSearchOption> {
    return this.store$.select(selectPartFilterById(config.id)).pipe(
      take(1),
      switchMap((filter: PartFilterConfig) => {
        return this.getFacetRequest(filter?.selectedFacets, true).pipe(
          map((facetRequest) => {
            return {
              schemaType: this.searchConfig.schemaType,
              dataType: DataElementType.Part,
              filter: {
                limit: 50,
                offset,
                orderBy: this.orderBy,
                query: filter.query,
                facets: facetRequest,
              },
            };
          })
        );
      })
    );
  }

  partSearch(
    config: Config,
    query: string,
    offset: number,
    selectedFacets: PartFacetsSelectedOption,
    includeFacets: boolean
  ): Observable<PartSearchResponse> {
    this.errorStatus = 'none';
    return this.getFacetRequest(selectedFacets, includeFacets).pipe(
      switchMap((facetRequest) => {
        const option: ForgeDataSearchOption = {
          dataType: DataElementType.Part,
          schemaType: this.searchConfig.schemaType,
          filter: {
            limit: 50,
            offset,
            orderBy: this.orderBy,
            query,
            facets: facetRequest,
          },
        };

        return this.forgeContentService.dataSearch(option).pipe(
          switchMap((response: SearchContentItemsResponse) =>
            combineLatest([
              this.getFormattedData(config, response),
              this.getFormattedFacets(config, response),
              of(response.pagination?.totalResults),
            ])
          ),
          map(
            (data): PartSearchResponse => ({
              data: data[0],
              facets: data[1],
              totalResults: data[2],
            })
          )
        );
      })
    );
  }

  protected updateSearchQueryAction(config: Config, query: string): void {
    this.store$.dispatch(new UpsertPartFilter({ filter: { query, configId: config.id } }));
  }

  protected updateSelectedFacetsAction(
    config: Config,
    selectedFacets: PartFacetsSelectedOption
  ): void {
    this.store$.dispatch(
      new UpsertPartFilter({
        filter: {
          selectedFacets: cloneDeep(selectedFacets),
          configId: config.id,
        },
      })
    );
  }

  /**
   * Used for getting part facets if navigating directly to editing a part rather than through the Search UI.
   * @param config
   * @returns
   */
  partFacets(config: Config): Observable<PartFacets> {
    return this.getFacets(config).pipe(
      switchMap((response: SearchContentItemsResponse) => {
        return this.getFormattedFacets(config, response);
      })
    );
  }

  getFormattedData(config: Config, response: SearchContentItemsResponse): Observable<Part[]> {
    const parts = this.forgeContentService.deserializeContentItemListResponse(
      response.results
    ) as Part[];
    const contentFilesMap = this.forgeContentService.getContentElementFileContent(
      response.results,
      config.externalId
    );
    const contentFiles =
      (contentFilesMap.length &&
        contentFilesMap.filter((x) => !x.isImage).map((x) => x.contentFile)) ||
      [];
    const thumbnailFiles =
      (contentFilesMap.length &&
        contentFilesMap.filter((x) => x.isImage).map((x) => x.contentFile as ThumbnailFile)) ||
      [];

    if (parts.length) {
      // todo - when we remove the part browser remove all parts from the store and replace
      // with the current search
      // this.store$.dispatch(new RemoveAllParts());
      const successAction = new LoadPartsSuccess();
      successAction.data = parts;
      this.store$.dispatch(successAction);
    }

    if (contentFiles.length) {
      this.store$.dispatch(new UpsertContentFiles(contentFiles));
    }
    if (thumbnailFiles.length) {
      this.store$.dispatch(new UpsertThumbnailFiles(thumbnailFiles));
    }

    const setup = this.dynamicDataService.getDynamicDataSetupForType(DataElementType.Part);
    const supportedStorageFileTypes = setup.options.bulkLoadFileTypesSupported;

    return this.fileStorageService
      .addStorageFiles(contentFilesMap, DataElementType.Part, supportedStorageFileTypes)
      .pipe(map(() => parts));
  }

  getFormattedFacets(config: Config, result: SearchContentItemsResponse): Observable<PartFacets> {
    const unassigned = this.translate.instant(LC.GRAPH.UNASSIGNED);
    return this.store$.select(selectCurrentConfigMaterials(true, true)).pipe(
      take(1),
      map((materials: (Material | MaterialFinish)[]) => {
        const materialFacetMap: { [key: string]: FacetResponse } = {};
        const materialFinishFacetMap: { [key: string]: FacetResponse } = {};

        const materialFacet = this.getFacetByKey(
          result,
          this.searchConfig.facets[PartFacetType.materials].forgeDataKey
        );
        materialFacet.forEach((x) => {
          if (x.key === EnvironmentConstants.FCS_UNASSIGNED_MATERIAL) {
            materialFacetMap[unassigned] = {
              label: unassigned,
              count: x.count,
              key: x.key,
              extraKeys: [],
              isUnassigned: true,
            };
            return;
          }

          const material = materials.find(
            (m) => m.externalId === x.key && m.materialType !== MaterialType.Finish
          );
          if (material) {
            // check for existing filters of same material
            const existingFacet = materialFacetMap[material.name];
            if (existingFacet) {
              existingFacet.extraKeys.push(x.key);
              existingFacet.count += x.count;
            } else {
              materialFacetMap[material.name] = {
                label: material.name,
                count: x.count,
                key: x.key,
                extraKeys: [],
                isUnassigned: false,
              };
            }
          }
        });

        const materialFinishFacet = this.getFacetByKey(
          result,
          this.searchConfig.facets[PartFacetType.finishes].forgeDataKey
        );
        materialFinishFacet.forEach((x) => {
          if (x.key === EnvironmentConstants.FCS_UNASSIGNED_MATERIAL_FINISH) {
            materialFinishFacetMap[unassigned] = {
              label: unassigned,
              count: x.count,
              key: x.key,
              extraKeys: [],
              isUnassigned: true,
            };
            return;
          }

          const finish = materials.find(
            (m) => m.externalId === x.key && m.materialType === MaterialType.Finish
          );
          if (finish) {
            // check for existing filters of same material
            const existingFacet = materialFinishFacetMap[finish.name];
            if (existingFacet) {
              existingFacet.extraKeys.push(x.key);
              existingFacet.count += x.count;
            } else {
              materialFinishFacetMap[finish.name] = {
                label: finish.name,
                count: x.count,
                key: x.key,
                extraKeys: [],
                isUnassigned: false,
              };
            }
          }
        });

        const partResponseMapper = (facetTypeKey: PartFacetType) =>
          this.getFacetByKey(result, this.searchConfig.facets[facetTypeKey].forgeDataKey).map(
            (o): FacetResponse => ({
              label: o.key || unassigned,
              key: o.key,
              count: o.count,
              extraKeys: [],
              isUnassigned: o.key ? false : true,
            })
          );

        return {
          [PartFacetType.brands]: partResponseMapper(PartFacetType.brands),
          [PartFacetType.ranges]: partResponseMapper(PartFacetType.ranges),
          [PartFacetType.materials]: Object.values(materialFacetMap),
          [PartFacetType.finishes]: Object.values(materialFinishFacetMap),
          [PartFacetType.patternNumber]: partResponseMapper(PartFacetType.patternNumber),
        };
      })
    );
  }

  getMergedFacets(config: Config, result: SearchContentItemsResponse): Observable<PartFacets> {
    return combineLatest([
      this.getFormattedFacets(config, result),
      this.store$.select(selectPartFilterById(config.id)),
    ]).pipe(
      map((data: [PartFacets, PartFilterConfig]) => {
        return {
          [PartFacetType.brands]: this.mergeFacets(
            data[1].facets[PartFacetType.brands] || [],
            data[0][PartFacetType.brands]
          ),
          [PartFacetType.ranges]: this.mergeFacets(
            data[1].facets[PartFacetType.ranges] || [],
            data[0][PartFacetType.ranges]
          ),
          [PartFacetType.materials]: this.mergeFacets(
            data[1].facets[PartFacetType.materials] || [],
            data[0][PartFacetType.materials]
          ),
          [PartFacetType.finishes]: this.mergeFacets(
            data[1].facets[PartFacetType.finishes] || [],
            data[0][PartFacetType.finishes]
          ),
          [PartFacetType.patternNumber]: this.mergeFacets(
            data[1].facets[PartFacetType.patternNumber] || [],
            data[0][PartFacetType.patternNumber]
          ),
        };
      })
    );
  }

  getFilterForm(): FilterForm[] {
    const filters = [
      PartFacetType.brands,
      PartFacetType.ranges,
      PartFacetType.materials,
      PartFacetType.finishes,
    ];

    if (this.debugModeService.enablePatternNumberSearch) {
      filters.push(PartFacetType.patternNumber);
    }

    return filters.map((key) => ({
      key,
      options: [],
      label: this.searchConfig.facets[key].label,
    }));
  }

  getPartDetailsUrl(partId: string, configId: string): string {
    const role: ContentUserRole = this.route?.snapshot?.data?.accessRightsData?.role;
    const action = role === ContentUserRoleEnum.READ ? 'view' : 'edit';
    const detailsLink = [partId, action];

    const urlTree = this.router.createUrlTree(detailsLink, {
      queryParams: {},
    });

    // set url because Part in a new tab could be open from Service Templates
    const partsLink = `/data/config/${configId}/parts`;

    return partsLink + this.router.serializeUrl(urlTree);
  }
}
